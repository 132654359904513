import siteContent from './site-content.md';

// Helper to extract section content
const extractSection = (content, sectionName) => {
  const pattern = '### ' + sectionName + '[\\s\\S]*?(?=### |$)';
  const regex = new RegExp(pattern);
  const match = content.match(regex);
  return match ? match[0] : '';
};

// Helper to extract title from section
const extractTitle = (section) => {
  const titleRegex = /#### Title: "([^"]+)"/;
  const match = section.match(titleRegex);
  return match ? match[1] : '';
};

// Helper to extract content items
const extractContentItems = (section) => {
  const contentRegex = /-\s+"([^"]+)"/g;
  const items = [];
  let match;
  while ((match = contentRegex.exec(section)) !== null) {
    items.push(match[1]);
  }
  return items;
};

// Helper to extract collapsible sections
const extractCollapsibleSections = (section) => {
  const sectionRegex = /\d+\.\s+([^:]+):\s*\n\s*-\s*Title:\s*"([^"]+)"\s*\n\s*-\s*Description:\s*"([^"]+)"/g;
  const sections = [];
  let match;
  while ((match = sectionRegex.exec(section)) !== null) {
    sections.push({
      id: match[1].toLowerCase().replace(/\s+/g, '-'),
      title: match[2],
      description: match[3]
    });
  }
  return sections;
};

// Parse the markdown content
const parseContent = () => {
  const content = {
    global: {
      navigation: extractSection(siteContent, 'Navigation'),
      footer: extractSection(siteContent, 'Footer')
    },
    festivals: {
      hero: {
        title: "FUTUREPERFECT FESTIVAL"
      },
      what: {
        title: extractTitle(extractSection(siteContent, 'What Section')),
        content: extractContentItems(extractSection(siteContent, 'What Section')),
        collapsible: extractCollapsibleSections(extractSection(siteContent, 'What Section'))
      },
      goals: {
        title: extractTitle(extractSection(siteContent, 'Goals Section')),
        content: extractCollapsibleSections(extractSection(siteContent, 'Goals Section'))
      },
      who: {
        title: extractTitle(extractSection(siteContent, 'Who Section')),
        introduction: extractSection(siteContent, 'Who Section').match(/#### Introduction:\s*"([^"]+)"/)?.[1] || '',
        content: extractCollapsibleSections(extractSection(siteContent, 'Who Section'))
      },
      program: {
        title: extractTitle(extractSection(siteContent, 'Program Section')),
        introduction: extractSection(siteContent, 'Program Section').match(/#### Introduction:\s*"([^"]+)"/)?.[1] || '',
        content: extractCollapsibleSections(extractSection(siteContent, 'Program Section'))
      },
      festivals: {
        title: extractTitle(extractSection(siteContent, 'Festivals Section')),
        content: extractCollapsibleSections(extractSection(siteContent, 'Festivals Section'))
      },
      participate: {
        title: extractTitle(extractSection(siteContent, 'Participate Section')),
        content: extractCollapsibleSections(extractSection(siteContent, 'Participate Section'))
      }
    },
    events: {
      hero: {
        title: "FUTUREPERFECT EVENTS"
      },
      what: {
        title: extractTitle(extractSection(siteContent, 'What Section')),
        content: extractContentItems(extractSection(siteContent, 'What Section'))
      },
      program: {
        title: extractTitle(extractSection(siteContent, 'Program Section')),
        content: extractCollapsibleSections(extractSection(siteContent, 'Program Section'))
      },
      pastEvents: {
        title: extractTitle(extractSection(siteContent, 'Past Events Section')),
        content: extractCollapsibleSections(extractSection(siteContent, 'Past Events Section'))
      },
      participate: {
        title: extractTitle(extractSection(siteContent, 'Participate Section')),
        content: extractCollapsibleSections(extractSection(siteContent, 'Participate Section'))
      }
    }
  };
  return content;
};

// Export getters for content sections
export const getContent = () => parseContent();
export const getFestivalsContent = () => parseContent().festivals;
export const getEventsContent = () => parseContent().events;
export const getGlobalContent = () => parseContent().global;
