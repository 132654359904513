import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  Avatar,
  Divider,
  Alert,
  InputAdornment,
  useTheme,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MovieIcon from '@mui/icons-material/Movie';
import BusinessIcon from '@mui/icons-material/Business';
import { SectionBanner, SectionBannerText, SectionBannerWrapper } from '../components/SharedComponents';
import Navbar from '../components/Navbar';
import { pageColors } from '../components/shared/colors';



// Base styled components
const PageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  background: `url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#000',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '85%',
  margin: '0 auto',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    width: '95%',
  }
}));

const MainContent = styled(Container)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(8),
}));

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: theme.spacing(1),
  color: '#FFFFFF',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    padding: theme.spacing(2),
  }
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '2.5rem',
  color: '#FFFFFF',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    marginBottom: theme.spacing(3),
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.8rem',
    marginBottom: theme.spacing(2),
  }
}));

const FormSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.5rem',
  color: '#FFFFFF',
  borderLeft: `4px solid ${pageColors.events}`,
  paddingLeft: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3rem',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(2),
  }
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: pageColors.events,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: pageColors.events,
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    marginBottom: theme.spacing(2),
  }
}));

const FormSelect = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: pageColors.events,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: pageColors.events,
  },
  '& .MuiSelect-icon': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiMenuItem-root': {
    color: '#000000',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    marginBottom: theme.spacing(2),
  }
}));

const CheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: '#FFFFFF',
  },
  '& .MuiCheckbox-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .Mui-checked': {
    color: pageColors.events,
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    marginBottom: theme.spacing(2),
    marginTop: 0,
  }
}));

const FilePreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(0.5),
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    marginBottom: theme.spacing(2),
  }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: pageColors.events,
  color: '#FFFFFF',
  padding: theme.spacing(1.5, 4),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: theme.palette.augmentColor({
      color: { main: pageColors.events }
    }).dark,
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1rem',
    padding: theme.spacing(1, 3),
  }
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FormSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    marginBottom: theme.spacing(3),
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  }
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '80px',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    height: '60px',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    height: '50px',
  }
}));

const Create = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    bio: '',
    contributionType: '',
    contributionDescription: '',
    availableDates: '',
    instagram: '',
    websiteUrl: '',
    mediaLinks: '',
    interests: {
      speaking: false,
      performance: false,
      workshop: false,
      exhibition: false,
      sponsorship: false,
      partnership: false
    },
    profileImage: null,
    portfolioImage: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submission, setSubmission] = useState({ status: null, message: '' });
  const [profileImagePreview, setProfileImagePreview] = useState('');
  const [portfolioImagePreview, setPortfolioImagePreview] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Clear error when field is edited
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      interests: {
        ...formData.interests,
        [name]: checked
      }
    });
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type and size
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const maxSize = 5 * 1024 * 1024; // 5MB
    
    if (!validTypes.includes(file.type)) {
      setFormErrors({
        ...formErrors,
        [fileType]: 'Please upload a JPG, PNG, or WebP image',
      });
      return;
    }
    
    if (file.size > maxSize) {
      setFormErrors({
        ...formErrors,
        [fileType]: 'File size must be less than 5MB',
      });
      return;
    }

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      if (fileType === 'profileImage') {
        setProfileImagePreview(reader.result);
      } else {
        setPortfolioImagePreview(reader.result);
      }
    };
    reader.readAsDataURL(file);

    // Update form data
    setFormData({
      ...formData,
      [fileType]: file,
    });
    
    // Clear error
    if (formErrors[fileType]) {
      setFormErrors({
        ...formErrors,
        [fileType]: null,
      });
    }
  };

  const removeFile = (fileType) => {
    if (fileType === 'profileImage') {
      setProfileImagePreview('');
    } else {
      setPortfolioImagePreview('');
    }
    
    setFormData({
      ...formData,
      [fileType]: null,
    });
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    
    if (!formData.contributionType) {
      errors.contributionType = 'Please select a contribution type';
    }
    
    if (!formData.contributionDescription.trim()) {
      errors.contributionDescription = 'Please describe your contribution';
    }
    
    // Check if at least one interest is selected
    const hasInterest = Object.values(formData.interests).some(value => value);
    if (!hasInterest) {
      errors.interests = 'Please select at least one area of interest';
    }
    
    // Instagram URL validation (optional field)
    if (formData.instagram && !formData.instagram.match(/^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_]+(\/)?$/)) {
      errors.instagram = 'Please enter a valid Instagram profile URL';
    }
    
    // Website URL validation (optional field)
    if (formData.websiteUrl && !formData.websiteUrl.match(/^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+\/?.*$/)) {
      errors.websiteUrl = 'Please enter a valid URL';
    }
    
    if (!formData.profileImage) {
      errors.profileImage = 'Please upload a profile image';
    }
    
    if (Object.keys(errors).length === 0) {
      return true;
    }
    
    setFormErrors(errors);
    return false;
  };

  // Function to upload files to Discord
  const uploadToDiscord = async (formData) => {
    try {
      // Discord webhook URL - use environment variable if available, fall back to direct URL for local dev
      const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_URL || 'https://discord.com/api/webhooks/1354947314720178328/KUvI9CrgY1STiyufXa_jDihqJ9Y0xLfDfM149Cr5d1UyCljr1XBjiE6vC8k13BXF3pIv';
      
      // Create detailed message with content creation info
      const message = `
**New Content Creation Submission**
**Name:** ${formData.name}
**Organization:** ${formData.organization || "N/A"}
**Contribution Type:** ${formData.contributionType}
**Description:** ${formData.contributionDescription}
**Available Dates:** ${formData.availableDates || "N/A"}
**Instagram:** ${formData.instagram || "N/A"}
**Website:** ${formData.websiteUrl || "N/A"}
**Media Links:** ${formData.mediaLinks || "N/A"}
**Areas of Interest:** ${Object.entries(formData.interests)
        .filter(([_, value]) => value)
        .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
        .join(', ')}
      `;

      // Send first message with info and profile image
      const firstData = new FormData();
      firstData.append('content', message);
      
      // Add the profile image
      if (formData.profileImage) {
        firstData.append('file', formData.profileImage, formData.profileImage.name);
      }
      
      // Send to Discord webhook
      const response1 = await fetch(webhookUrl, {
        method: 'POST',
        body: firstData
      });
      
      if (!response1.ok) {
        console.error('Discord webhook error (profile):', await response1.text());
        return false;
      }
      
      // If we have a portfolio image, send it as a second message
      if (formData.portfolioImage) {
        // Create a second FormData for the portfolio image
        const secondData = new FormData();
        secondData.append('content', `**Portfolio Image for ${formData.name}**`);
        secondData.append('file', formData.portfolioImage, formData.portfolioImage.name);
        
        // Send second webhook request
        const response2 = await fetch(webhookUrl, {
          method: 'POST',
          body: secondData
        });
        
        if (!response2.ok) {
          console.error('Discord webhook error (portfolio):', await response2.text());
          // Continue anyway since we already sent the main info
        }
      }
      
      return true;
    } catch (error) {
      console.error('Error uploading to Discord:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setSubmission({
        status: 'error',
        message: 'Please correct the errors in the form',
      });
      return;
    }
    
    // Set loading state
    setSubmission({
      status: 'info',
      message: 'Submitting your content creation form...',
    });
    
    try {
      // Upload to Discord using the webhook
      const success = await uploadToDiscord(formData);
      
      if (success) {
        setSubmission({
          status: 'success',
          message: 'Form submitted successfully! We will contact you soon.',
        });
        
        // Reset form after successful submission
        setFormData({
          name: '',
          organization: '',
          bio: '',
          contributionType: '',
          contributionDescription: '',
          availableDates: '',
          instagram: '',
          websiteUrl: '',
          mediaLinks: '',
          interests: {
            speaking: false,
            performance: false,
            workshop: false,
            exhibition: false,
            sponsorship: false,
            partnership: false
          },
          profileImage: null,
          portfolioImage: null,
        });
        setProfileImagePreview('');
        setPortfolioImagePreview('');
      } else {
        setSubmission({
          status: 'error',
          message: 'There was an error submitting your form. Please try again.',
        });
      }
    } catch (error) {
      setSubmission({
        status: 'error',
        message: 'There was an error submitting your form. Please try again.',
      });
      console.error('Submission error:', error);
    }
  };

  // Add custom style to hide mobile strapline
  useEffect(() => {
    // Add a style to hide mobile strapline
    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
      div[class*="MobileStraplineBar"] {
        display: none !important;
      }
    `;
    document.head.appendChild(styleEl);
    
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  return (
    <PageContainer>
      <Helmet>
        <title>CREATE | FUTUREPERFECT</title>
      </Helmet>
      
      <Navbar pageName="EVENTS" />
      
      {/* Content Creation Banner */}
      <SectionBannerWrapper sx={{ 
        mt: 0, 
        top: { xs: '0 !important', sm: 0 },
        position: 'relative'
      }}>
        <SectionBanner sx={{
          position: 'relative',
          zIndex: 4
        }} color={pageColors.events}>
          <Container maxWidth="lg">
            <SectionBannerText variant="h2">
              CONTENT CREATION
            </SectionBannerText>
          </Container>
        </SectionBanner>
      </SectionBannerWrapper>
      
      <MainContent maxWidth="md">
        <ContentWrapper>
          <FormContainer elevation={3}>
            <FormTitle variant="h2">Create with Futureperfect</FormTitle>
            
            <Typography variant="body1" color="white" sx={{ mb: 2, textAlign: 'center' }}>
              Submit your ideas for contributing to Futureperfect events, festivals, and talks.
            </Typography>
            
            <Typography variant="body1" color="white" sx={{ mb: 4, textAlign: 'center' }}>
              Whether you're a performer, artist, partner, or speaker, we welcome your creative input.
            </Typography>
            
            <form onSubmit={handleSubmit}>
              <FormSection>
                <FormSubtitle variant="h5">Basic Information</FormSubtitle>
                <FormField
                  name="name"
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.name}
                  onChange={handleInputChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  placeholder="John Smith"
                />
                
                <FormField
                  name="organization"
                  label="Organization (if applicable)"
                  variant="outlined"
                  fullWidth
                  value={formData.organization}
                  onChange={handleInputChange}
                  error={!!formErrors.organization}
                  helperText={formErrors.organization}
                  placeholder="Acme Productions"
                />
                
                <FormField
                  name="bio"
                  label="Professional Bio (50 words max)"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={formData.bio}
                  onChange={handleInputChange}
                  error={!!formErrors.bio}
                  helperText={formErrors.bio || `${formData.bio.split(/\s+/).length}/50 words`}
                  placeholder="Brief professional bio highlighting your expertise and background"
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Contribution Details</FormSubtitle>
                
                <FormSelect 
                  variant="outlined"
                  fullWidth
                  error={!!formErrors.contributionType}
                  required
                  sx={{ mb: 3 }}
                >
                  <InputLabel id="contribution-type-label">Contribution Type</InputLabel>
                  <Select
                    labelId="contribution-type-label"
                    name="contributionType"
                    value={formData.contributionType}
                    onChange={handleInputChange}
                    label="Contribution Type"
                  >
                    <MenuItem value="performance">Performance (Music, Dance, etc.)</MenuItem>
                    <MenuItem value="workshop">Workshop or Class</MenuItem>
                    <MenuItem value="exhibition">Exhibition or Installation</MenuItem>
                    <MenuItem value="talk">Talk or Presentation</MenuItem>
                    <MenuItem value="panel">Panel Discussion</MenuItem>
                    <MenuItem value="partnership">Partnership or Sponsorship</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {formErrors.contributionType && (
                    <Typography variant="caption" color="error" sx={{ mt: 1 }}>
                      {formErrors.contributionType}
                    </Typography>
                  )}
                </FormSelect>
                
                <FormField
                  name="contributionDescription"
                  label="Describe Your Contribution"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  value={formData.contributionDescription}
                  onChange={handleInputChange}
                  error={!!formErrors.contributionDescription}
                  helperText={formErrors.contributionDescription}
                  placeholder="Describe what you'd like to contribute to Futureperfect events or festivals"
                />
                
                <FormField
                  name="availableDates"
                  label="Available Dates (if known)"
                  variant="outlined"
                  fullWidth
                  value={formData.availableDates}
                  onChange={handleInputChange}
                  error={!!formErrors.availableDates}
                  helperText={formErrors.availableDates}
                  placeholder="List any specific dates or date ranges when you're available"
                />
                
                <Typography variant="subtitle1" color="white" sx={{ mt: 2, mb: 1 }}>
                  Areas of Interest (select all that apply)
                </Typography>
                
                <FormGroup>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CheckboxLabel
                        control={<Checkbox checked={formData.interests.speaking} onChange={handleCheckboxChange} name="speaking" />}
                        label="Speaking/Presenting"
                      />
                      <CheckboxLabel
                        control={<Checkbox checked={formData.interests.performance} onChange={handleCheckboxChange} name="performance" />}
                        label="Performance"
                      />
                      <CheckboxLabel
                        control={<Checkbox checked={formData.interests.workshop} onChange={handleCheckboxChange} name="workshop" />}
                        label="Workshop"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CheckboxLabel
                        control={<Checkbox checked={formData.interests.exhibition} onChange={handleCheckboxChange} name="exhibition" />}
                        label="Exhibition"
                      />
                      <CheckboxLabel
                        control={<Checkbox checked={formData.interests.sponsorship} onChange={handleCheckboxChange} name="sponsorship" />}
                        label="Sponsorship"
                      />
                      <CheckboxLabel
                        control={<Checkbox checked={formData.interests.partnership} onChange={handleCheckboxChange} name="partnership" />}
                        label="Partnership"
                      />
                    </Grid>
                  </Grid>
                  {formErrors.interests && (
                    <Typography variant="caption" color="error">
                      {formErrors.interests}
                    </Typography>
                  )}
                </FormGroup>
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Online Presence</FormSubtitle>
                <FormField
                  name="instagram"
                  label="Instagram Profile"
                  variant="outlined"
                  fullWidth
                  value={formData.instagram}
                  onChange={handleInputChange}
                  error={!!formErrors.instagram}
                  helperText={formErrors.instagram}
                  placeholder="https://instagram.com/yourprofile"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                
                <FormField
                  name="websiteUrl"
                  label="Website URL"
                  variant="outlined"
                  fullWidth
                  value={formData.websiteUrl}
                  onChange={handleInputChange}
                  error={!!formErrors.websiteUrl}
                  helperText={formErrors.websiteUrl}
                  placeholder="https://example.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                
                <FormField
                  name="mediaLinks"
                  label="Media Links (Spotify, SoundCloud, YouTube, etc.)"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={formData.mediaLinks}
                  onChange={handleInputChange}
                  error={!!formErrors.mediaLinks}
                  helperText={formErrors.mediaLinks}
                  placeholder="Add links to your music, videos, or other media (one per line)"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MusicNoteIcon sx={{ color: 'rgba(255, 255, 255, 0.7)', mr: -1 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Visual Assets</FormSubtitle>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="white" sx={{ mb: 1 }}>
                      Profile Image
                    </Typography>
                    
                    {profileImagePreview ? (
                      <FilePreview>
                        <Avatar 
                          src={profileImagePreview} 
                          alt="Profile preview" 
                          sx={{ width: 60, height: 60, mr: 2 }}
                        />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" color="white">
                            {formData.profileImage?.name}
                          </Typography>
                          <Typography variant="caption" color="white" sx={{ opacity: 0.7 }}>
                            {Math.round(formData.profileImage?.size / 1024)} KB
                          </Typography>
                        </Box>
                        <IconButton 
                          onClick={() => removeFile('profileImage')}
                          sx={{ color: 'white' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </FilePreview>
                    ) : (
                      <>
                        <UploadButton 
                          component="label"
                          variant="outlined" 
                          startIcon={<CloudUploadIcon />}
                          fullWidth
                        >
                          Upload Image
                          <VisuallyHiddenInput 
                            type="file"
                            accept="image/jpeg,image/png,image/webp"
                            onChange={(e) => handleFileChange(e, 'profileImage')}
                          />
                        </UploadButton>
                        {formErrors.profileImage && (
                          <Typography variant="caption" color="error">
                            {formErrors.profileImage}
                          </Typography>
                        )}
                      </>
                    )}
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                      Your profile or headshot.
                      JPG, PNG or WebP, max 5MB.
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="white" sx={{ mb: 1 }}>
                      Portfolio Image (Optional)
                    </Typography>
                    
                    {portfolioImagePreview ? (
                      <FilePreview>
                        <Box 
                          component="img"
                          src={portfolioImagePreview}
                          alt="Portfolio preview"
                          sx={{ 
                            width: 60, 
                            height: 60, 
                            mr: 2,
                            objectFit: 'cover',
                            borderRadius: '4px'
                          }}
                        />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" color="white">
                            {formData.portfolioImage?.name}
                          </Typography>
                          <Typography variant="caption" color="white" sx={{ opacity: 0.7 }}>
                            {Math.round(formData.portfolioImage?.size / 1024)} KB
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={() => removeFile('portfolioImage')}
                          sx={{ color: 'white' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </FilePreview>
                    ) : (
                      <UploadButton
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                        fullWidth
                      >
                        Upload Portfolio
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/jpeg,image/png,image/webp"
                          onChange={(e) => handleFileChange(e, 'portfolioImage')}
                        />
                      </UploadButton>
                    )}
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                      An image showcasing your work or performance.
                      JPG, PNG or WebP, max 5MB.
                    </Typography>
                  </Grid>
                </Grid>
              </FormSection>
              
              <Divider sx={{ my: 4, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
              
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                gap: 3,
                flexDirection: { xs: 'column', sm: 'row' } 
              }}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit Form
                </SubmitButton>
                
                {submission.status && (
                  <Alert 
                    severity={submission.status} 
                    sx={{ 
                      flexGrow: { xs: 1, sm: 0 },
                      width: { xs: '100%', sm: 'auto' },
                      maxWidth: { sm: '400px' }
                    }}
                    onClose={() => setSubmission({ status: null, message: '' })}
                  >
                    {submission.message}
                  </Alert>
                )}
              </Box>
              
              <Typography variant="caption" color="#FFFFFF" align="center" display="block" sx={{ mt: 2 }}>
                By submitting this form, you agree to our{' '}
                <Link href="#" color="#FFFFFF" underline="hover">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link href="#" color="#FFFFFF" underline="hover">
                  Terms of Service
                </Link>
              </Typography>
            </form>
          </FormContainer>
        </ContentWrapper>
      </MainContent>
    </PageContainer>
  );
};

export default Create;