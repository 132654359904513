import React from 'react';
import {
  Box,
  ImageList,
  ImageListItem,
  Typography,
  IconButton,
  Stack,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import OptimizedImage from './OptimizedImage';

// Masonry size variation patterns for more visual interest
const getMasonrySize = (index) => {
  // Create a varied pattern where about 1/3 of images are featured
  // This makes the masonry layout more interesting
  const position = index % 9; // Repeating pattern of 9 positions

  // Special treatment for first image on first page
  if (index === 0) {
    return { styleKey: 'large' }; // First image is always large
  }

  switch (position) {
    case 1: return { styleKey: 'vertical' }; // Tall image
    case 4: return { styleKey: 'horizontal' }; // Wide image 
    case 7: return { styleKey: 'large' }; // Large image
    default: return { styleKey: 'standard' }; // Standard size
  }
};

// Define responsive values based on screen size
const getResponsiveValues = (theme) => ({
  xs: {
    COLS: 2,
    ROWS_PER_PAGE: 3, // 3 rows as requested
    ITEMS_PER_PAGE: 6, // 2 columns × 3 rows
    ROW_HEIGHT: 160 
  },
  sm: {
    COLS: 3,
    ROWS_PER_PAGE: 3, // 3 rows as requested
    ITEMS_PER_PAGE: 9, // 3 columns × 3 rows
    ROW_HEIGHT: 190
  },
  md: {
    COLS: 4,
    ROWS_PER_PAGE: 3, // 3 rows as requested
    ITEMS_PER_PAGE: 12, // 4 columns × 3 rows
    ROW_HEIGHT: 220
  }
});

// Default values for desktop
const ROWS_PER_PAGE = 3; // 3 rows as requested 
const COLS = 4; 
const MAX_ITEMS_PER_ROW = 4;
const ITEMS_PER_PAGE = 12; // 4 columns × 3 rows

const ImageGallery = ({ images, description, maxHeight, spacing }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  // Get responsive configuration based on screen size
  const responsiveValues = React.useMemo(() => {
    if (isMobile) return getResponsiveValues(theme).xs;
    if (isTablet) return getResponsiveValues(theme).sm;
    return getResponsiveValues(theme).md;
  }, [theme, isMobile, isTablet]);
  
  const [currentPage, setCurrentPage] = React.useState(0);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  
  const totalPages = Math.ceil(images.length / responsiveValues.ITEMS_PER_PAGE);

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const getPageItems = (pageIndex) => {
    const startIndex = pageIndex * responsiveValues.ITEMS_PER_PAGE;
    const endIndex = startIndex + responsiveValues.ITEMS_PER_PAGE;
    return images.slice(startIndex, endIndex);
  };

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const currentImages = getPageItems(currentPage);
  
  // Format lightbox images to use optimized sources where possible
  const lightboxImages = images.map(img => {
    const basePath = (img.url || img.src).replace(/\.[^/.]+$/, '');
    const optimizedPath = basePath.includes('/img/') ? 
      basePath.replace('/img/', '/img-optimized/') : basePath;

    return {
      src: `${optimizedPath}-xl.jpg`, // Use the largest optimized image for lightbox
      alt: img.alt || img.title || '',
      srcFallback: img.url || img.src // Original image as fallback
    };
  });

  return (
    <Box sx={{ width: '100%' }}>
      {description && (
        <Typography 
          variant="body1" 
          sx={{ 
            mb: { xs: 1.5, sm: 2, md: 3 },
            fontFamily: 'Inter, sans-serif',
            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' },
            lineHeight: { xs: 1.5, md: 1.8 },
            color: '#333333',
            '@media (max-width: 600px) and (orientation: landscape)': {
              mb: 1,
              fontSize: '0.8rem',
              lineHeight: 1.4
            }
          }}
        >
          {description}
        </Typography>
      )}
      <Box sx={{ width: '100%', position: 'relative' }}>
        <ImageList
          variant="masonry" // Masonry layout
          cols={responsiveValues.COLS}
          gap={32} // Significantly increased gap to prevent overlap
          sx={{ 
            width: '100%',
            m: 0,
            overflow: 'hidden', // Changed from 'visible' to 'hidden' to prevent overflow on Vercel
            pb: 6, // More padding at bottom
            mb: 4, // More margin at bottom
            height: '100%', // Fill container height
            '& .MuiImageListItem-root': {
              // Prevent scaling and use explicit margins instead
              '@media (min-width: 960px)': {
                margin: '16px 0', // More vertical spacing between items
                transform: 'none' // Remove transform to prevent overlap
              }
            }
          }}
        >
          {getPageItems(currentPage).map((item, index) => {
            // Get the masonry style for this image
            const { styleKey } = getMasonrySize(currentPage * responsiveValues.ITEMS_PER_PAGE + index);
            
            // Define scaling for size variations
            let scale = 1.0;
            let heightMult = 1.0;
            
            switch (styleKey) {
              case 'large':
                scale = 1.25;
                break;
              case 'vertical':
                heightMult = 1.5; // Taller image
                break;
              case 'horizontal':
                scale = 1.1; // Slightly larger wide image
                break;
              default:
                scale = 1.0;
            }
            
            return (
              <ImageListItem 
                key={item.url}
                onClick={() => handleImageClick(currentPage * responsiveValues.ITEMS_PER_PAGE + index)}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  transition: 'all 0.2s ease-in-out',
                  transform: 'translateZ(0)', // Force GPU acceleration
                  mb: 3, // More bottom margin to prevent overlap
                  width: '100%', // Ensure full width rendering
                  display: 'block', // Force block rendering
                  '&:hover': {
                    boxShadow: '0 3px 8px rgba(0,0,0,0.15)',
                    transform: 'translateY(-2px) translateZ(0)'
                  },
                  '& img': {
                    borderRadius: '8px',
                    transition: 'transform 0.3s ease-in-out',
                    width: '100%', // Ensure images use full width
                    display: 'block', // Force block display
                    '&:hover': {
                      transform: 'scale(1.03)'
                    }
                  },
                  // Add specific spacing for desktop
                  '@media (min-width: 960px)': {
                    mb: 4 // Even more space between items on desktop
                  }
                }}
              >
                <OptimizedImage
                  src={item.url}
                  alt={item.alt || ''}
                  style={{ 
                    width: '100%', 
                    display: 'block', // Force block display to fix spacing
                    transformOrigin: 'center center', // Scale from center
                    objectFit: 'cover', // Ensure proper image scaling
                    maxHeight: styleKey === 'vertical' ? `${heightMult * 100}%` : 'auto' // Apply height variation
                  }}
                />
              </ImageListItem>
            );
          })}
        </ImageList>

        {totalPages > 1 && (
          <>
            <IconButton
              onClick={handlePrevPage}
              disabled={currentPage === 0}
              sx={{
                position: 'absolute',
                left: { xs: -24, sm: -32, md: -48 },
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                },
                visibility: currentPage === 0 ? 'hidden' : 'visible',
                width: { xs: 32, sm: 40, md: 48 },
                height: { xs: 32, sm: 40, md: 48 },
                boxShadow: '0px 2px 8px rgba(0,0,0,0.2)',
                '@media (max-width: 600px) and (orientation: landscape)': {
                  width: 28,
                  height: 28,
                  left: -16
                }
              }}
            >
              <ChevronLeftIcon sx={{ 
                color: '#333333', 
                fontSize: { xs: 20, sm: 24, md: 32 },
                '@media (max-width: 600px) and (orientation: landscape)': {
                  fontSize: 18
                }
              }} />
            </IconButton>
            <IconButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
              sx={{
                position: 'absolute',
                right: { xs: -24, sm: -32, md: -48 },
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                },
                visibility: currentPage === totalPages - 1 ? 'hidden' : 'visible',
                width: { xs: 32, sm: 40, md: 48 },
                height: { xs: 32, sm: 40, md: 48 },
                boxShadow: '0px 2px 8px rgba(0,0,0,0.2)',
                '@media (max-width: 600px) and (orientation: landscape)': {
                  width: 28,
                  height: 28,
                  right: -16
                }
              }}
            >
              <ChevronRightIcon sx={{ 
                color: '#333333', 
                fontSize: { xs: 20, sm: 24, md: 32 },
                '@media (max-width: 600px) and (orientation: landscape)': {
                  fontSize: 18
                }
              }} />
            </IconButton>
          </>
        )}
      </Box>

      {/* Pagination Dots */}
      {totalPages > 1 && (
        <Stack 
          direction="row" 
          spacing={{ xs: 0.5, sm: 0.75, md: 1 }} 
          justifyContent="center" 
          sx={{ 
            mt: { xs: 1, sm: 1.5, md: 2 },
            '@media (max-width: 600px) and (orientation: landscape)': {
              mt: 0.5
            }
          }}
        >
          {Array.from({ length: totalPages }).map((_, index) => (
            <FiberManualRecordIcon
              key={index}
              sx={{
                fontSize: { xs: 8, sm: 10, md: 12 },
                color: currentPage === index ? 'primary.main' : 'grey.300',
                cursor: 'pointer',
                '@media (max-width: 600px) and (orientation: landscape)': {
                  fontSize: 6
                }
              }}
              onClick={() => setCurrentPage(index)}
            />
          ))}
        </Stack>
      )}

      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={lightboxImages}
        index={currentImageIndex}
        render={{
          slide: ({ slide, offset }) => (
            <img 
              src={slide.src}
              alt={slide.alt}
              onError={(e) => {
                if (e.target.src !== slide.srcFallback) {
                  e.target.src = slide.srcFallback;
                }
              }}
              style={{ objectFit: 'contain', maxHeight: '90vh' }}
            />
          )
        }}
      />
    </Box>
  );
};

export default ImageGallery;
