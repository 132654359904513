// Theme colors and styles
export const colors = {
  gold: '#F4C145',
  darkText: '#333333',
  white: '#FFFFFF',
  background: {
    light: 'rgba(247, 212, 23, 0.1)'
  }
};

// Theme typography
export const typography = {
  h1: {
    fontFamily: 'Oswald, sans-serif',
    fontWeight: 700,
    fontSize: '2.5rem',
    letterSpacing: '-0.04em'
  },
  h2: {
    fontFamily: 'Oswald, sans-serif',
    fontWeight: 700,
    fontSize: '2rem',
    letterSpacing: '-0.01em'
  },
  body1: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.6,
    letterSpacing: 'normal'
  }
};
