import React from 'react';
import { Box, Container, Grid, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import Navbar from '../components/Navbar';
import { BannerText, SectionTitle, SectionSubtitle, BodyText } from '../components/shared/Typography';
import { 
  FullWidthSection,
  ContainerSection,
  HalfBox,
  ThirdBox,
  TwoThirdsBox,
  FeatureBox,
  FlexRow,
  GridLayout,
  ContentBox,
  MediaBox,
  LayoutPattern
} from '../components/shared/ViewComponents';
import ContentCard from '../components/shared/ContentCard';
import CardGrid from '../components/shared/CardGrid';
import OptimizedImage from '../components/OptimizedImage';
import { 
  ContentBoxHeading,
  ContentBoxHeadingCallout,
  SectionBanner,
  SectionBannerText,
  SectionBannerWrapper
} from '../components/SharedComponents';

const StyledCardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

const SectionCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(18, 18, 18, 0.8)',
  color: '#FFFFFF',
  height: '100%',
  borderRadius: 0,
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)'
}));

const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  background: `url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#000',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden'
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const TestContent = () => {
  return (
    <PageContainer>
      <Navbar />
      <MainContent>
        <BannerText>Test Content Page</BannerText>

        {/* Basic Layout Components */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Basic Layout Components
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          <FlexRow>
            <HalfBox>
              <ContentBoxHeading>Half Box Left</ContentBoxHeading>
              <Box sx={{ mt: 2, bgcolor: 'background.paper', p: 3 }}>
                Content for the left half
              </Box>
            </HalfBox>
            <HalfBox>
              <ContentBoxHeading>Half Box Right</ContentBoxHeading>
              <Box sx={{ mt: 2, bgcolor: 'background.paper', p: 3 }}>
                Content for the right half
              </Box>
            </HalfBox>
          </FlexRow>

          <FlexRow sx={{ mt: 4 }}>
            <ThirdBox>
              <FeatureBox backgroundColor="rgba(141, 174, 28, 0.9)">
                <ContentBoxHeadingCallout>One Third Box</ContentBoxHeadingCallout>
              </FeatureBox>
            </ThirdBox>
            <TwoThirdsBox>
              <ContentBoxHeading>Two Thirds Box</ContentBoxHeading>
              <Box sx={{ mt: 2, bgcolor: 'background.paper', p: 3 }}>
                This box takes up two-thirds of the width
              </Box>
            </TwoThirdsBox>
          </FlexRow>
        </ContainerSection>

        {/* Grid Layout Components */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Grid Layout Components
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          <ContentBoxHeading>Two Column Grid</ContentBoxHeading>
          <GridLayout.Half sx={{ mt: 2 }}>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Column 1 Content</Box>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Column 2 Content</Box>
          </GridLayout.Half>

          <ContentBoxHeading sx={{ mt: 4 }}>Three Column Grid</ContentBoxHeading>
          <GridLayout.Thirds sx={{ mt: 2 }}>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Column 1</Box>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Column 2</Box>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Column 3</Box>
          </GridLayout.Thirds>

          <ContentBoxHeading sx={{ mt: 4 }}>2/3 + 1/3 Grid</ContentBoxHeading>
          <GridLayout.TwoThirdsGrid sx={{ mt: 2 }}>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Wide Column</Box>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Narrow Column</Box>
          </GridLayout.TwoThirdsGrid>
        </ContainerSection>

        {/* Content Box Variants */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Content Box Variants
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          <GridLayout.Half>
            <Box>
              <ContentBoxHeading>Basic Box</ContentBoxHeading>
              <Box sx={{ mt: 2, bgcolor: 'background.paper', p: 3 }}>
                Basic box with background
              </Box>
            </Box>
            <Box>
              <ContentBoxHeading>Transparent Box</ContentBoxHeading>
              <Box sx={{ 
                mt: 2, 
                p: 3,
                backdropFilter: 'blur(10px)',
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }}>
                With backdrop blur effect
              </Box>
            </Box>
          </GridLayout.Half>

          <GridLayout.Half sx={{ mt: 4 }}>
            <Box>
              <ContentBoxHeading>Gradient Box</ContentBoxHeading>
              <Box sx={{ 
                mt: 2,
                p: 3,
                background: 'linear-gradient(45deg, #8DAE1C, #B4006C)'
              }}>
                With custom gradient
              </Box>
            </Box>
            <Box>
              <ContentBoxHeading>Float Box</ContentBoxHeading>
              <Box sx={{ 
                mt: 2,
                p: 3,
                bgcolor: 'background.paper',
                boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                transform: 'translateY(-20px)'
              }}>
                With shadow and lift effect
              </Box>
            </Box>
          </GridLayout.Half>
        </ContainerSection>

        {/* Media Box Variants */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Media Box Variants
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          <GridLayout.Thirds>
            <Box>
              <ContentBoxHeading>Square (1:1)</ContentBoxHeading>
              <Box sx={{ 
                mt: 2,
                position: 'relative',
                width: '100%',
                paddingTop: '100%',
                '& img': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }
              }}>
                <img src="/img/backgrounds/background_small.png" alt="Square" />
              </Box>
            </Box>
            <Box>
              <ContentBoxHeading>Wide (16:9)</ContentBoxHeading>
              <Box sx={{ 
                mt: 2,
                position: 'relative',
                width: '100%',
                paddingTop: '56.25%',
                '& img': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }
              }}>
                <img src="/img/backgrounds/background_small.png" alt="Wide" />
              </Box>
            </Box>
            <Box>
              <ContentBoxHeading>Portrait (3:4)</ContentBoxHeading>
              <Box sx={{ 
                mt: 2,
                position: 'relative',
                width: '100%',
                paddingTop: '133.33%',
                '& img': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }
              }}>
                <img src="/img/backgrounds/background_small.png" alt="Portrait" />
              </Box>
            </Box>
          </GridLayout.Thirds>
        </ContainerSection>

        {/* Layout Patterns */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Layout Patterns
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          <ContentBoxHeading>Zigzag Layout</ContentBoxHeading>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            '& > *:nth-of-type(even)': {
              flexDirection: 'row-reverse'
            }
          }}>
            <FlexRow>
              <HalfBox>
                <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Row 1 Content</Box>
              </HalfBox>
              <HalfBox>
                <OptimizedImage
                  src="/img/backgrounds/background_small.png"
                  alt="Row 1 Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </HalfBox>
            </FlexRow>
            <FlexRow>
              <HalfBox>
                <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Row 2 Content</Box>
              </HalfBox>
              <HalfBox>
                <OptimizedImage
                  src="/img/backgrounds/background_small.png"
                  alt="Row 2 Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </HalfBox>
            </FlexRow>
          </Box>

          <ContentBoxHeading sx={{ mt: 6 }}>Masonry Layout</ContentBoxHeading>
          <Box sx={{
            columnCount: {
              xs: 1,
              sm: 2,
              md: 3
            },
            columnGap: 3,
            '& > *': {
              breakInside: 'avoid',
              marginBottom: 3
            }
          }}>
            {[...Array(6)].map((_, i) => (
              <Box key={i} sx={{ bgcolor: 'background.paper', p: 3, mb: 2 }}>
                <ContentBoxHeading>Item {i + 1}</ContentBoxHeading>
                <Box sx={{ mt: 2 }}>
                  {i % 2 === 0 ? 'Short content' : 'Longer content that takes up more vertical space to create masonry effect'}
                </Box>
              </Box>
            ))}
          </Box>

          <ContentBoxHeading sx={{ mt: 6 }}>Overlap Layout</ContentBoxHeading>
          <Box sx={{
            position: 'relative',
            '& > *:not(:first-child)': {
              marginTop: '-10%',
              marginLeft: '10%',
              position: 'relative',
              zIndex: 1
            }
          }}>
            <Box sx={{ bgcolor: 'background.paper', p: 3 }}>Base Layer</Box>
            <Box sx={{ 
              bgcolor: 'background.paper',
              p: 3,
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
              transform: 'translateY(-20px)'
            }}>
              Overlapping Layer 1
            </Box>
            <Box sx={{ 
              p: 3,
              background: 'linear-gradient(45deg, #8DAE1C, #B4006C)'
            }}>
              Overlapping Layer 2
            </Box>
          </Box>
        </ContainerSection>

        {/* Content Cards */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Content Cards - Different Sizes
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          {/* Full Width Card */}
          <ContentBoxHeading>Full Width Card</ContentBoxHeading>
          <Box sx={{ mb: 4 }}>
            <ContentCard
              title="FULL WIDTH CONTENT CARD"
              subtitle="SPANS ENTIRE CONTAINER"
              accentColor="#8DAE1C"
              image="/img/backgrounds/background_small.png"
              imageOverlay="rgba(0,0,0,0.5)"
            >
              <BodyText>
                This is a sample paragraph showing how the body text should look in content cards. The styling should match the section cards, using the Inter font family and proper line height.
              </BodyText>
              <BodyText>
                Here's a second paragraph to demonstrate spacing between paragraphs and overall text flow. We want to ensure the typography is consistent across all cards.
              </BodyText>
              <BodyText sx={{ color: '#9BA1A6' }}>
                This is how secondary text appears, like the "Sponsored by" text.
              </BodyText>
            </ContentCard>
          </Box>

          {/* Half Width Cards */}
          <ContentBoxHeading>Half Width Cards (Two Column)</ContentBoxHeading>
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <ContentCard
                title="HALF WIDTH CARD"
                subtitle="LEFT COLUMN"
                accentColor="#B4006C"
              >
                <BodyText>
                  This card demonstrates how text flows in a narrower container. The typography should remain consistent regardless of the card's width.
                </BodyText>
                <BodyText>
                  All text elements should use the same styling as the main section cards: titles in NeusaBlack, subtitles with reduced opacity, and body text in Inter.
                </BodyText>
              </ContentCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContentCard
                title="HALF WIDTH CARD"
                subtitle="RIGHT COLUMN"
                accentColor="#B4006C"
              >
                <BodyText>
                  The spacing between text elements should be identical to the section cards, ensuring a cohesive look throughout the interface.
                </BodyText>
                <BodyText sx={{ color: '#9BA1A6' }}>
                  Secondary text in muted gray.
                </BodyText>
              </ContentCard>
            </Grid>
          </Grid>

          {/* Third Width Cards */}
          <ContentBoxHeading>Third Width Cards (Three Column)</ContentBoxHeading>
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <Grid item xs={12} md={4}>
              <ContentCard
                title="THIRD WIDTH CARD"
                subtitle="LEFT COLUMN"
                accentColor="#8DAE1C"
              >
                One third width card example.
              </ContentCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <ContentCard
                title="THIRD WIDTH CARD"
                subtitle="MIDDLE COLUMN"
                accentColor="#8DAE1C"
                image="/img/backgrounds/background_small.png"
              >
                One third with image.
              </ContentCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <ContentCard
                title="THIRD WIDTH CARD"
                subtitle="RIGHT COLUMN"
                accentColor="#8DAE1C"
                image="/img/backgrounds/background_small.png"
                imageOverlay="rgba(0,0,0,0.5)"
              >
                One third with overlay.
              </ContentCard>
            </Grid>
          </Grid>

          {/* Quarter Width Cards */}
          <ContentBoxHeading>Quarter Width Cards (Four Column)</ContentBoxHeading>
          <Grid container spacing={4} sx={{ mb: 4 }}>
            {[1, 2, 3, 4].map((num) => (
              <Grid item xs={12} sm={6} md={3} key={num}>
                <ContentCard
                  title={`QUARTER WIDTH ${num}`}
                  subtitle="COLUMN"
                  accentColor="#B4006C"
                  image={num % 2 === 0 ? "/img/backgrounds/background_small.png" : undefined}
                  imageOverlay={num === 4 ? "rgba(0,0,0,0.5)" : undefined}
                >
                  Quarter width card example.
                </ContentCard>
              </Grid>
            ))}
          </Grid>

          {/* Mixed Width Cards */}
          <ContentBoxHeading>Mixed Width Cards</ContentBoxHeading>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <ContentCard
                title="TWO THIRDS WIDTH CARD"
                subtitle="COLUMN"
                accentColor="#8DAE1C"
                image="/img/backgrounds/background_small.png"
              >
                This card takes up two-thirds of the container width.
              </ContentCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <ContentCard
                title="ONE THIRD WIDTH CARD"
                subtitle="COLUMN"
                accentColor="#8DAE1C"
              >
                This card takes up one-third width.
              </ContentCard>
            </Grid>
          </Grid>
        </ContainerSection>

        {/* Event Content Cards */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Event Content Cards
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <ContentCard
                title="PRO DIALOGUES"
                accentColor="#B4006C"
              >
                <BodyText sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Interactive dialogues with world class experts, with a FutureLab of best practice and collaborative solutions
                </BodyText>
              </ContentCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <ContentCard
                title="FORUMS"
                accentColor="#B4006C"
              >
                <BodyText sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  High-powered presentations and exchanges with global thought and practice leaders across society and enterprise
                </BodyText>
              </ContentCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <ContentCard
                title="OPEN DIALOGUES"
                accentColor="#B4006C"
              >
                <BodyText sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Participatory dialogues with the public, with a focus on local and regional sustainable development
                </BodyText>
              </ContentCard>
            </Grid>
          </Grid>

          {/* Festival Examples */}
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <ContentCard
                title="EVENTS"
                accentColor="#F4C145"
              >
                <BodyText sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Experience a rich tapestry of activities, from thought-provoking dialogues to immersive exhibitions and performances
                </BodyText>
              </ContentCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <ContentCard
                title="COLLABORATE"
                accentColor="#F4C145"
              >
                <BodyText sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Join forces with innovators, creators, and change-makers in shaping sustainable futures through active participation
                </BodyText>
              </ContentCard>
            </Grid>

            <Grid item xs={12} md={4}>
              <ContentCard
                title="CREATIVITY"
                accentColor="#F4C145"
              >
                <BodyText sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                  Explore the intersection of art, technology, and sustainability through exhibitions, performances, and interactive installations
                </BodyText>
              </ContentCard>
            </Grid>
          </Grid>
        </ContainerSection>

        {/* Section Cards */}
        <SectionBannerWrapper>
          <SectionBanner>
            <Container maxWidth="lg">
              <SectionBannerText>
                Section Cards
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <ContainerSection>
          <Grid container spacing={4} sx={{ justifyContent: 'space-between' }}>
            {/* Example Section Card 1 */}
            <Grid item xs={12} md={5.5}>
              <SectionCard>
                <StyledCardContent>
                  <SectionTitle>
                    CIRCULAR CITIES
                  </SectionTitle>
                  <SectionSubtitle>
                    SEMINAR SERIES
                  </SectionSubtitle>
                  <BodyText>
                    Circular Cities is a workshop and podcast series, developed by Futureperfect and partners.
                  </BodyText>
                  <BodyText>
                    The Circular Cities series helps professionals and learners develop basic knowledge, shared
                    understanding and pathways forward for circular strategy, design and operations in urban
                    development, architecture and built design, materials and resource flows.
                  </BodyText>
                  <BodyText sx={{ 
                    color: '#FFFFFF', 
                    fontFamily: 'NeusaNextPro-Bold, sans-serif',
                    fontSize: '1.2rem',
                    opacity: 0.9,
                    marginTop: 'auto',
                    fontWeight: 700
                  }}>
                    Info coming soon
                  </BodyText>
                </StyledCardContent>
              </SectionCard>
            </Grid>

            {/* Example Section Card 2 */}
            <Grid item xs={12} md={5.5}>
              <SectionCard>
                <StyledCardContent>
                  <SectionTitle>
                    FUTUREPERFECT TALKS
                  </SectionTitle>
                  <SectionSubtitle>
                    PODCAST
                  </SectionSubtitle>
                  <Box sx={{ display: 'flow-root' }}>
                    <OptimizedImage 
                      src={`${process.env.PUBLIC_URL}/img/logos/pod_logo.jpg`} 
                      alt="Futureperfect Talks" 
                      style={{ width: '100%', height: 'auto' }}
                    />
                    <BodyText>
                      The Futureperfect Talks are where spatial technology, sustainability and society - across cities, architecure, art, film, reality capture, entertainment, mapping, industry, medicine, science, game, metaverse, and more - are explored by the leading technologists, thinkers, planners and creators in the world.
                    </BodyText>
                    <BodyText sx={{ color: '#9BA1A6' }}>
                      Sponsored by Epic Games.
                    </BodyText>
                  </Box>
                </StyledCardContent>
              </SectionCard>
            </Grid>
          </Grid>
        </ContainerSection>

      </MainContent>
    </PageContainer>
  );
};

export default TestContent;
