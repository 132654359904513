import { Typography, styled, Box, Card } from '@mui/material';
import { colors } from '../theme';

export const ContentBoxHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontFamily: 'NeusaBlack, sans-serif !important',
  fontWeight: '700 !important',
  letterSpacing: '0.02em',  // Just slightly more than normal
  margin: 0,
  padding: 0,
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1,
  color: '#333333'
}));

export const ContentBoxHeadingCallout = styled(ContentBoxHeading)(({ theme }) => ({
  color: '#FFFFFF'
}));

export const SectionBannerWrapper = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: '91px', // Desktop navbar height
  zIndex: 1100, // Higher z-index to ensure it stays above other content
  width: '100%',
  // Mobile adjustments for two-row navbar
  [theme.breakpoints.down('sm')]: {
    // Adjust position based on strapline visibility
    // When strapline is visible: navbar(112px) + full strapline(38px) - current scroll offset
    // When strapline is hidden: just navbar(112px)
    top: 'calc(112px + max(0px, 38px - var(--strapline-offset, 0px)))',
    marginTop: 0,
    transition: 'top 0.1s ease-out', // Smooth transition as strapline scrolls
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    top: 'calc(98px + max(0px, 32px - var(--strapline-offset, 0px)))',
  }
}));

export const SectionBanner = styled(Box)(({ theme, color }) => ({
  width: '100%',
  backgroundColor: color || colors.gold,
  padding: theme.spacing(2.5, 0),
  margin: 0,
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.75, 0),
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    padding: theme.spacing(1.25, 0),
  }
}));

export const SectionBannerText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'NeusaBlack, sans-serif',
  fontWeight: 700,
  letterSpacing: '0.03em',
  textAlign: 'center',
  fontSize: '2.5rem',
  margin: 0,
  textTransform: 'uppercase',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.6rem',
    letterSpacing: '0.01em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    padding: 0,
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.3rem',
    letterSpacing: '0.01em',
  }
}));

export const SubSectionBannerWrapper = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: '175px', // Desktop: Navbar (91px) + SectionBanner height
  zIndex: 1099, // Just below SectionBanner but above other content
  width: '100%',
  backgroundColor: '#ffffff',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    // Dynamic calculation based on strapline visibility
    // Navbar(112px) + section banner(50px) + adjustable strapline height
    top: 'calc(112px + 50px + max(0px, 38px - var(--strapline-offset, 0px)))',
    marginTop: 0,
    transition: 'top 0.1s ease-out', // Smooth transition
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    top: 'calc(98px + 43px + max(0px, 32px - var(--strapline-offset, 0px)))',
  }
}));

export const SubSectionBanner = styled(Box)(({ theme, color }) => ({
  width: '100vw',
  position: 'relative', // Changed from sticky to relative
  marginLeft: 'calc(-50vw + 50%)',
  marginRight: 'calc(-50vw + 50%)',
  backgroundColor: color ? `${color}40` : `${colors.gold}40`,
  padding: theme.spacing(1, 0),
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.75, 0),
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    padding: theme.spacing(0.5, 0),
  }
}));

export const SubSectionBannerText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'NeusaBlack, sans-serif',
  fontWeight: 700,
  letterSpacing: '0.03em',
  textAlign: 'center',
  fontSize: '1.5rem',
  margin: 0,
  textTransform: 'uppercase',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
    letterSpacing: '0.01em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    padding: 0,
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '0.9rem',
  }
}));

export const ContentCategoryBannerWrapper = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: '239px', // Desktop: Navbar + SectionBanner + SubSectionBanner
  zIndex: 1098,
  width: '100%',
  backgroundColor: '#ffffff',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    // Dynamic calculation including all previous elements plus strapline adjustment
    top: 'calc(112px + 50px + 35px + max(0px, 38px - var(--strapline-offset, 0px)))',
    marginTop: 0,
    transition: 'top 0.1s ease-out', // Smooth transition
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    top: 'calc(98px + 43px + 30px + max(0px, 32px - var(--strapline-offset, 0px)))',
  }
}));

export const ContentCategoryBanner = styled(SubSectionBanner)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  minHeight: 'unset',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 0),
  }
}));

export const ContentCategoryBannerText = styled(SubSectionBannerText)({
  // Inherits all styles from SubSectionBannerText
});

export const CategoryContentSection = styled(Box)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor || 'rgba(180, 0, 108, 0.1)',
  display: 'flex',
  alignItems: 'center',
  width: '100vw',
  position: 'relative',
  left: '50%',
  right: '50%',
  marginLeft: '-50vw',
  marginRight: '-50vw',
  minHeight: '33.33vh',
  '& .content-wrapper': {
    width: '100%',
    maxWidth: 'none',
    padding: theme.spacing(4, { xs: 2, md: 4 })
  }
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  letterSpacing: 'normal',
  fontSize: '1.1rem',
  lineHeight: 1.8,
  color: '#333333'
}));

export const SectionCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#FFFFFF',
  height: '100%',
  transition: 'transform 0.3s ease-in-out',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-5px)'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  }
}));

// Podcast-specific components
export const PodcastCard = styled(SectionCard)({
  // Inherits all SectionCard styles by default
});

export const PodcastBodyText = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'Inter, sans-serif',
  fontSize: '0.9rem',
  lineHeight: 1.6,
  textAlign: 'left',
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0
  },
  opacity: 0.9
}));
