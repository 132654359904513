import React, { useRef, useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import { pageColors, textColors } from './shared/colors';
import { NavLinkText, PageNameText } from './shared/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Define straplines for each page
const pageStraplines = {
  '/': 'spaces for the worlds we make',
  '/festivals': 'experiences for the worlds we make',
  '/events': 'connections for the worlds we make',
  '/talks': 'conversations for the worlds we make'
};

const StyledAppBar = styled(AppBar)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor || theme.palette.primary.main,
  boxShadow: 'none',
  position: 'fixed', // Changed to fixed for better positioning
  top: 0,
  left: 0,
  right: 0,
  width: '100%',
  zIndex: 1200,
  transition: 'background-color 0.3s ease-in-out',
  '& .MuiToolbar-root': {
    minHeight: '91px',
    padding: 0
  },
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    '& .MuiToolbar-root': {
      flexDirection: 'column',
      minHeight: '112px', // Restore the original mobile height
      padding: 0
    },
    marginBottom: 0, // Remove any bottom margin
    paddingBottom: 0, // Remove any bottom padding
  }
}));

const NavLink = styled(NavLinkText)(({ theme }) => ({
  color: textColors.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
    padding: theme.spacing(0.5, 1),
    whiteSpace: 'nowrap'
  }
}));

const PageName = styled(PageNameText)(({ theme }) => ({
  marginLeft: '13px',
  height: '45px',
  marginTop: '1px', // Fine-tuned from 2px to 1px for optimal logo alignment
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '8px',
    height: '35px',
    marginTop: '2px' // Adjusted for mobile too
  },
  '@media (max-width: 600px) and (orientation: landscape)': {
    marginLeft: '6px',
    height: '32px',
    whiteSpace: 'nowrap',
    marginTop: '0px' // Adjusted for landscape mode
  }
}));

// Desktop strapline
const StraplineText = styled('div')(({ theme }) => ({
  fontFamily: 'Neusa-DemiBold, sans-serif',
  fontSize: '1.75rem',
  letterSpacing: '0.03em',
  lineHeight: 1.4,
  color: '#FFFFFF',
  opacity: 0.7,
  textAlign: 'center',
  textTransform: 'none',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap', // Prevent wrapping to two lines
  position: 'relative', // Ensure correct stacking
  zIndex: 5, // Higher than LogoContainer
  marginLeft: '-60px', // Shift strapline text to the left
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    display: 'none', // Hide on mobile as we'll use a separate component
    marginLeft: 0 // Reset margin for mobile
  }
}));

// Mobile strapline (separate bar)
const MobileStraplineBar = styled(Box)(({ theme, bgcolor }) => ({
  display: 'none', // Hidden on desktop
  position: 'fixed',
  top: '112px', // Place directly below the navbar
  left: '0',
  width: '100%',
  backgroundColor: bgcolor || theme.palette.primary.main,
  zIndex: 1190, // Below navbar but above other content
  transition: 'transform 0.3s ease-in-out',
  borderTop: '1px solid rgba(255,255,255,0.1)', // Restore subtle border
  willChange: 'transform', // Optimize for transform changes
  marginTop: 0, // Remove any margin
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    transform: 'translateY(0)', // Initial position
  },
  '@media (max-width: 600px) and (orientation: landscape)': {
    top: '98px', // Adjusted for landscape
  }
}));

const MobileStraplineText = styled('div')(({ theme }) => ({
  fontFamily: 'Neusa-DemiBold, sans-serif',
  fontSize: '1.2rem',
  letterSpacing: '0.02em',
  lineHeight: 1.3,
  color: '#FFFFFF',
  opacity: 0.9, // Increased opacity for better visibility
  textAlign: 'center',
  padding: theme.spacing(0.75, 0), // Removed horizontal padding for more space
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1rem',
    lineHeight: 1.2,
    padding: theme.spacing(0.5, 0)
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '250px', // Fixed width on desktop to match nav width
  paddingTop: theme.spacing(1),
  marginLeft: theme.spacing(-4),
  position: 'relative', // Ensure proper stacking
  zIndex: 1, // Lower than strapline
  [theme.breakpoints.down('md')]: {
    width: '200px',
    marginLeft: theme.spacing(-2)
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    width: '100%', // Full width on mobile
    marginLeft: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
    justifyContent: 'center',
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.25),
    justifyContent: 'center',
  }
}));

const getNavLinks = (pathname, customLinks) => {
  // If custom links are provided, use them
  if (customLinks) {
    return customLinks.map(text => ({ type: 'scroll', to: `/${text.toLowerCase().replace(' ', '-')}`, text }));
  }

  switch (pathname) {
    case '/festivals':
      return [
        { type: 'scroll', to: '#what', text: 'WHAT' },
        { type: 'scroll', to: '#goals', text: 'GOALS' },
        { type: 'scroll', to: '#who', text: 'WHO' },
        { type: 'scroll', to: '#program', text: 'PROGRAM' },
        { type: 'scroll', to: '#festivals', text: 'FESTIVALS' },
        { type: 'scroll', to: '#participate', text: 'PARTICIPATE' },
      ];
    case '/events':
      return [
        { type: 'scroll', to: '#what', text: 'WHAT' },
        { type: 'scroll', to: '#futureperfect-events', text: 'FUTUREPERFECT EVENTS' },
        { type: 'scroll', to: '#partner-events', text: 'PARTNER EVENTS' },
        { type: 'scroll', to: '#participate', text: 'PARTICIPATE' },
      ];
    case '/talks':
      return [
        { type: 'scroll', to: '#what', text: 'WHAT' },
        { type: 'scroll', to: '#seasons-1-2', text: 'S 1&2' },
        { type: 'scroll', to: '#seasons-3-4', text: 'S 3&4' },
        { type: 'scroll', to: '#partner-talks', text: 'PARTNER TALKS' },
        { type: 'scroll', to: '#participate', text: 'PARTICIPATE' },
      ];
    default:
      return [
        { type: 'scroll', to: '#what', text: 'WHAT' },
        { type: 'scroll', to: '#events', text: 'EVENTS' },
        { type: 'scroll', to: '#talks', text: 'TALKS' },
        { type: 'scroll', to: '#festival', text: 'FESTIVALS' },
        { type: 'scroll', to: '#participate', text: 'PARTICIPATE' }
      ];
  }
};

const scrollToSection = (e, sectionId) => {
  e.preventDefault();
  const section = document.querySelector(sectionId);
  if (section) {
    section.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }
};

// Scroll indicator component
const ScrollIndicator = styled(Box)(({ theme, direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  color: '#fff',
  cursor: 'pointer',
  opacity: 0.8,
  transition: 'opacity 0.2s ease',
  '&:hover': {
    opacity: 1
  },
  [theme.breakpoints.up('md')]: {
    display: 'none'
  },
  ...(direction === 'left' ? {
    left: 0
  } : {
    right: 0
  })
}));

const Navbar = ({ pageName, customLinks }) => {
  const { pathname } = useLocation();
  const navLinks = getNavLinks(pathname, customLinks);
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const scrollContainerRef = useRef(null);
  const mobileStraplineRef = useRef(null);
  
  // States to control scroll indicators
  const [showLeftIndicator, setShowLeftIndicator] = useState(false);
  const [showRightIndicator, setShowRightIndicator] = useState(false);

  // State for mobile strapline position
  const [straplineTransform, setStraplineTransform] = useState(0);
  
  // Adjust logo scale based on device and orientation
  const logoScale = isMobile ? (isLandscape ? 0.75 : 0.7) : isTablet ? 0.85 : 1;
  
  // Get the page name without the leading slash
  const pageNameFromPath = pathname.slice(1) || 'main';
  
  // Function to scroll the navigation container
  const scrollNav = (direction) => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const scrollAmount = direction === 'left' ? -100 : 100;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };
  
  // Check scroll position to show/hide indicators
  const checkScrollPosition = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      setShowLeftIndicator(container.scrollLeft > 0);
      setShowRightIndicator(
        container.scrollLeft < (container.scrollWidth - container.clientWidth - 5)
      );
    }
  };
  
  // Set up scroll event listener for nav scrolling
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      // Initial check
      checkScrollPosition();
      // Add scroll event listener
      container.addEventListener('scroll', checkScrollPosition);
      // Check when window resizes
      window.addEventListener('resize', checkScrollPosition);
      
      return () => {
        container.removeEventListener('scroll', checkScrollPosition);
        window.removeEventListener('resize', checkScrollPosition);
      };
    }
  }, []);
  
  // Set CSS variables for navbar height
  useEffect(() => {
    const updateNavbarHeight = () => {
      const navbar = document.querySelector('.MuiAppBar-root');
      if (navbar) {
        const navbarHeight = navbar.offsetHeight;
        document.documentElement.style.setProperty('--navbar-height', `${navbarHeight}px`);
        
        // Also set banner heights for sticky positioning
        document.documentElement.style.setProperty('--section-banner-height', isMobile ? '50px' : '84px');
        document.documentElement.style.setProperty('--subsection-banner-height', isMobile ? '35px' : '64px');
      }
    };
    
    // Initial update
    updateNavbarHeight();
    
    // Update on resize
    window.addEventListener('resize', updateNavbarHeight);
    return () => window.removeEventListener('resize', updateNavbarHeight);
  }, [isMobile]);
  
  // Handle strapline positioning on scroll
  useEffect(() => {
    if (!isMobile) return; // Only apply on mobile
    
    // Function to handle page scroll
    const handleScroll = () => {
      if (!mobileStraplineRef.current) return;
      
      const scrollY = window.scrollY;
      // Start moving strapline when scrolling begins
      const straplineHeight = mobileStraplineRef.current.offsetHeight;
      
      // Calculate strapline movement - use a more aggressive scroll factor
      let newTransform = 0;
      if (scrollY > 0) {
        // Make strapline move up faster than scroll (1.5x)
        newTransform = Math.min(scrollY * 1.5, straplineHeight);
        
        // Update CSS variable for sticky banners
        document.documentElement.style.setProperty(
          '--strapline-offset', 
          `${newTransform}px`
        );
        
        // Set flag when strapline is fully hidden
        if (newTransform >= straplineHeight) {
          document.documentElement.style.setProperty('--strapline-hidden', '1');
        } else {
          document.documentElement.style.setProperty('--strapline-hidden', '0');
        }
      } else {
        // Reset position when at the top
        document.documentElement.style.setProperty('--strapline-offset', '0px');
        document.documentElement.style.setProperty('--strapline-hidden', '0');
      }
      
      console.log('Strapline transform: ', newTransform); // Debug
      setStraplineTransform(newTransform);
    };
    
    // Run once to ensure proper initial positioning
    setTimeout(() => {
      handleScroll();
    }, 100); 
    
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);
  
  return (
    <>
      {/* Spacer div to prevent content from being hidden under the fixed navbar */}
      <Box sx={{ 
        height: { xs: 'var(--navbar-height, 112px)', md: '91px' } 
      }} />
      
      <StyledAppBar position="fixed" bgcolor={pageColors[pageNameFromPath]}>
        <Container maxWidth="xl" sx={{ px: 0 }}>
          <Toolbar disableGutters sx={{ 
            width: '100%',
            px: { xs: 1, sm: 4, md: 6 },
            pb: { xs: 0 },
            flexDirection: { xs: 'column', md: 'row' },
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
          }}>
            {/* Desktop layout with 3 equal sections */}
            <Box sx={{ 
              display: 'flex', 
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              display: { xs: 'none', md: 'flex' }
            }}>
              {/* Logo section - fixed width */}
              <Box sx={{ width: '250px', flexShrink: 0 }}>
                <LogoContainer sx={{ flexShrink: 0 }}>
                  <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    transform: `scale(${logoScale})`,
                    transformOrigin: 'left center'
                  }}>
                    <Link to="/" style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      transition: 'opacity 0.3s ease'
                    }}
                    onMouseEnter={(e) => e.target.style.opacity = '0.8'}
                    onMouseLeave={(e) => e.target.style.opacity = '1'}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/img/logos/futureperfect_logo.svg`}
                        alt="FUTUREPERFECT"
                        style={{ height: '45px' }}
                      />
                    </Link>
                    {(pathname === '/festivals' || pathname === '/events' || pathname === '/talks') && (
                      <PageName sx={{ opacity: '0.8' }}>
                        {pageName || (pathname === '/festivals' ? 'FESTIVAL' : pathname === '/events' ? 'EVENTS' : 'TALKS')}
                      </PageName>
                    )}
                  </Box>
                </LogoContainer>
              </Box>
              
              {/* Desktop strapline - center section */}
              <Box sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                justifyContent: 'center', 
                mx: 2
              }}>
                <StraplineText>
                  {pageStraplines[pathname]}
                </StraplineText>
              </Box>
              
              {/* Nav section - fixed width */}
              <Box sx={{ 
                width: '250px', 
                flexShrink: 0,
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'relative'
              }}>
                {/* Desktop links */}
                <Box 
                  ref={scrollContainerRef}
                  sx={{ 
                    display: 'flex', 
                    gap: 4,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    minHeight: '91px'
                  }}
                >
                  {navLinks.map((link, index) => (
                    link.type === 'scroll' ? (
                      <NavLink
                        key={index}
                        component="a"
                        onClick={(e) => scrollToSection(e, link.to)}
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                      >
                        {link.text}
                      </NavLink>
                    ) : (
                      <NavLink 
                        key={index}
                        component={Link}
                        to={link.to}
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                      >
                        {link.text}
                      </NavLink>
                    )
                  ))}
                </Box>
              </Box>
            </Box>
            
            {/* Mobile layout - full width stacked */}
            <Box sx={{ 
              width: '100%', 
              display: { xs: 'flex', md: 'none' },
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              {/* Mobile logo */}
              <LogoContainer>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    transform: isLandscape ? 'none' : undefined
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      transform: `scale(${logoScale})`,
                      transformOrigin: 'center center',
                      justifyContent: 'center', // Center content horizontally
                      '@media (orientation: landscape)': {
                        maxWidth: 'fit-content'
                      }
                    }}
                  >
                    <Link to="/" style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      transition: 'opacity 0.3s ease'
                    }}
                    onMouseEnter={(e) => e.target.style.opacity = '0.8'}
                    onMouseLeave={(e) => e.target.style.opacity = '1'}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/img/logos/futureperfect_logo.svg`}
                        alt="FUTUREPERFECT"
                        style={{ height: isMobile ? '35px' : '45px' }}
                      />
                    </Link>
                    {(pathname === '/festivals' || pathname === '/events' || pathname === '/talks') && (
                      <PageName sx={{ opacity: '0.8' }}>
                        {pageName || (pathname === '/festivals' ? 'FESTIVAL' : pathname === '/events' ? 'EVENTS' : 'TALKS')}
                      </PageName>
                    )}
                  </Box>
                </Box>
              </LogoContainer>
              
              {/* Mobile navigation */}
              <Box sx={{ 
                position: 'relative',
                width: '100%',
                paddingBottom: 0, // Reduced padding
                paddingTop: 0.5, // Reduced padding
                borderTop: '1px solid rgba(255,255,255,0.1)',
                marginBottom: 0,
                display: 'flex',
                alignItems: 'center'
              }}>
                {/* Left scroll indicator */}
                {showLeftIndicator && (
                  <ScrollIndicator 
                    direction="left"
                    onClick={() => scrollNav('left')}
                    sx={{ left: { xs: 2, sm: 4 }, top: '50%', zIndex: 3 }}
                  >
                    <ChevronLeftIcon fontSize="small" />
                  </ScrollIndicator>
                )}
                
                {/* Scrollable navigation container */}
                <Box 
                  ref={scrollContainerRef}
                  sx={{ 
                    display: 'flex', 
                    gap: { xs: 2, sm: 3 },
                    width: '100%',
                    justifyContent: 'center', // Center the links
                    overflowX: 'auto',
                    paddingLeft: { xs: 4, sm: 4 },
                    paddingRight: { xs: 4, sm: 4 },
                    paddingTop: { xs: 1, sm: 1 },
                    paddingBottom: { xs: 1, sm: 1 },
                    minHeight: '45px', // Ensure consistent height
                    alignItems: 'center', // Vertically center links
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '@media (orientation: landscape)': {
                      gap: 1.5
                    }
                  }}
                >
                  {navLinks.map((link, index) => (
                    link.type === 'scroll' ? (
                      <NavLink
                        key={index}
                        component="a"
                        onClick={(e) => scrollToSection(e, link.to)}
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                      >
                        {link.text}
                      </NavLink>
                    ) : (
                      <NavLink 
                        key={index}
                        component={Link}
                        to={link.to}
                        sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                      >
                        {link.text}
                      </NavLink>
                    )
                  ))}
                </Box>
                
                {/* Right scroll indicator */}
                {showRightIndicator && (
                  <ScrollIndicator 
                    direction="right"
                    onClick={() => scrollNav('right')}
                    sx={{ right: { xs: 2, sm: 4 }, top: '50%', zIndex: 3 }}
                  >
                    <ChevronRightIcon fontSize="small" />
                  </ScrollIndicator>
                )}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </StyledAppBar>
    
    {/* Mobile-only scrollable strapline */}
    <MobileStraplineBar 
      ref={mobileStraplineRef}
      bgcolor={pageColors[pageNameFromPath]}
      style={{ 
        transform: `translateY(-${straplineTransform}px)` // Using style for transforms
      }}
      sx={{ 
        width: '100%',
        left: 0,
        right: 0
      }}
    >
      <MobileStraplineText>
        {pageStraplines[pathname]}
      </MobileStraplineText>
    </MobileStraplineBar>
    </>
  );
};

export default Navbar;
