import React from 'react';
import { Box, Container, Typography, styled } from '@mui/material';
import HeroCarousel from './HeroCarousel';

const NavLink = styled('a')({
  color: '#FFFFFF',
  textDecoration: 'none',
  fontFamily: 'Oswald',
  fontWeight: 700,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline'
  }
});

const PageHero = ({ 
  title, 
  subtitle,
  strapline,
  color,
  navLinks,
  backgroundImage,
  carouselImages,
  height = 'calc(100vh - 91px)', // Subtract navbar height
  overlay = true
}) => {
  return (
    <Box sx={{ 
      position: 'relative',
      height,
      width: '100%',
      overflow: 'hidden'
    }}>
      {carouselImages ? (
        <HeroCarousel images={carouselImages} />
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 0
          }}
        />
      )}

      {overlay && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1
          }}
        />
      )}

      <Container 
        maxWidth="lg" 
        sx={{ 
          position: 'relative',
          height: '100%',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          color: '#fff'
        }}
      >
        {strapline && (
          <Typography
            variant="h1"
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              width: '100%',
              maxWidth: '1600px',
              textAlign: 'center',
              fontSize: '5.5rem',
              fontFamily: 'NeusaBlack, sans-serif',
              fontWeight: 700,
              letterSpacing: '0.02em',
              textShadow: '0 0 30px rgba(0,0,0,0.4)',
              padding: '0 1rem',
              margin: 0,
              display: 'block',
              color: 'rgba(255, 255, 255, 0.7)',
              whiteSpace: 'nowrap',
              textTransform: 'none' // Ensures text isn't converted to uppercase
            }}
          >
            {strapline}
          </Typography>
        )}
        {title && (
          <Typography 
            variant="h1" 
            sx={{ 
              color: '#FFFFFF',
              mb: subtitle ? 2 : 0
            }}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography 
            variant="h2" 
            sx={{ 
              color: '#FFFFFF',
              mb: 4
            }}
          >
            {subtitle}
          </Typography>
        )}
        {navLinks && (
          <Box sx={{ display: 'flex', gap: 4 }}>
            {navLinks.map((link, index) => (
              <NavLink key={index} href={link.href}>
                {link.text}
              </NavLink>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default PageHero;
