import React from 'react';
import { Helmet } from 'react-helmet';
import { 
  Box, 
  Typography, 
  Container,
  Grid,
  CardContent,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Navbar from '../components/Navbar';
import { 
  SectionBanner, 
  SectionBannerText, 
  SectionBannerWrapper,
  SubSectionBanner,
  SubSectionBannerText,
  SubSectionBannerWrapper,
  ContentCategoryBanner,
  ContentCategoryBannerText,
  ContentCategoryBannerWrapper,
  PodcastCard,
  PodcastBodyText,
  SectionCard,
  BodyText
} from '../components/SharedComponents';
import { colors } from '../theme';
import { colorVariants, pageColors } from '../components/shared/colors';
import ContentCard from '../components/shared/ContentCard';
import SectionBodyText from '../components/SectionBodyText';
import HeroCarousel from '../components/HeroCarousel';

// Styled components
const PageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0, // Removed padding to prevent double padding with MainContent
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4) // Restore padding on desktop
  }
}));

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4)
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '85%',
  margin: '0 auto',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    width: '95%',
  }
}));

const WiderContentWrapper = styled(Box)(({ theme }) => ({
  width: '95%',
  margin: '0 auto',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  textTransform: 'uppercase',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(0.5),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '3.5rem',
  textAlign: 'left',
  letterSpacing: '0.02em',
  lineHeight: 1,
  // Match banner text size on mobile
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.6rem', // Same as SectionBannerText mobile size
    letterSpacing: '0.01em',
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.3rem', // Same as SectionBannerText landscape size
    letterSpacing: '0.01em',
  }
}));

const SectionSubtitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  marginBottom: theme.spacing(3),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '3rem',
  textAlign: 'left',
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1,
  opacity: 0.6,
  // Slightly smaller than the title on mobile
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem', // Slightly smaller than title
    letterSpacing: '0.01em',
    marginBottom: theme.spacing(2),
  },
  // Landscape mobile adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.1rem', // Slightly smaller than title
    letterSpacing: '0.01em',
    marginBottom: theme.spacing(1.5),
  }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2.5),
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2.5),
  paddingBottom: 0,
  '&:last-child': {
    paddingBottom: 0
  },
  '& h3.MuiTypography-root': { // Target for SectionTitle
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem !important',
      lineHeight: '1.1 !important',
      marginTop: '1rem !important',
    },
    '@media (max-width: 600px) and (orientation: landscape)': {
      fontSize: '1.3rem !important',
      lineHeight: '1 !important',
    }
  },
  '& h4.MuiTypography-root': { // Target for SectionSubtitle
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem !important',
      lineHeight: '1.1 !important',
      marginBottom: '1.5rem !important',
    },
    '@media (max-width: 600px) and (orientation: landscape)': {
      fontSize: '1.1rem !important',
      lineHeight: '1 !important',
      marginBottom: '1rem !important',
    }
  }
}));

const PodcastLogo = styled('img')(({ theme }) => ({
  width: '200px',
  height: 'auto',
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
  float: 'left'
}));

const ScrollIndicator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: 'rgba(255, 255, 255, 0.4)',
  fontSize: '0.75rem',
  fontFamily: 'Inter, sans-serif',
  pointerEvents: 'none',
  zIndex: 1
}));

const SpotifyEmbed = styled('iframe')({
  borderRadius: '12px',
  width: '100%',
  height: '160px',
  marginTop: '3px'
});

const EpisodesContainer = styled(Box)(({ theme }) => ({
  height: '400px',
  overflowY: 'auto',
  marginTop: theme.spacing(1),
  position: 'relative',
  '& > iframe': {
    marginBottom: 0
  },
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)'
    }
  }
}));

const Section = styled(Box)(({ theme }) => ({
  minHeight: 'calc(100vh - 91px)',  // Subtract navbar height
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#000000',
  position: 'relative'
}));

const SubsectionIntro = styled(Box)(({ theme }) => ({
  minHeight: '50vh',
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}));

const HeroText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  width: '100%',
  maxWidth: '1600px',
  textAlign: 'center',
  fontSize: '5.5rem',
  fontFamily: 'NeusaBlack, sans-serif',
  fontWeight: 700,
  letterSpacing: '0.02em',
  textShadow: '0 0 30px rgba(0,0,0,0.4)',
  padding: '0 1rem',
  margin: 0,
  display: 'block',
  color: 'rgba(255, 255, 255, 0.7)',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('md')]: {
    fontSize: '4.5rem'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.5rem'
  }
}));

const ContentSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: '#FFFFFF'
}));

const Talks = () => {
  const theme = useTheme();
  
  // Add custom styles for podcast headings via React Helmet
  const customStyles = `
    @media (max-width: 600px) {
      .podcast-title {
        font-size: 1.6rem !important;
        line-height: 1.1 !important;
      }
      .podcast-subtitle {
        font-size: 1.4rem !important;
        line-height: 1.1 !important;
      }
    }
    
    @media (max-width: 600px) and (orientation: landscape) {
      .podcast-title {
        font-size: 1.3rem !important;
        line-height: 1 !important;
      }
      .podcast-subtitle {
        font-size: 1.1rem !important;
        line-height: 1 !important;
      }
    }
  `;

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',
      backgroundColor: '#000000'
    }}>
      <Helmet>
        <title>FUTUREPERFECT TALKS</title>
        <style>{customStyles}</style>
      </Helmet>
      
      <Navbar pageName="TALKS" />
      
      {/* Hero Section */}
      <Section>
        <HeroCarousel 
          images={[
            { 
              url: '/img/carousel/hero_carousel_A.jpg',
              alt: 'Futureperfect Talks Hero'
            }
          ]}
          strapline="Conversations For The Worlds We Make"
          color={pageColors.talks}
        />
      </Section>

      {/* WHAT Section */}
      <Box id="what" sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
        backgroundSize: '200%', /* Zoomed out */
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#000'
      }}>
        <SectionBannerWrapper>
          <SectionBanner color={pageColors.talks}>
            <Container maxWidth="lg">
              <SectionBannerText>
                WHAT ARE FUTUREPERFECT TALKS?
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        <Box sx={{ 
          minHeight: 'calc(100vh - 91px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Container maxWidth="lg">
            <SectionBodyText variant="white">
              The Futureperfect Talks explore the intersection of spatial technology, sustainability, and society. Leading technologists, thinkers, planners, and creators from around the world discuss applications across diverse domains including architecture, art, film, entertainment, medicine, gaming, and the metaverse.
            </SectionBodyText>
          </Container>
        </Box>
      </Box>

      {/* Seasons 1 & 2 Section */}
      <Box id="seasons-1-2" sx={{ 
        backgroundColor: '#01579B40', /* Semi-transparent dark blue */
        minHeight: 'calc(100vh - 91px)',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <SectionBannerWrapper sx={{ mb: 0 }}>
          <SectionBanner color={pageColors.talks}>
            <Container maxWidth="lg">
              <SectionBannerText>
                SEASONS 1 & 2
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <PageContainer>
          <MainContent>
            <WiderContentWrapper>
              <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} sx={{ 
                justifyContent: { xs: 'center', md: 'space-around' } 
              }}>
                {/* Futureperfect Talks Section 1 */}
                <Grid item xs={12} md={11} lg={5.8}>
                  <PodcastCard sx={{ width: '100%' }}>
                    <StyledCardContent>
                      <SectionTitle variant="h3" className="podcast-title">
                        FUTUREPERFECT TALKS
                      </SectionTitle>
                      <SectionSubtitle variant="h4" className="podcast-subtitle">
                        SEASON 1
                      </SectionSubtitle>
                      <Box sx={{ display: 'flow-root', position: 'relative' }}>
                        <PodcastLogo 
                          src={`${process.env.PUBLIC_URL}/img/logos/pod_logo.jpg`} 
                          alt="Futureperfect Talks" 
                        />
                        <PodcastBodyText>
                          Season 1 of the Futureperfect Talks are an exploration of design and spatial technology in practice - and how they are starting to overlap and merge, across architecture, game, urbanism and more.
                        </PodcastBodyText>
                        <PodcastBodyText sx={{ color: '#9BA1A6' }}>
                          Sponsored by Epic Games.
                        </PodcastBodyText>
                        <ScrollIndicator>
                          scroll for more episodes
                          <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
                        </ScrollIndicator>
                      </Box>
                      <EpisodesContainer>
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/4u7yaEAURgMtnc6PFVKmkm" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/5mJa7J2ef0T5FO8HbywGoC" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/7vJQ0458bEQIiyLcCG5TJr" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/2ux9cTl8pUfr02BTblTnBE" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/0hftbh0YbckJ3oN2usGfgb" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/09Su6uwcghEFeOxspfGgMP" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/6lqUQkMEcZxicHQ5hZd85c" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                      </EpisodesContainer>
                    </StyledCardContent>
                  </PodcastCard>
                </Grid>

                {/* Futureperfect Talks Section 2 */}
                <Grid item xs={12} md={11} lg={5.8}>
                  <PodcastCard sx={{ width: '100%' }}>
                    <StyledCardContent>
                      <SectionTitle variant="h3" className="podcast-title">
                        FUTUREPERFECT TALKS
                      </SectionTitle>
                      <SectionSubtitle variant="h4" className="podcast-subtitle">
                        SEASON 2
                      </SectionSubtitle>
                      <Box sx={{ display: 'flow-root', position: 'relative' }}>
                        <PodcastLogo 
                          src={`${process.env.PUBLIC_URL}/img/logos/pod_logo.jpg`} 
                          alt="Futureperfect Talks" 
                        />
                        <PodcastBodyText>
                          Season 2 of the Futureperfect Talks take the investigations further into deeper applications of design and spatial technology - including modular housing, micromobility, and robotics used in built design.
                        </PodcastBodyText>
                        <PodcastBodyText sx={{ color: '#9BA1A6' }}>
                          Sponsored by Epic Games.
                        </PodcastBodyText>
                        <ScrollIndicator>
                          scroll for more episodes
                          <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
                        </ScrollIndicator>
                      </Box>
                      <EpisodesContainer>
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/0ANXZwHjwxD7PWR9hzwWo0" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/2utWSrMkzVUGGReCVtaGyH" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/72Fr0VFlS3WbV4wZ427M2f" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/0osZGGuNId15YCch0CDMrz" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/7iKDS03otraNQ9HPrhnoTi" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/3OxwGqYKsldsK62yQ9U1UX" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/50LVZQoExVedN4AeINzoaB" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                        <SpotifyEmbed 
                          src="https://open.spotify.com/embed/episode/0AJni8fE7FN4mrHITbuJvv" 
                          height="160" 
                          frameBorder="0" 
                          allowFullScreen="" 
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                          loading="lazy"
                        />
                      </EpisodesContainer>
                    </StyledCardContent>
                  </PodcastCard>
                </Grid>
              </Grid>
            </WiderContentWrapper>
          </MainContent>
        </PageContainer>
      </Box>

      {/* Seasons 3 & 4 Section */}
      <Box id="seasons-3-4" sx={{ 
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
        backgroundSize: '200%', /* Zoomed out */
        backgroundPosition: 'top left', /* Shifted position */
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#000',
        minHeight: 'calc(100vh - 91px)',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <SectionBannerWrapper sx={{ mb: 0 }}>
          <SectionBanner color={pageColors.talks}>
            <Container maxWidth="lg">
              <SectionBannerText>
                SEASONS 3 & 4
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <PageContainer>
          <MainContent>
            <WiderContentWrapper>
              <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} sx={{ 
                justifyContent: { xs: 'center', md: 'space-around' } 
              }}>
                {/* Season 3 */}
                <Grid item xs={12} md={11} lg={5.8}>
                  <PodcastCard sx={{ width: '100%' }}>
                    <StyledCardContent>
                      <SectionTitle variant="h3" className="podcast-title">
                        FUTUREPERFECT TALKS
                      </SectionTitle>
                      <SectionSubtitle variant="h4" className="podcast-subtitle">
                        SEASON 3
                      </SectionSubtitle>
                      <Box sx={{ display: 'flow-root', position: 'relative' }}>
                        <PodcastLogo 
                          src={`${process.env.PUBLIC_URL}/img/logos/pod_logo.jpg`} 
                          alt="Futureperfect Talks" 
                        />
                        <PodcastBodyText>
                          Season 3 of the Futureperfect Talks will explore the intersection of technology and sustainability in the built environment, focusing on circular economy principles and innovative design solutions.
                        </PodcastBodyText>
                        <PodcastBodyText sx={{ color: '#9BA1A6' }}>
                          Coming Soon
                        </PodcastBodyText>
                      </Box>
                      {/* No episodes yet */}
                    </StyledCardContent>
                  </PodcastCard>
                </Grid>

                {/* Season 4 */}
                <Grid item xs={12} md={11} lg={5.8}>
                  <PodcastCard sx={{ width: '100%' }}>
                    <StyledCardContent>
                      <SectionTitle className="podcast-title">
                        FUTUREPERFECT TALKS
                      </SectionTitle>
                      <SectionSubtitle className="podcast-subtitle">
                        SEASON 4
                      </SectionSubtitle>
                      <Box sx={{ display: 'flow-root', position: 'relative' }}>
                        <PodcastLogo 
                          src={`${process.env.PUBLIC_URL}/img/logos/pod_logo.jpg`} 
                          alt="Futureperfect Talks" 
                        />
                        <PodcastBodyText>
                          Season 4 of the Futureperfect Talks will engage with applications and developers of artificial intelligence in the built design and spatial technology sectors, examining the potential for AI-driven innovation and transformation.
                        </PodcastBodyText>
                        <PodcastBodyText sx={{ color: '#9BA1A6' }}>
                          Coming Soon
                        </PodcastBodyText>
                      </Box>
                      {/* No episodes yet */}
                    </StyledCardContent>
                  </PodcastCard>
                </Grid>
              </Grid>
            </WiderContentWrapper>
          </MainContent>
        </PageContainer>
      </Box>

      {/* Partner Talks Section */}
      <Box id="partner-talks">
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color={pageColors.talks}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                PARTNER TALKS
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Box sx={{ 
          minHeight: '50vh',
          backgroundColor: colorVariants.talks.medium,
          display: 'flex',
          alignItems: 'center'
        }}>
          <WiderContentWrapper>
            <SectionBodyText variant="white">
              Futureperfect produces, hosts, and promotes podcast series with partners, bringing together diverse voices and perspectives on design, technology, and sustainability.
            </SectionBodyText>
          </WiderContentWrapper>
        </Box>

        {/* Resource Talks Partner Subsection */}
        <SubSectionBannerWrapper>
          <SubSectionBanner sx={{ backgroundColor: '#040835' }}>
            <Container maxWidth="xl" sx={{ 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}>
              <Box component="img" 
                src="/img/logos/resource_talks_banner.jpg"
                alt="Resource Talks"
                sx={{ 
                  height: 'auto',
                  maxWidth: '100%',
                  maxHeight: '60px',
                  my: 1
                }}
              />
            </Container>
          </SubSectionBanner>
        </SubSectionBannerWrapper>

        {/* Resource Talks introduction block */}
        <SubsectionIntro sx={{ backgroundColor: 'rgba(4, 8, 53, 0.9)' }}>
          <Container maxWidth="lg">
            <SectionBodyText variant="white">
              Resource Vision offers the Resource Talks - investigations in environmental science, sustainable design, nature, and the life support systems of the planet including climate and more, speaking to world-leading scholars, creators, curators, activists and teachers to review and animate progress, understand barriers, and link science and built design.
            </SectionBodyText>
          </Container>
        </SubsectionIntro>

        {/* Resource Talks Series I */}
        <Box sx={{ position: 'relative' }}>
          <ContentCategoryBannerWrapper sx={{ 
            position: 'sticky',
            top: '239px', // Keep original top value
            maxWidth: '100%',
            left: 0,
            right: 0
          }}>
            <ContentCategoryBanner sx={{ backgroundColor: '#040835' }}>
              <Container maxWidth="xl">
                <ContentCategoryBannerText>
                  RESOURCE TALKS PARTS 1 & 2
                </ContentCategoryBannerText>
              </Container>
            </ContentCategoryBanner>
          </ContentCategoryBannerWrapper>

          <Box sx={{ 
            backgroundColor: 'rgba(4, 8, 53, 0.05)',
            padding: theme.spacing(4, 0)
          }}>
          <ContentWrapper>
            <Grid container spacing={4}>
              <Grid item xs={12} md={5.5}>
                <PodcastCard>
                  <StyledCardContent>
                    <SectionTitle variant="h3" className="podcast-title">
                      RESOURCE TALKS
                    </SectionTitle>
                    <SectionSubtitle variant="h4" className="podcast-subtitle">
                      PART 1
                    </SectionSubtitle>
                    <Box sx={{ display: 'flow-root', position: 'relative' }}>
                      <PodcastLogo 
                        src={`${process.env.PUBLIC_URL}/img/logos/resource_talks_banner.jpg`} 
                        alt="Resource Talks" 
                      />
                      <PodcastBodyText>
                        Part I of Resource Talks explores the fundamentals of environmental science and sustainable design, examining urban planning, circular economy, biomimicry, and digital twins for sustainable cities.
                      </PodcastBodyText>
                      <PodcastBodyText sx={{ color: '#9BA1A6' }}>
                        Produced by Resource Vision.
                      </PodcastBodyText>
                      <ScrollIndicator>
                        scroll for more episodes
                        <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
                      </ScrollIndicator>
                    </Box>
                    <EpisodesContainer>
                      {/* Real episodes from resource_talks.json */}
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/5sE11L6L1KY2aIV7hFwZlk?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/1CqQOj4mC10USPVx5qpjHG?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/5iOIi7KmFP2BcG4jzZFbyz?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/26MBrdt9dusighF05q5O4t?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                    </EpisodesContainer>
                  </StyledCardContent>
                </PodcastCard>
              </Grid>
              
              <Grid item xs={12} md={5.5}>
                <PodcastCard>
                  <StyledCardContent>
                    <SectionTitle variant="h3" className="podcast-title">
                      RESOURCE TALKS
                    </SectionTitle>
                    <SectionSubtitle variant="h4" className="podcast-subtitle">
                      PART 2
                    </SectionSubtitle>
                    <Box sx={{ display: 'flow-root', position: 'relative' }}>
                      <PodcastLogo 
                        src={`${process.env.PUBLIC_URL}/img/logos/resource_talks_banner.jpg`} 
                        alt="Resource Talks" 
                      />
                      <PodcastBodyText>
                        Part II of Resource Talks delves into advanced applications of environmental science through topics including mass timber construction, regenerative design, urban mobility, and biophilic design strategies.
                      </PodcastBodyText>
                      <PodcastBodyText sx={{ color: '#9BA1A6' }}>
                        Produced by Resource Vision.
                      </PodcastBodyText>
                      <ScrollIndicator>
                        scroll for more episodes
                        <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
                      </ScrollIndicator>
                    </Box>
                    <EpisodesContainer>
                      {/* Real episodes from resource_talks.json - Part II */}
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/7LUxf7Gx5OdrpRGdpN1t1J?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/4Q3tWHbPDH4se6a4sPW9Qo?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/13pDGhEU6nohVUk8DwEV4r?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/1lAu5dRpn4C1RaibqAZumy?utm_source=generator" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                    </EpisodesContainer>
                  </StyledCardContent>
                </PodcastCard>
              </Grid>
            </Grid>
          </ContentWrapper>
          </Box>
        </Box>


      </Box>

      {/* Participate Section */}
      <Box id="participate">
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color={pageColors.talks}>
            <Container maxWidth="lg">
              <SectionBannerText>
                PARTICIPATE
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        
        <Section sx={{ 
          backgroundColor: '#01579B40', /* Semi-transparent dark blue */
          minHeight: 'calc(100vh - 91px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', /* Center content vertically */
          alignItems: 'center'
        }}>
          <ContentWrapper>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
              <Grid item xs={12}>
                <SectionBodyText variant="white">
                  Below are the ways to participate in Futureperfect Talks.<br/><br/>
                  <a href="mailto:hello@futureperfect.net" style={{ color: 'inherit', textDecoration: 'none' }}>hello@futureperfect.net</a>
                </SectionBodyText>
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} sx={{ mt: { xs: 1, md: 2 } }}>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard 
                  title="JOIN"
                  accentColor="#01579B"
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Sign up for updates and invites to talks - including live podcasts, private discussions and more.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="https://discord.gg/R2NKjCm" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Join the conversation →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard 
                  title="SPEAK"
                  accentColor="#01579B"
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Share your expertise and insights as a speaker or moderator in our talks.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="/register" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Register as a speaker →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard 
                  title="CREATE"
                  accentColor="#01579B"
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Collaborate with us to host, develop, produce, translate, distribute, promote the talks.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="/create" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Share your ideas →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard 
                  title="SPONSOR"
                  accentColor="#01579B"
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Support our mission and gain visibility among creative and technical leaders and emerging contributors.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="/sponsor" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Explore sponsorship →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>
      </Box>
    </Box>
  );
};

export default Talks;
