import React from 'react';
import { Typography } from '@mui/material';

const SectionBodyText = ({ variant = 'default', children, ...props }) => {
  const getColor = () => {
    switch (variant) {
      case 'white':
        return '#FFFFFF';
      default:
        return '#333333';
    }
  };

  return (
    <Typography
      {...props}
      sx={{
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        letterSpacing: 'normal',
        fontSize: {
          xs: '1rem',    // Mobile (reduced from 1.2rem)
          sm: '1.3rem',  // Tablet (reduced from 1.6rem)
          md: '1.5rem',  // Small desktop (reduced from 1.8rem)
          lg: '1.8rem',  // Large desktop (reduced from 2.2rem)
        },
        lineHeight: 1.4, // Reduced from 1.5
        color: getColor(),
        '@media (max-width: 600px) and (orientation: landscape)': {
          fontSize: '0.9rem', // Reduced from 1rem
          lineHeight: 1.3,    // Reduced from 1.4
        },
        ...props.sx
      }}
    >
      {children}
    </Typography>
  );
};

export default SectionBodyText;
