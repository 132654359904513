import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  Avatar,
  Divider,
  Alert,
  InputAdornment,
  useTheme,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import { SectionBanner, SectionBannerText, SectionBannerWrapper } from '../components/SharedComponents';
import Navbar from '../components/Navbar';
import { pageColors } from '../components/shared/colors';

const PageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  background: `url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#000',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '85%',
  margin: '0 auto',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    width: '95%',
  }
}));

const MainContent = styled(Container)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(8),
}));

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: theme.spacing(1),
  color: '#FFFFFF',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    padding: theme.spacing(2),
  }
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '2.5rem',
  color: '#FFFFFF',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    marginBottom: theme.spacing(3),
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.8rem',
    marginBottom: theme.spacing(2),
  }
}));

const FormSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.5rem',
  color: '#FFFFFF',
  borderLeft: `4px solid ${pageColors.talks}`,
  paddingLeft: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3rem',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(2),
  }
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: pageColors.talks,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: pageColors.talks,
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

const FilePreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(0.5),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: pageColors.talks,
  color: '#FFFFFF',
  padding: theme.spacing(1.5, 4),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: theme.palette.augmentColor({
      color: { main: pageColors.talks }
    }).dark,
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FormSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  }
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '80px',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    height: '60px',
  }
}));



const Sponsor = () => {
  const theme = useTheme();
  
  // Add CSS to hide mobile strapline
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
      div[class*="MobileStraplineBar"] {
        display: none !important;
      }
    `;
    document.head.appendChild(styleEl);
    
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);
  
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    title: '',
    email: '',
    interests: '',
    description: '',
    goals: '',
    linkedin: '',
    websiteUrl: '',
    budget: '',
    timeline: '',
    logo: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submission, setSubmission] = useState({ status: null, message: '' });
  const [logoPreview, setLogoPreview] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Clear error when field is edited
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type and size
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const maxSize = 5 * 1024 * 1024; // 5MB
    
    if (!validTypes.includes(file.type)) {
      setFormErrors({
        ...formErrors,
        [fileType]: 'Please upload a JPG, PNG, or WebP image',
      });
      return;
    }
    
    if (file.size > maxSize) {
      setFormErrors({
        ...formErrors,
        [fileType]: 'File size must be less than 5MB',
      });
      return;
    }

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      if (fileType === 'headshot') {
        setHeadshotPreview(reader.result);
      } else {
        setLogoPreview(reader.result);
      }
    };
    reader.readAsDataURL(file);

    // Update form data
    setFormData({
      ...formData,
      [fileType]: file,
    });
    
    // Clear error
    if (formErrors[fileType]) {
      setFormErrors({
        ...formErrors,
        [fileType]: null,
      });
    }
  };

  const removeFile = (fileType) => {
    if (fileType === 'headshot') {
      setHeadshotPreview('');
    } else {
      setLogoPreview('');
    }
    
    setFormData({
      ...formData,
      [fileType]: null,
    });
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    
    if (!formData.organization.trim()) {
      errors.organization = 'Organization is required';
    }
    
    if (!formData.title?.trim()) {
      errors.title = 'Title/Position is required';
    }
    
    if (!formData.email?.trim()) {
      errors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }
    
    if (!formData.interests?.trim()) {
      errors.interests = 'Areas of interest are required';
    }
    
    if (!formData.description?.trim()) {
      errors.description = 'Organization description is required';
    }
    
    if (!formData.goals?.trim()) {
      errors.goals = 'Sponsorship goals are required';
    }
    
    // LinkedIn URL validation (optional field)
    if (formData.linkedin && !formData.linkedin.match(/^(https?:\/\/)?(www\.)?linkedin\.com\/company\/[\w-]+\/?$/)) {
      errors.linkedin = 'Please enter a valid LinkedIn company page URL';
    }
    
    // Website URL validation (optional field)
    if (formData.websiteUrl && !formData.websiteUrl.match(/^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+\/?.*$/)) {
      errors.websiteUrl = 'Please enter a valid URL';
    }
    
    if (Object.keys(errors).length === 0) {
      return true;
    }
    
    setFormErrors(errors);
    return false;
  };

  // Function to upload files to Discord
  const uploadToDiscord = async (formData) => {
    try {
      // Discord webhook URL - use environment variable if available, fall back to direct URL for local dev
      const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_URL || 'https://discord.com/api/webhooks/1353929283705503815/9nycnXECvP4lH9s38N2r5wMVGK6RYOH_YNYwQBCV_68VNQSwUaAAYnRYaCEg3svV7-60';
      
      // Create detailed message with sponsor registration info
      const message = `
**New Sponsorship Inquiry**
**Name:** ${formData.name}
**Title/Position:** ${formData.title}
**Organization:** ${formData.organization}
**Email:** ${formData.email}
**Areas of Interest:** ${formData.interests}
**Sponsorship Goals:** ${formData.goals}
**LinkedIn:** ${formData.linkedin || "N/A"}
**Website:** ${formData.websiteUrl || "N/A"}
**Budget Range:** ${formData.budget || "N/A"}
**Timeline:** ${formData.timeline || "N/A"}
**Organization Description:**
${formData.description}
      `;

      // Send first message with info and headshot
      const firstData = new FormData();
      firstData.append('content', message);
      
      // Add the logo image if available
      if (formData.logo) {
        firstData.append('file', formData.logo, formData.logo.name);
      }
      
      // Send to Discord webhook
      const response1 = await fetch(webhookUrl, {
        method: 'POST',
        body: firstData
      });
      
      if (!response1.ok) {
        console.error('Discord webhook error (headshot):', await response1.text());
        return false;
      }
      
      // We're already sending the logo with the main message
      
      // Also save the form data and files to the uploads directory if possible
      try {
        // This part would require server-side logic which we would need to implement
        // For now just log that we would save locally
        console.log('Would also save registration data locally if server-side code was available');
      } catch (saveError) {
        console.error('Error saving uploads locally:', saveError);
        // Continue anyway since we already sent to Discord
      }
      
      return true;
    } catch (error) {
      console.error('Error uploading to Discord:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setSubmission({
        status: 'error',
        message: 'Please correct the errors in the form',
      });
      return;
    }
    
    // Set loading state
    setSubmission({
      status: 'info',
      message: 'Submitting your registration...',
    });
    
    try {
      // Actually upload to Discord using the webhook
      const success = await uploadToDiscord(formData);
      
      if (success) {
        setSubmission({
          status: 'success',
          message: 'Sponsorship inquiry submitted successfully! We will contact you soon.',
        });
        
        // Reset form after successful submission
        setFormData({
          name: '',
          organization: '',
          title: '',
          email: '',
          interests: '',
          description: '',
          goals: '',
          linkedin: '',
          websiteUrl: '',
          budget: '',
          timeline: '',
          logo: null,
        });
        setLogoPreview('');
      } else {
        setSubmission({
          status: 'error',
          message: 'There was an error submitting your sponsorship inquiry. Please try again.',
        });
      }
    } catch (error) {
      setSubmission({
        status: 'error',
        message: 'There was an error submitting your sponsorship inquiry. Please try again.',
      });
      console.error('Submission error:', error);
    }
  };



  return (
    <PageContainer>
      <Helmet>
        <title>SPONSOR | FUTUREPERFECT</title>
      </Helmet>
      
      <Navbar pageName="SPONSORSHIP" />
      
      {/* Sponsor Registration Banner */}
      <SectionBannerWrapper sx={{ 
        mt: 0, 
        top: { xs: '0 !important', sm: 0 },
        position: 'relative'
      }}>
        <SectionBanner sx={{
          position: 'relative',
          zIndex: 4
        }} color={pageColors.main}>
          <Container maxWidth="lg">
            <SectionBannerText variant="h2">
              SPONSORSHIP OPPORTUNITIES
            </SectionBannerText>
          </Container>
        </SectionBanner>
      </SectionBannerWrapper>
      
      <MainContent maxWidth="md">
        <ContentWrapper>
          <FormContainer elevation={3}>
            <FormTitle variant="h2">Partner with Futureperfect</FormTitle>
            
            <Typography variant="body1" color="white" sx={{ mb: 2, textAlign: 'center' }}>
              Complete this form to explore sponsorship opportunities with Futureperfect.
            </Typography>
            
            <Typography variant="body1" color="white" sx={{ mb: 4, textAlign: 'center' }}>
              We offer various partnership levels to connect your brand with our community of thought leaders, innovators, and creators in design, technology, and sustainability.
            </Typography>
            
            <LogoContainer>
              <LogoImage 
                src="/img/logos/fp_icon.jpg" 
                alt="Futureperfect" 
              />
            </LogoContainer>
            
            {/* Response message moved to bottom */}
            
            <form onSubmit={handleSubmit}>
              <FormSection>
                <FormSubtitle variant="h5">Contact Information</FormSubtitle>
                <FormField
                  name="name"
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.name}
                  onChange={handleInputChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  placeholder="John Smith"
                />
                
                <FormField
                  name="organization"
                  label="Organization"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.organization}
                  onChange={handleInputChange}
                  error={!!formErrors.organization}
                  helperText={formErrors.organization}
                  placeholder="Acme Corporation"
                />

                <FormField
                  name="title"
                  label="Title/Position"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.title || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.title}
                  helperText={formErrors.title}
                  placeholder="Director of Marketing"
                />
                
                <FormField
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.email || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  placeholder="you@example.com"
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Sponsorship Information</FormSubtitle>
                
                <FormField
                  name="interests"
                  label="Areas of Interest"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={3}
                  value={formData.interests || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.interests}
                  helperText={formErrors.interests}
                  placeholder="Which specific Futureperfect initiatives are you interested in supporting? (e.g., Events, Festivals, Talks, etc.)"
                />

                <FormField
                  name="description"
                  label="Organization Description"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  value={formData.description || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.description}
                  helperText={formErrors.description}
                  placeholder="Brief description of your organization and its focus areas"
                />

                <FormField
                  name="goals"
                  label="Sponsorship Goals"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={3}
                  value={formData.goals || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.goals}
                  helperText={formErrors.goals}
                  placeholder="What are your goals and expectations for this partnership?"
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Sponsorship Details</FormSubtitle>
                
                <FormField
                  name="budget"
                  label="Budget Range (Optional)"
                  variant="outlined"
                  fullWidth
                  value={formData.budget || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.budget}
                  helperText={formErrors.budget}
                  placeholder="e.g., $5,000-$10,000"
                />
                
                <FormField
                  name="timeline"
                  label="Timeline (Optional)"
                  variant="outlined"
                  fullWidth
                  value={formData.timeline || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.timeline}
                  helperText={formErrors.timeline}
                  placeholder="e.g., Q2 2025, or Summer 2025"
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Online Presence</FormSubtitle>
                <FormField
                  name="linkedin"
                  label="LinkedIn Company Page"
                  variant="outlined"
                  fullWidth
                  value={formData.linkedin || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.linkedin}
                  helperText={formErrors.linkedin}
                  placeholder="https://linkedin.com/company/yourcompany"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                
                <FormField
                  name="websiteUrl"
                  label="Organization Website"
                  variant="outlined"
                  fullWidth
                  value={formData.websiteUrl || ''}
                  onChange={handleInputChange}
                  error={!!formErrors.websiteUrl}
                  helperText={formErrors.websiteUrl}
                  placeholder="https://example.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Company Logo</FormSubtitle>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" color="white" sx={{ mb: 1 }}>
                      Organization Logo
                    </Typography>
                    
                    {logoPreview ? (
                      <FilePreview>
                        <Box 
                          component="img"
                          src={logoPreview}
                          alt="Logo preview"
                          sx={{ 
                            width: 60, 
                            height: 60, 
                            mr: 2,
                            objectFit: 'contain',
                            backgroundColor: 'white',
                            padding: '5px',
                            borderRadius: '4px'
                          }}
                        />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" color="white">
                            {formData.logo?.name}
                          </Typography>
                          <Typography variant="caption" color="white" sx={{ opacity: 0.7 }}>
                            {Math.round(formData.logo?.size / 1024)} KB
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={() => removeFile('logo')}
                          sx={{ color: 'white' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </FilePreview>
                    ) : (
                      <UploadButton
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                        fullWidth
                      >
                        Upload Logo
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/jpeg,image/png,image/webp"
                          onChange={(e) => handleFileChange(e, 'logo')}
                        />
                      </UploadButton>
                    )}
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                      Your organization's logo.
                      JPG, PNG or WebP, max 5MB.
                    </Typography>
                  </Grid>
                </Grid>
              </FormSection>
              
              <Divider sx={{ my: 4, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
              
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit Sponsorship Inquiry
                </SubmitButton>
                
                {submission.status && (
                  <Alert 
                    severity={submission.status} 
                    sx={{ 
                      flexGrow: 1,
                      maxWidth: '400px'
                    }}
                    onClose={() => setSubmission({ status: null, message: '' })}
                  >
                    {submission.message}
                  </Alert>
                )}
              </Box>
              
              <Typography variant="caption" color="#FFFFFF" align="center" display="block" sx={{ mt: 2 }}>
                By submitting this form, you agree to our{' '}
                <Link href="#" color="#FFFFFF" underline="hover">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link href="#" color="#FFFFFF" underline="hover">
                  Terms of Service
                </Link>
              </Typography>
            </form>
          </FormContainer>
        </ContentWrapper>
      </MainContent>
    </PageContainer>
  );
};

export default Sponsor;