// Primary page colors
export const pageColors = {
  main: '#000033',     // Dark blue for home
  events: '#B4006C',   // Pink for events
  festivals: '#F7D417', // Gold for festivals
  talks: '#01579B',    // Darker blue for talks (was dark blue #000033)
  test: '#8DAE1C'      // Green for test page
};

// Function to create opacity variants
const createOpacityVariant = (hexColor, opacity) => {
  // Convert hex to rgba
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Create opacity variants for each color
export const colorVariants = {
  main: {
    full: pageColors.main,
    medium: createOpacityVariant(pageColors.main, 0.7),
    light: createOpacityVariant(pageColors.main, 0.1)
  },
  events: {
    full: pageColors.events,
    medium: createOpacityVariant(pageColors.events, 0.7),
    light: createOpacityVariant(pageColors.events, 0.1)
  },
  festivals: {
    full: pageColors.festivals,
    medium: createOpacityVariant(pageColors.festivals, 0.7),
    light: createOpacityVariant(pageColors.festivals, 0.1)
  },
  talks: {
    full: pageColors.talks,
    medium: createOpacityVariant(pageColors.talks, 0.7),
    light: createOpacityVariant(pageColors.talks, 0.1)
  },
  test: {
    full: pageColors.test,
    medium: createOpacityVariant(pageColors.test, 0.7),
    light: createOpacityVariant(pageColors.test, 0.1),
    dark: createOpacityVariant(pageColors.test, 0.3),
    darker: createOpacityVariant(pageColors.test, 0.1)
  }
};

// Text colors
export const textColors = {
  primary: '#FFFFFF',
  secondary: 'rgba(255, 255, 255, 0.8)',
  accent: 'rgba(255, 255, 255, 0.6)'
};

// Background colors
export const backgroundColors = {
  default: '#000000',
  card: 'rgba(0, 0, 0, 0.7)',
  overlay: 'rgba(0, 0, 0, 0.5)',
  transparent: 'transparent',
  light: 'rgba(255, 255, 255, 0.05)',
  medium: 'rgba(0, 0, 0, 0.3)',
  dark: 'rgba(0, 0, 0, 0.9)'
};

// Utility function to get page-specific colors
export const getPageColors = (pageName) => {
  const pageColor = pageColors[pageName] || pageColors.main;
  return {
    primary: pageColor,
    variants: colorVariants[pageName] || colorVariants.main
  };
};
