import { Box, Container, styled } from '@mui/material';

// Main page wrapper that handles full-height layout
export const PageWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  position: 'relative',
  overflow: 'hidden'
}));

// Standard section with consistent spacing and optional background
export const PageSection = styled(Box)(({ 
  theme, 
  backgroundColor,
  fullHeight = false,
  noPadding = false
}) => ({
  width: '100%',
  minHeight: fullHeight ? '100vh' : 'auto',
  backgroundColor: backgroundColor || 'transparent',
  padding: noPadding ? 0 : theme.spacing(8, 0),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

// Container with consistent max-width and padding
export const ContentContainer = styled(Container)(({ theme, narrow }) => ({
  maxWidth: narrow ? '960px' : '1200px',
  padding: theme.spacing(0, 3),
  position: 'relative',
  zIndex: 1
}));

// Banner component for page headers
export const Banner = styled(Box)(({ theme, color }) => ({
  width: '100%',
  minHeight: '50vh',
  backgroundColor: color || theme.palette.primary.main,
  padding: theme.spacing(8, 0),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
}));

// Sub-banner component for secondary headers
export const SubBanner = styled(Box)(({ theme, color }) => ({
  width: '100%',
  minHeight: '30vh',
  backgroundColor: color || theme.palette.primary.main,
  padding: theme.spacing(4, 0),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
}));

// Background image wrapper with overlay option
export const BackgroundImage = styled(Box)(({ 
  src,
  overlay = false,
  overlayColor = 'rgba(0, 0, 0, 0.5)'
}) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&::after': overlay ? {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: overlayColor
  } : undefined
}));

// Grid container for content cards
export const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gap: theme.spacing(4),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr'
  }
}));

// Full-width banner
export const FullWidthBanner = styled(Box)(({ 
  theme, 
  color,
  height = 'auto',
  centered = true 
}) => ({
  width: '100%',
  minHeight: height,
  backgroundColor: color || theme.palette.primary.main,
  padding: theme.spacing(4, 0),
  display: 'flex',
  alignItems: 'center',
  justifyContent: centered ? 'center' : 'flex-start',
  position: 'relative'
}));

// Section divider
export const Divider = styled(Box)(({ theme, color }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: color || 'rgba(255, 255, 255, 0.1)',
  margin: theme.spacing(4, 0)
}));

// Flex container for flexible layouts
export const FlexBox = styled(Box)(({ 
  theme,
  direction = 'row',
  gap = 2,
  align = 'center',
  justify = 'flex-start'
}) => ({
  display: 'flex',
  flexDirection: direction,
  gap: theme.spacing(gap),
  alignItems: align,
  justifyContent: justify,
  width: '100%'
}));
