import React, { useState } from 'react';
import {
  Box,
  Tabs,
  Typography,
} from '@mui/material';
import ImageGallery from '../ImageGallery';
import StyledTab from './StyledTab';

const TabGallery = ({ items, styling }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 'none', ...styling?.container }}>
      <Box sx={{ 
        width: '90%', 
        maxWidth: 'none',
        margin: '0 auto' // Center the content
      }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, width: '100%', maxWidth: 'none' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="gallery tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              width: '100%',
              maxWidth: 'none',
              '& .MuiTabs-indicator': {
                backgroundColor: styling?.tab?.defaultColor || items[selectedTab]?.color || '#B4006C',
                height: 2
              }
            }}
          >
            {items.map((item, index) => (
              <StyledTab
                key={item.title}
                label={item.title}
                color={item.color}
                styling={styling}
              />
            ))}
          </Tabs>
        </Box>

        {items.map((item, index) => (
          <Box
            key={item.title}
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            sx={{ width: '100%', maxWidth: 'none' }}
          >
            {selectedTab === index && (
              <>
                {item.description && (
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 3,
                      fontFamily: 'Inter, sans-serif',
                      fontSize: {
                        xs: '1rem',    // Match SectionBodyText on mobile
                        sm: '1.1rem',  // Slightly smaller on tablet
                        md: '1.1rem',  // Standard on desktop
                      },
                      lineHeight: {
                        xs: 1.4, // Match SectionBodyText on mobile
                        sm: 1.6,
                        md: 1.8
                      },
                      color: '#333333',
                      width: '100%',
                      maxWidth: 'none',
                      '@media (max-width: 600px) and (orientation: landscape)': {
                        fontSize: '0.9rem', // Match SectionBodyText
                        lineHeight: 1.3,    // Match SectionBodyText
                      }
                    }}
                  >
                    {item.description}
                  </Typography>
                )}
                {item.content ? (
                  item.content
                ) : (
                  <ImageGallery images={item.images} />
                )}
              </>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TabGallery;
