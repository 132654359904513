import { getContent } from '../content';

const content = getContent();

export const introSlides = [
  {
    text: "How much easier would it be ...",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide1.jpg`
  },
  {
    text: "To make the world a better place",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide2.jpg`
  },
  {
    text: "... if only it felt good doing it?",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide3.jpg`
  },
  {
    text: "It's time to reimagine sustainability",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide4.jpg`
  },
  {
    text: "Time to make the global personal",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide5.jpg`
  },
  {
    text: "Time to create and curate",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide6.jpg`
  },
  {
    text: "Time to see beyond the horizon",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide7.jpg`
  },
  {
    text: "Welcome to Futureperfect Festival.",
    image: `${process.env.PUBLIC_URL}/img/carousel/slide8.jpg`
  }
];
