import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { pageColors } from '../components/shared/colors';
import Navbar from '../components/Navbar';
import TabGallery from '../components/TabGallery';
import CategoryTabGallery from '../components/CategoryTabGallery';
import ContentDescriptionBlock from '../components/ContentDescriptionBlock';
import { SectionBanner, SectionBannerText, SectionBannerWrapper, SubSectionBanner, SubSectionBannerText, SubSectionBannerWrapper, ContentCategoryBanner, ContentCategoryBannerText, ContentCategoryBannerWrapper } from '../components/SharedComponents';
import ContentCard from '../components/shared/ContentCard';
import { BodyText } from '../components/shared/Typography';
import SectionBodyText from '../components/SectionBodyText';
import HeroCarousel from '../components/HeroCarousel';

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '85%',
  margin: '0 auto'
}));

// Event highlights data structure
const highlightsData = [
  {
    title: 'FOOD FUTURES FORUM',
    color: '#B4006C',
    description: 'The Food Futures Forum explored sustainable and innovative approaches to food systems.',
    images: [
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490638261_o.jpg', alt: 'Food Futures Forum - Opening' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490643431_o.jpg', alt: 'Food Futures Forum - Presentation' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490646471_o.jpg', alt: 'Food Futures Forum - Workshop' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648015_o.jpg', alt: 'Food Futures Forum - Group Discussion' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648369_o.jpg', alt: 'Food Futures Forum - Interactive Session' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648711_o.jpg', alt: 'Food Futures Forum - Networking' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490649365_o.jpg', alt: 'Food Futures Forum - Exhibition' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490652239_o.jpg', alt: 'Food Futures Forum - Panel Discussion' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490654125_o.jpg', alt: 'Food Futures Forum - Closing Session' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490743061_o.jpg', alt: 'Food Futures Forum - Collaboration' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490745379_o.jpg', alt: 'Food Futures Forum - Keynote' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8491747244_o.jpg', alt: 'Food Futures Forum - Discussion' }
    ]
  },
  {
    title: 'Art + Tech Forum',
    color: '#B4006C',
    description: 'A forum exploring the intersection of art and technology, featuring leading artists and technologists.',
    images: [
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254120497_o.jpg', alt: 'Art + Tech Forum - Opening Session' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254120943_o.jpg', alt: 'Art + Tech Forum - Panel Discussion' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254121283_o.jpg', alt: 'Art + Tech Forum - Interactive Demo' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254121885_o.jpg', alt: 'Art + Tech Forum - Workshop' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122245_o.jpg', alt: 'Art + Tech Forum - Exhibition' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122347_o.jpg', alt: 'Art + Tech Forum - Networking' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122499_o.jpg', alt: 'Art + Tech Forum - Presentation' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122839_o.jpg', alt: 'Art + Tech Forum - Group Discussion' }
    ]
  },
  {
    title: 'ALMEDALEN',
    color: '#B4006C',
    description: 'Exploring sustainability and innovation at Almedalen Week.',
    images: [
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-1_9273575565_o.jpg', alt: 'Almedalen - Opening Session' },
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-2_9273574419_o.jpg', alt: 'Almedalen - Panel Discussion' },
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-3_9276360004_o.jpg', alt: 'Almedalen - Workshop' },
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-4_9276359530_o.jpg', alt: 'Almedalen - Networking' },
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-5_9273572151_o.jpg', alt: 'Almedalen - Presentation' },
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-6_9276357812_o.jpg', alt: 'Almedalen - Group Discussion' },
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-7_9276356612_o.jpg', alt: 'Almedalen - Exhibition' },
      { url: '/img/fp_events/almedalen-2013/fp-almedalen-2013-8_9273568785_o.jpg', alt: 'Almedalen - Closing Session' }
    ]
  },
  {
    title: 'LAUNCH',
    color: '#B4006C',
    description: 'The launch event that started it all.',
    images: [
      { url: '/img/fp_events/launch-2010/29660_427084921473_1969173_n_9297878817_o.jpg', alt: 'Launch event - Scene 1' },
      { url: '/img/fp_events/launch-2010/29660_427084946473_4126664_n_9300662876_o.jpg', alt: 'Launch event - Scene 2' },
      { url: '/img/fp_events/launch-2010/29660_427084971473_6208632_n_9300662848_o.jpg', alt: 'Launch event - Scene 3' },
      { url: '/img/fp_events/launch-2010/29660_427084976473_7469280_n_9297878807_o.jpg', alt: 'Launch event - Scene 4' },
      { url: '/img/fp_events/launch-2010/29660_427084991473_1453976_n_9297879405_o.jpg', alt: 'Launch event - Scene 5' },
      { url: '/img/fp_events/launch-2010/29660_427085211473_586208_n_9300662792_o.jpg', alt: 'Launch event - Scene 6' }
    ]
  }
];

// Last Meter data
const lastMeterGallery = [
  {
    title: 'STHLM TECH',
    color: '#FF3B00',
    description: 'Last Meter® at Stockholm Tech',
    content: (
      <Box sx={{ width: '100%', height: '70vh', overflow: 'hidden' }}>
        <iframe
          src="https://www.lastmeter.info/sthlmtech-livework"
          title="STHLM Tech"
          style={{ width: '100%', height: '100%', border: 'none', overflow: 'hidden' }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          loading="lazy"
        />
      </Box>
    )
  },
  {
    title: 'MORE LAST METER',
    color: '#FF3B00',
    description: 'More Last Meter® content and events',
    content: (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '50vh'
      }}>
        <Typography variant="h6" sx={{ color: '#666' }}>Coming soon...</Typography>
      </Box>
    )
  }
];

// Treasury events
const treasuryOtherEventsGallery = [
  {
    title: 'Vision Dev Camp',
    color: '#31914E',
    description: 'Developer camp for spatial computing.',
    images: [19, 20, 24, 26, 35, 40, 49, 55, 60, 62, 77, 83, 93, 94, 97, 109, 119, 123, 124, 126].map(i => ({
      url: `/img/partner_events/treasury/E01-vision-dev-camp/E01-vision-dev-camp_${String(i).padStart(3, '0')}.jpg`,
      alt: `Vision Dev Camp - Scene ${i}`
    }))
  },
  {
    title: 'AWE',
    color: '#31914E',
    description: 'Augmented World Expo highlights.',
    images: [3, 13, 16, 19, 21, 27, 39, 41, 43, 45, 50, 57, 58, 78, 81, 93, 94, 98, 103, 104].map(i => ({
      url: `/img/partner_events/treasury/E02-awe/E02-awe_${String(i).padStart(3, '0')}.jpg`,
      alt: `AWE - Scene ${i}`
    }))
  },
  {
    title: 'Vision Hack',
    color: '#31914E',
    description: 'Hackathon for spatial computing innovation.',
    images: [3, 5, 6, 10, 12, 13, 14, 15, 18, 21, 22, 23, 25, 27, 29, 30, 32, 35, 38, 40].map(i => ({
      url: `/img/partner_events/treasury/E02-vision-hack/E02-vision-hack_${String(i).padStart(3, '0')}.jpg`,
      alt: `Vision Hack - Scene ${i}`
    }))
  }
];

const treasurySpecialEventsGallery = [
  {
    title: 'Digital Wellbeing',
    color: '#31914E',
    description: 'Special session on digital wellness.',
    images: [7, 13, 15, 25, 26, 32, 36, 37, 40, 41, 46, 48, 52, 58, 69, 86, 91, 92, 94, 109].map(i => ({
      url: `/img/partner_events/treasury/SS1-digital-wellbeing/SS1-digital-wellbeing_${String(i).padStart(3, '0')}.jpg`,
      alt: `Digital Wellbeing - Scene ${i}`
    }))
  },
  {
    title: 'Ana IV',
    color: '#31914E',
    description: 'Special series on spatial computing narratives.',
    images: [1, 4, 13, 19, 21, 25, 28, 33, 35, 36, 42, 43, 48, 50, 52, 53, 54, 56, 58, 60].map(i => ({
      url: `/img/partner_events/treasury/SSS01-ana-IV/SSS01-ana-IV_${String(i).padStart(3, '0')}.jpg`,
      alt: `Ana IV - Scene ${i}`
    }))
  }
];

// Treasury Session Events - Gallery 1 (S01-S04)
const treasurySessionsGallery1 = [
  {
    title: 'Real Estate',
    color: '#31914E',
    description: 'Exploring the future of real estate and spatial computing.',
    images: [1, 2, 8, 9, 13, 14, 16, 19, 26, 30, 36, 37, 42, 45, 47, 53, 57, 63, 71, 72].map(i => ({
      url: `/img/partner_events/treasury/S01-real-estate/S01-real-estate_${String(i).padStart(3, '0')}.jpg`,
      alt: `Real Estate - Scene ${i}`
    }))
  },
  {
    title: 'Branding',
    color: '#31914E',
    description: 'Reimagining brand experiences in the spatial era.',
    images: [6, 7, 8, 12, 15, 17, 18, 20, 21, 22, 27, 32, 33, 37, 45, 47, 48, 49, 51, 52].map(i => ({
      url: `/img/partner_events/treasury/S03-branding/S03-branding_${String(i).padStart(3, '0')}.jpg`,
      alt: `Branding - Scene ${i}`
    }))
  },
  {
    title: 'AI Roots',
    color: '#31914E',
    description: 'Exploring the implications of artificial intelligence.',
    images: [4, 11, 12, 13, 16, 18, 19, 20, 26, 27, 29, 33, 42, 49, 51, 53, 55, 58, 63, 65].map(i => ({
      url: `/img/partner_events/treasury/S04-ai-roots/S04-ai-roots_${String(i).padStart(3, '0')}.jpg`,
      alt: `AI Roots - Scene ${i}`
    }))
  },
  {
    title: 'New Movie Set',
    color: '#31914E',
    description: 'The evolution of film production in spatial computing.',
    images: [1, 2, 5, 7, 10, 12, 15, 16, 20, 22, 24, 25, 29, 30, 32, 33, 34, 35, 36, 39].map(i => ({
      url: `/img/partner_events/treasury/S05-new-movie-set/S05-new-movie-set_${String(i).padStart(3, '0')}.jpg`,
      alt: `New Movie Set - Scene ${i}`
    }))
  },
  {
    title: 'Laws',
    color: '#31914E',
    description: 'Legal frameworks for the spatial computing era.',
    images: [6, 12, 13, 14, 20, 24, 25, 27, 30, 32, 33, 35, 42, 51, 52, 53, 54, 57, 60, 62].map(i => ({
      url: `/img/partner_events/treasury/S06-laws/S06-laws_${String(i).padStart(3, '0')}.jpg`,
      alt: `Laws - Scene ${i}`
    }))
  }
];

const treasurySessionsGallery2 = [
  {
    title: 'Humans Digital',
    color: '#31914E',
    description: 'The human experience in the digital age.',
    images: [7, 11, 13, 16, 17, 23, 25, 27, 29, 30, 33, 36, 38, 46, 51, 61, 64, 68, 80, 81].map(i => ({
      url: `/img/partner_events/treasury/S07-humans-digital/S07-humans-digital_${String(i).padStart(3, '0')}.jpg`,
      alt: `Humans Digital - Scene ${i}`
    }))
  },
  {
    title: 'IP Part 1',
    color: '#31914E',
    description: 'Intellectual property in the digital realm.',
    images: [1, 2, 5, 6, 9, 10, 12, 15, 17, 18, 20, 21, 24, 25, 28, 30, 32, 37, 38, 40].map(i => ({
      url: `/img/partner_events/treasury/S08-ip-1/S08-ip-1_${String(i).padStart(3, '0')}.jpg`,
      alt: `IP Part 1 - Scene ${i}`
    }))
  },
  {
    title: 'Ana II',
    color: '#31914E',
    description: 'Continuing the exploration of spatial narratives.',
    images: [1].map(i => ({
      url: `/img/partner_events/treasury/S10-ana-II/S10-ana-II_${String(i).padStart(3, '0')}.jpg`,
      alt: `Ana II - Scene ${i}`
    }))
  },
  {
    title: 'Immersive Healing',
    color: '#31914E',
    description: 'The intersection of wellness and spatial computing.',
    images: [5, 8, 11, 12, 17, 21, 22, 31, 34, 36, 40, 42, 46, 48, 49, 50, 52, 55, 56, 73].map(i => ({
      url: `/img/partner_events/treasury/S11-immersive-healing/S11-immersive-healing_${String(i).padStart(3, '0')}.jpg`,
      alt: `Immersive Healing - Scene ${i}`
    }))
  }
];

const treasuryDemosGallery1 = [
  {
    title: 'Metaverse',
    color: '#31914E',
    description: 'Exploring the foundations of spatial computing.',
    images: [8, 10, 19, 20, 22, 23, 28, 35, 38, 39, 45, 46, 52, 59, 63, 67, 70, 72, 78, 80].map(i => ({
      url: `/img/partner_events/treasury/D01-metaverse/D01-metaverse_${String(i).padStart(3, '0')}.jpg`,
      alt: `Metaverse - Scene ${i}`
    }))
  },
  {
    title: 'Vision Launch',
    color: '#31914E',
    description: 'Apple Vision Pro launch implications.',
    images: [1, 2, 3, 4, 8, 10, 12, 13, 14, 16, 17, 20, 21, 24, 27, 32, 33, 35, 36, 39].map(i => ({
      url: `/img/partner_events/treasury/D02-apple-vision-launch/D02-apple-vision-launch_${String(i).padStart(3, '0')}.jpg`,
      alt: `Vision Launch - Scene ${i}`
    }))
  },
  {
    title: 'Open Demo',
    color: '#31914E',
    description: 'Showcasing innovative spatial computing projects.',
    images: [3, 4, 10, 13, 15, 17, 18, 19, 20, 24, 25, 31, 32, 34, 39, 44, 45, 49, 50, 52].map(i => ({
      url: `/img/partner_events/treasury/D04-open-demo/D04-open-demo_${String(i).padStart(3, '0')}.jpg`,
      alt: `Open Demo - Scene ${i}`
    }))
  },
  {
    title: 'GDC Open Demo',
    color: '#31914E',
    description: 'Game Developers Conference spatial computing showcase.',
    images: [2, 9, 17, 18, 20, 23, 24, 26, 27, 28, 29, 36, 46, 49, 55, 56, 58, 61, 70, 74].map(i => ({
      url: `/img/partner_events/treasury/D05-gdc-open-demo/D05-gdc-open-demo_${String(i).padStart(3, '0')}.jpg`,
      alt: `GDC Open Demo - Scene ${i}`
    }))
  }
];

const treasuryDemosGallery2 = [
  {
    title: 'Open Demo II',
    color: '#31914E',
    description: 'Continued exploration of spatial computing innovations.',
    images: [1, 3, 5, 6, 7, 10, 11, 13, 15, 16, 17, 20, 28, 30, 31, 34, 35, 37, 43, 45].map(i => ({
      url: `/img/partner_events/treasury/D06-open-demo/D06-open-demo_${String(i).padStart(3, '0')}.jpg`,
      alt: `Open Demo II - Scene ${i}`
    }))
  },
  {
    title: 'Open Demo III',
    color: '#31914E',
    description: 'Latest developments in spatial computing.',
    images: Array.from({length: 19}, (_, i) => i + 1).map(i => ({
      url: `/img/partner_events/treasury/D07-open-demo/D07-open-demo_${String(i).padStart(3, '0')}.jpg`,
      alt: `Open Demo III - Scene ${i}`
    }))
  }
];


// Events styling configuration
const eventsTabStyling = {
  tab: {
    fontFamily: 'Oswald',
    fontSize: '1.1rem',
    defaultColor: '#B4006C',
    selectedColor: '#800048'
  },
  container: {
    width: '100%'
  }
};

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center'
}));

const SubsectionIntro = styled(Box)(({ theme }) => ({
  minHeight: '50vh',
  display: 'flex',
  alignItems: 'center',
  width: '100%'
}));

const Events = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>FUTUREPERFECT EVENTS</title>
      </Helmet>
      <Box>
        <Navbar />
        <Box sx={{
          position: 'relative',
          height: 'calc(100vh - 91px)',
          minHeight: 'calc(100vh - 91px)',
          overflow: 'hidden'
        }}>
          <HeroCarousel 
            images={[{ 
              url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490638261_o.jpg',
              alt: 'Futureperfect Events Hero'
            }]}
            strapline="Connections for the Worlds We Make"
            color={pageColors.events}
          />
        </Box>
        
        {/* Holding section for future removal */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color="#B4006C">
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                WHAT ARE FUTUREPERFECT EVENTS?
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Box id="what" sx={{ 
          minHeight: 'calc(100vh - 91px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Container maxWidth="lg" sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            py: { xs: 8, sm: 6, md: 4 } // Add padding to prevent pushing against banners
          }}>
            <Box sx={{ 
              maxWidth: '90%', 
              mx: 'auto', 
              mb: { xs: 4, sm: 6, md: 8 },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%'
            }}>
              <SectionBodyText sx={{
                textAlign: { xs: 'center', md: 'left' } // Center text on mobile
              }}>
              Futureperfect Events are connections around themes relating to built design, technology, and sustainability related issues, led by world experts and worldclass creators and curators.
              <br/>
              <br/>
              Everyone working in these fields - as well as students, enthusiasts, campaigners, educators, policy-makers and more - are welcome to participate.
            </SectionBodyText>
            </Box>

            <Grid container spacing={4} sx={{ 
              mt: { xs: 2, sm: 4, md: 8 },
              mb: { xs: 4, sm: 6, md: 8 } // Add bottom margin to prevent pushing against next section
            }}>
              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="PRO DIALOGUES"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  Interactive dialogues with world class experts, with a FutureLab of best practice and collaborative solution
                </ContentCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="FORUMS"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  High-powered presentations and exchanges with global thought and practice leaders across society and enterprise
                </ContentCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="OPEN DIALOGUES"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  Interactive seminars led by inspiring individuals and organisations – for everyone interested to connect and explore
                </ContentCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="CO-CREATION"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  Dynamic collaboration and discussion space - open to all - led by professional facilitators and thought leaders
                </ContentCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="LIVING WELL"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  Food from world-class chefs, nature and adventure, live music and more
                </ContentCard>
              </Grid>

              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="MUSIC & ARTS"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  Full music program - as well as art, film, photography
                </ContentCard>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box id="futureperfect-events">
          {/* Futureperfect Events Section */}
          <SectionBannerWrapper>
            <SectionBanner sx={{
              position: 'sticky',
              top: 0,
              zIndex: 4
            }} color="#B4006C">
              <Container maxWidth="lg">
                <SectionBannerText variant="h2">
                  FUTUREPERFECT EVENTS
                </SectionBannerText>
              </Container>
            </SectionBanner>
          </SectionBannerWrapper>

          <Box sx={{ 
            minHeight: '50vh',
            backgroundColor: 'rgba(180, 0, 108, 0.1)',
            display: 'flex',
            alignItems: 'center', 
            py: { xs: 6, sm: 6, md: 4 } // Add vertical padding to prevent pushing against banner
          }}>
            <ContentWrapper>
              <SectionBodyText sx={{ 
                color: '#333333',
                textAlign: { xs: 'center', md: 'left' } // Center text on mobile
              }}>
                Events hosted and curated by Futureperfect have included Forums on Food,  Technology, Art, and Urban Futures Forums in Copenhagen and Pittsburgh.
                <br/>
                <br/>
                Dialogues and Seminars have been held across the Nordic region, US and UK, and at Almedalen.
              </SectionBodyText>
            </ContentWrapper>
          </Box>

          <TabGallery 
            items={highlightsData}
            styling={{ 
              tab: {
                fontFamily: 'NeusaBlack, sans-serif',
                fontSize: '1.5rem',
                fontWeight: 700,
                letterSpacing: '0.03em',
                textTransform: 'uppercase',
                defaultColor: '#B4006C',
                hoverColor: '#B4006C',
                selectedColor: '#B4006C'
              },
              container: {
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }
            }}
          />
        </Box>

        {/* Partner Events Section */}
        <Box id="partner-events" sx={{ }}>
          <SectionBannerWrapper>
            <SectionBanner sx={{
              position: 'sticky',
              top: 0,
              zIndex: 4
            }} color="#B4006C">
              <Container maxWidth="lg">
                <SectionBannerText variant="h2">
                  PARTNER EVENTS
                </SectionBannerText>
              </Container>
            </SectionBanner>
          </SectionBannerWrapper>

          <Box sx={{ 
            minHeight: '50vh',
            backgroundColor: 'rgba(180, 0, 108, 0.1)',
            display: 'flex',
            alignItems: 'center',
            py: { xs: 6, sm: 6, md: 4 } // Add vertical padding to prevent pushing against banner
          }}>
            <ContentWrapper>
              <SectionBodyText sx={{ 
                textAlign: { xs: 'center', md: 'left' } // Center text on mobile
              }}>
                Futureperfect hosts, develops, curates and promotes events by partners including BASE2 partners, Treasury and Last Meter®.
              </SectionBodyText>
            </ContentWrapper>
          </Box>

          <SubSectionBannerWrapper>
            <SubSectionBanner sx={{ backgroundColor: '#31914E' }}>
              <Container maxWidth="xl" sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}>
                <Box component="img" 
                  src="/img/logos/T LOGO_WHITE.png"
                  alt="Treasury"
                  sx={{ 
                    height: '40px',
                    width: 'auto',
                    my: 1
                  }}
                />
              </Container>
            </SubSectionBanner>
          </SubSectionBannerWrapper>

          {/* Treasury introduction block */}
          <SubsectionIntro sx={{ 
            backgroundColor: 'rgba(49, 145, 78, 0.9)',
            py: { xs: 6, sm: 6, md: 4 } // Add vertical padding to prevent pushing against banner
          }}>
            <Container maxWidth="lg">
              <SectionBodyText variant="white" sx={{ 
                textAlign: { xs: 'center', md: 'left' } // Center text on mobile
              }}>
                Treasury licenses and distributes the world's most valuable digital spatial assets. Jointly founded by BASE2, Zaha Hadid Architects, and Spaceform (backed by Bjarke Ingels Group, UN Studio and more), Treasury is based in San Francisco.
                <br/>
                <br/>
                Treasury has developed a worldclass series of conversations on spatial technology - the Sessions and Demos and related events.
              </SectionBodyText>
            </Container>
          </SubsectionIntro>

          {/* Treasury Sessions Galleries */}
          <Box>
            <ContentCategoryBannerWrapper>
              <ContentCategoryBanner sx={{ backgroundColor: '#31914E' }}>
                <Container maxWidth="xl">
                  <ContentCategoryBannerText>
                    TREASURY SESSIONS I
                  </ContentCategoryBannerText>
                </Container>
              </ContentCategoryBanner>
            </ContentCategoryBannerWrapper>
            <Box sx={{
              '& .MuiTabs-root': {
                position: 'sticky',
                top: '128px',
                zIndex: 2
              }
            }}>
              <Box sx={{
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }}>
                <TabGallery 
                  items={treasurySessionsGallery1} 
                  styling={{ 
                    tab: {
                      fontFamily: 'NeusaBlack, sans-serif',
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      letterSpacing: '0.03em',
                      textTransform: 'uppercase',
                      defaultColor: '#31914E',
                      hoverColor: '#31914E',
                      selectedColor: '#31914E'
                    },
                    container: {
                      width: '100%',
                      maxWidth: 'none',
                      px: 0,
                      mb: 4
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ }}>
            <ContentCategoryBannerWrapper>
              <ContentCategoryBanner sx={{ backgroundColor: '#31914E' }}>
                <Container maxWidth="xl">
                  <ContentCategoryBannerText>
                    TREASURY SESSIONS II
                  </ContentCategoryBannerText>
                </Container>
              </ContentCategoryBanner>
            </ContentCategoryBannerWrapper>
            <Box sx={{
              '& .MuiTabs-root': {
                position: 'sticky',
                top: '128px',
                zIndex: 2
              }
            }}>
              <Box sx={{
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }}>
                <TabGallery 
                  items={treasurySessionsGallery2} 
                  styling={{ 
                    tab: {
                      fontFamily: 'NeusaBlack, sans-serif',
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      letterSpacing: '0.03em',
                      textTransform: 'uppercase',
                      defaultColor: '#31914E',
                      hoverColor: '#31914E',
                      selectedColor: '#31914E'
                    },
                    container: {
                      width: '100%',
                      maxWidth: 'none',
                      px: 0,
                      mb: 4
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* Treasury Demos Galleries */}
          <Box sx={{ }}>
            <ContentCategoryBannerWrapper>
              <ContentCategoryBanner sx={{ backgroundColor: '#31914E' }}>
                <Container maxWidth="xl">
                  <ContentCategoryBannerText>
                    TREASURY DEMOS I
                  </ContentCategoryBannerText>
                </Container>
              </ContentCategoryBanner>
            </ContentCategoryBannerWrapper>
            <Box sx={{
              '& .MuiTabs-root': {
                position: 'sticky',
                top: '128px',
                zIndex: 2
              }
            }}>
              <Box sx={{
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }}>
                <TabGallery 
                  items={treasuryDemosGallery1} 
                  styling={{ 
                    tab: {
                      fontFamily: 'NeusaBlack, sans-serif',
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      letterSpacing: '0.03em',
                      textTransform: 'uppercase',
                      defaultColor: '#31914E',
                      hoverColor: '#31914E',
                      selectedColor: '#31914E'
                    },
                    container: {
                      width: '100%',
                      maxWidth: 'none',
                      px: 0,
                      mb: 4
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ }}>
            <ContentCategoryBannerWrapper>
              <ContentCategoryBanner sx={{ backgroundColor: '#31914E' }}>
                <Container maxWidth="xl">
                  <ContentCategoryBannerText>
                    TREASURY DEMOS II
                  </ContentCategoryBannerText>
                </Container>
              </ContentCategoryBanner>
            </ContentCategoryBannerWrapper>
            <Box sx={{
              '& .MuiTabs-root': {
                position: 'sticky',
                top: '128px',
                zIndex: 2
              }
            }}>
              <Box sx={{
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }}>
                <TabGallery 
                  items={treasuryDemosGallery2} 
                  styling={{ 
                    tab: {
                      fontFamily: 'NeusaBlack, sans-serif',
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      letterSpacing: '0.03em',
                      textTransform: 'uppercase',
                      defaultColor: '#31914E',
                      hoverColor: '#31914E',
                      selectedColor: '#31914E'
                    },
                    container: {
                      width: '100%',
                      maxWidth: 'none',
                      px: 0,
                      mb: 4
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* Treasury Special Events Gallery */}
          <Box sx={{ }}>
            <ContentCategoryBannerWrapper>
              <ContentCategoryBanner sx={{ backgroundColor: '#31914E' }}>
                <Container maxWidth="xl">
                  <ContentCategoryBannerText>
                    TREASURY SPECIAL EVENTS
                  </ContentCategoryBannerText>
                </Container>
              </ContentCategoryBanner>
            </ContentCategoryBannerWrapper>
            <Box sx={{
              '& .MuiTabs-root': {
                position: 'sticky',
                top: '128px',
                zIndex: 2
              }
            }}>
              <Box sx={{
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }}>
                <TabGallery 
                  items={treasurySpecialEventsGallery} 
                  styling={{ 
                    tab: {
                      fontFamily: 'NeusaBlack, sans-serif',
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      letterSpacing: '0.03em',
                      textTransform: 'uppercase',
                      defaultColor: '#31914E',
                      hoverColor: '#31914E',
                      selectedColor: '#31914E'
                    },
                    container: {
                      width: '100%',
                      maxWidth: 'none',
                      px: 0,
                      mb: 4
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* Treasury Other Events Gallery */}
          <Box sx={{ }}>
            <ContentCategoryBannerWrapper>
              <ContentCategoryBanner sx={{ backgroundColor: '#31914E' }}>
                <Container maxWidth="xl">
                  <ContentCategoryBannerText>
                    OTHER EVENTS
                  </ContentCategoryBannerText>
                </Container>
              </ContentCategoryBanner>
            </ContentCategoryBannerWrapper>
            <Box sx={{
              '& .MuiTabs-root': {
                position: 'sticky',
                top: '128px',
                zIndex: 2
              }
            }}>
              <Box sx={{
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }}>
                <TabGallery 
                  items={treasuryOtherEventsGallery} 
                  styling={{ 
                    tab: {
                      fontFamily: 'NeusaBlack, sans-serif',
                      fontSize: '1.5rem',
                      fontWeight: 700,
                      letterSpacing: '0.03em',
                      textTransform: 'uppercase',
                      defaultColor: '#31914E',
                      hoverColor: '#31914E',
                      selectedColor: '#31914E'
                    },
                    container: {
                      width: '100%',
                      maxWidth: 'none',
                      px: 0,
                      mb: 4
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 8, mb: 8 }}>
          <SubSectionBannerWrapper>
            <SubSectionBanner sx={{ backgroundColor: '#FF3B00' }}>
              <Container maxWidth="xl" sx={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}>
                <Box component="img" 
                  src="/img/logos/last_meter_logo.png"
                  alt="Last Meter"
                  sx={{ 
                    height: '40px',
                    width: 'auto',
                    my: 1
                  }}
                />
              </Container>
            </SubSectionBanner>
          </SubSectionBannerWrapper>

          {/* Last Meter introduction block */}
          <SubsectionIntro sx={{ 
            backgroundColor: 'rgba(255, 59, 0, 0.9)',
            py: { xs: 6, sm: 6, md: 4 } // Add vertical padding to prevent pushing against banner
          }}>
            <Container maxWidth="lg">
              <SectionBodyText variant="white" sx={{ 
                textAlign: { xs: 'center', md: 'left' } // Center text on mobile
              }}>
                Last Meter® is a service integration platform and design service developed by BASE2 - to enable real estate operators and developers, architects, service providers, logistics companies, and consumers to achieve efficiency and convenience at the 'last meter' beyond the logistics last mile.
                <br/>
                <br/>
                Last Meter® is the stepping stone to large scale circular cities development: enabling true product, waste, and resource take-back at the building-level at city-scale.
              </SectionBodyText>
            </Container>
          </SubsectionIntro>

          <TabGallery 
            items={lastMeterGallery} 
            styling={{ 
              tab: {
                fontFamily: 'NeusaBlack, sans-serif',
                fontSize: '1.5rem',
                fontWeight: 700,
                letterSpacing: '0.03em',
                textTransform: 'uppercase',
                defaultColor: '#FF3B00',
                hoverColor: '#FF3B00',
                selectedColor: '#FF3B00'
              },
              container: {
                width: '100%',
                maxWidth: 'none',
                px: 0,
                mb: 4
              }
            }}
          />

        </Box>

        {/* Participate Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color="#B4006C">
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                PARTICIPATE
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Box id="participate" sx={{ 
          minHeight: 'calc(100vh - 64px)', 
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/carousel/hero_carousel_C.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <ContentWrapper>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SectionBodyText variant="white">
                  Below are the ways to participate in Futureperfect Events.
                  <br/><br/>
                  <a href="mailto:hello@futureperfect.net" style={{ color: 'inherit', textDecoration: 'none' }}>
                    hello@futureperfect.net
                  </a>
                </SectionBodyText>
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="JOIN"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Sign up for updates and invites to events, including dialogues, sessions, forums, dinners and parties.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="https://discord.gg/R2NKjCm" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Join the conversation →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="SPEAK"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Share your expertise and insights as a speaker or moderator at our events.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="/register" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Register as a speaker →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="CREATE"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Collaborate with us to host, perform, display, hack, experience at events.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="/create" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Share your ideas →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="SPONSOR"
                  accentColor={pageColors.events}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Support our mission and gain visibility among creative and technical leaders and emerging contributors.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 20, left: 0, right: 0 }}>
                      <a href="/sponsor" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Explore sponsorship →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Box>
      </Box>
    </>
  );
};

export default Events;
