import { createTheme } from '@mui/material';
import { pageColors, textColors, backgroundColors } from './colors';

// Create the base theme
export const createAppTheme = (pageName = 'main') => createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: pageColors[pageName] || pageColors.main,
    },
    text: {
      primary: textColors.primary,
      secondary: textColors.secondary
    },
    background: {
      default: '#000000',
      paper: backgroundColors.card
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#000000',
          color: '#FFFFFF'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColors.card,
          backdropFilter: 'blur(10px)'
        }
      }
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontFamily: 'NeusaBlack, sans-serif',
      fontWeight: 700,
      fontSize: '3.5rem',
      letterSpacing: '0.02em',
      textTransform: 'uppercase',
      color: '#FFFFFF'
    },
    h2: {
      fontFamily: 'Neusa, sans-serif',
      fontWeight: 700,
      fontSize: '2.5rem',
      letterSpacing: '0.02em',
      textTransform: 'uppercase',
      color: '#FFFFFF'
    },
    h3: {
      fontFamily: 'Oswald, sans-serif',
      fontWeight: 700,
      fontSize: '1.5rem',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
      color: '#FFFFFF'
    },
    h4: {
      fontFamily: 'Oswald, sans-serif',
      fontWeight: 700,
      fontSize: '1.25rem',
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
      color: '#FFFFFF'
    },
    body1: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '1.1rem',
      lineHeight: 1.8,
      color: '#FFFFFF'
    },
    body2: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '0.9rem',
      lineHeight: 1.6,
      color: '#FFFFFF'
    }
  },
  spacing: (factor) => `${8 * factor}px`,
  shape: {
    borderRadius: 4
  }
});

// Add these color definitions to the theme
export const themeColors = {
  events: {
    banner: '#B4006C',
    card: '#8B0034',
    cardHover: '#A30040',
    light: 'rgba(180, 0, 108, 0.1)'
  },
  festivals: {
    banner: '#F4C145',
    card: '#E6A817',
    cardHover: '#F6B827',
    light: 'rgba(244, 193, 69, 0.1)'
  }
};
