import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  Avatar,
  Divider,
  Alert,
  InputAdornment,
  useTheme,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
import { SectionBanner, SectionBannerText, SectionBannerWrapper } from '../components/SharedComponents';
import Navbar from '../components/Navbar';
import { pageColors } from '../components/shared/colors';

const PageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  background: `url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#000',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '85%',
  margin: '0 auto',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    width: '95%',
  }
}));

const MainContent = styled(Container)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(8),
}));

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  borderRadius: theme.spacing(1),
  color: '#FFFFFF',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    padding: theme.spacing(2),
  }
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '2.5rem',
  color: '#FFFFFF',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    marginBottom: theme.spacing(3),
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.8rem',
    marginBottom: theme.spacing(2),
  }
}));

const FormSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.5rem',
  color: '#FFFFFF',
  borderLeft: `4px solid ${pageColors.talks}`,
  paddingLeft: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3rem',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(2),
  }
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputBase-root': {
    color: '#FFFFFF',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: pageColors.talks,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: pageColors.talks,
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

const FilePreview = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(0.5),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: pageColors.talks,
  color: '#FFFFFF',
  padding: theme.spacing(1.5, 4),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: theme.palette.augmentColor({
      color: { main: pageColors.talks }
    }).dark,
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FormSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  }
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '80px',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    height: '60px',
  }
}));



const Register = () => {
  const theme = useTheme();
  
  // Add CSS to hide mobile strapline
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
      div[class*="MobileStraplineBar"] {
        display: none !important;
      }
    `;
    document.head.appendChild(styleEl);
    
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);
  
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    bio: '',
    themes: '',
    linkedin: '',
    websiteUrl: '',
    headshot: null,
    logo: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submission, setSubmission] = useState({ status: null, message: '' });
  const [headshotPreview, setHeadshotPreview] = useState('');
  const [logoPreview, setLogoPreview] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    // Clear error when field is edited
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type and size
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    const maxSize = 5 * 1024 * 1024; // 5MB
    
    if (!validTypes.includes(file.type)) {
      setFormErrors({
        ...formErrors,
        [fileType]: 'Please upload a JPG, PNG, or WebP image',
      });
      return;
    }
    
    if (file.size > maxSize) {
      setFormErrors({
        ...formErrors,
        [fileType]: 'File size must be less than 5MB',
      });
      return;
    }

    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      if (fileType === 'headshot') {
        setHeadshotPreview(reader.result);
      } else {
        setLogoPreview(reader.result);
      }
    };
    reader.readAsDataURL(file);

    // Update form data
    setFormData({
      ...formData,
      [fileType]: file,
    });
    
    // Clear error
    if (formErrors[fileType]) {
      setFormErrors({
        ...formErrors,
        [fileType]: null,
      });
    }
  };

  const removeFile = (fileType) => {
    if (fileType === 'headshot') {
      setHeadshotPreview('');
    } else {
      setLogoPreview('');
    }
    
    setFormData({
      ...formData,
      [fileType]: null,
    });
  };

  const validateForm = () => {
    const errors = {};
    
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    
    if (!formData.organization.trim()) {
      errors.organization = 'Organization is required';
    }
    
    if (!formData.bio.trim()) {
      errors.bio = 'Bio is required';
    } else if (formData.bio.split(/\s+/).length > 50) {
      errors.bio = 'Bio must be 50 words or less';
    }
    
    // LinkedIn URL validation (optional field)
    if (formData.linkedin && !formData.linkedin.match(/^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\w-]+\/?$/)) {
      errors.linkedin = 'Please enter a valid LinkedIn profile URL';
    }
    
    // Website URL validation (optional field)
    if (formData.websiteUrl && !formData.websiteUrl.match(/^(https?:\/\/)?(www\.)?[\w-]+(\.[\w-]+)+\/?.*$/)) {
      errors.websiteUrl = 'Please enter a valid URL';
    }
    
    if (!formData.headshot) {
      errors.headshot = 'Please upload a headshot image';
    }
    
    if (Object.keys(errors).length === 0) {
      return true;
    }
    
    setFormErrors(errors);
    return false;
  };

  // Function to upload files to Discord
  const uploadToDiscord = async (formData) => {
    try {
      // Discord webhook URL - use environment variable if available, fall back to direct URL for local dev
      const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_URL || 'https://discord.com/api/webhooks/1353929283705503815/9nycnXECvP4lH9s38N2r5wMVGK6RYOH_YNYwQBCV_68VNQSwUaAAYnRYaCEg3svV7-60';
      
      // Create detailed message with registration info
      const message = `
**New Speaker Registration**
**Name:** ${formData.name}
**Organization:** ${formData.organization}
**Themes:** ${formData.themes || "N/A"}
**LinkedIn:** ${formData.linkedin || "N/A"}
**Website:** ${formData.websiteUrl || "N/A"}
      `;

      // Send first message with info and headshot
      const firstData = new FormData();
      firstData.append('content', message);
      
      // Add the headshot image
      if (formData.headshot) {
        firstData.append('file', formData.headshot, formData.headshot.name);
      }
      
      // Send to Discord webhook
      const response1 = await fetch(webhookUrl, {
        method: 'POST',
        body: firstData
      });
      
      if (!response1.ok) {
        console.error('Discord webhook error (headshot):', await response1.text());
        return false;
      }
      
      // If we have a logo, send it as a second message
      if (formData.logo) {
        // Create a second FormData for the logo
        const secondData = new FormData();
        secondData.append('content', `**Organization Logo for ${formData.name} from ${formData.organization}**`);
        secondData.append('file', formData.logo, formData.logo.name);
        
        // Send second webhook request
        const response2 = await fetch(webhookUrl, {
          method: 'POST',
          body: secondData
        });
        
        if (!response2.ok) {
          console.error('Discord webhook error (logo):', await response2.text());
          // Continue anyway since we already sent the main info
        }
      }
      
      // Also save the form data and files to the uploads directory if possible
      try {
        // This part would require server-side logic which we would need to implement
        // For now just log that we would save locally
        console.log('Would also save registration data locally if server-side code was available');
      } catch (saveError) {
        console.error('Error saving uploads locally:', saveError);
        // Continue anyway since we already sent to Discord
      }
      
      return true;
    } catch (error) {
      console.error('Error uploading to Discord:', error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setSubmission({
        status: 'error',
        message: 'Please correct the errors in the form',
      });
      return;
    }
    
    // Set loading state
    setSubmission({
      status: 'info',
      message: 'Submitting your registration...',
    });
    
    try {
      // Actually upload to Discord using the webhook
      const success = await uploadToDiscord(formData);
      
      if (success) {
        setSubmission({
          status: 'success',
          message: 'Registration submitted successfully! We will contact you soon.',
        });
        
        // Reset form after successful submission
        setFormData({
          name: '',
          organization: '',
          bio: '',
          themes: '',
          linkedin: '',
          websiteUrl: '',
          headshot: null,
          logo: null,
        });
        setHeadshotPreview('');
        setLogoPreview('');
      } else {
        setSubmission({
          status: 'error',
          message: 'There was an error submitting your registration. Please try again.',
        });
      }
    } catch (error) {
      setSubmission({
        status: 'error',
        message: 'There was an error submitting your registration. Please try again.',
      });
      console.error('Submission error:', error);
    }
  };



  return (
    <PageContainer>
      <Helmet>
        <title>REGISTER | FUTUREPERFECT</title>
      </Helmet>
      
      <Navbar pageName="TALKS" />
      
      {/* Speaker Registration Banner */}
      <SectionBannerWrapper sx={{ 
        mt: 0, 
        top: { xs: '0 !important', sm: 0 },
        position: 'relative'
      }}>
        <SectionBanner sx={{
          position: 'relative',
          zIndex: 4
        }} color={pageColors.talks}>
          <Container maxWidth="lg">
            <SectionBannerText variant="h2">
              SPEAKER REGISTRATION
            </SectionBannerText>
          </Container>
        </SectionBanner>
      </SectionBannerWrapper>
      
      <MainContent maxWidth="md">
        <ContentWrapper>
          <FormContainer elevation={3}>
            <FormTitle variant="h2">Join Our Speaker Community</FormTitle>
            
            <Typography variant="body1" color="white" sx={{ mb: 2, textAlign: 'center' }}>
              Complete this form to register your interest in speaking at Futureperfect Talks and Events.
            </Typography>
            
            <Typography variant="body1" color="white" sx={{ mb: 4, textAlign: 'center' }}>
              We're looking for thought leaders and innovators in design, technology, and sustainability.
            </Typography>
            
            <LogoContainer>
              <LogoImage 
                src="/img/fp_talks_sq_logo.jpg" 
                alt="Futureperfect Talks" 
              />
              <LogoImage 
                src="/img/rv_talks_sq_logo.jpg" 
                alt="Resource Vision Talks" 
              />
            </LogoContainer>
            
            {/* Response message moved to bottom */}
            
            <form onSubmit={handleSubmit}>
              <FormSection>
                <FormSubtitle variant="h5">Personal Information</FormSubtitle>
                <FormField
                  name="name"
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.name}
                  onChange={handleInputChange}
                  error={!!formErrors.name}
                  helperText={formErrors.name}
                  placeholder="John Smith"
                />
                
                <FormField
                  name="organization"
                  label="Organization"
                  variant="outlined"
                  fullWidth
                  required
                  value={formData.organization}
                  onChange={handleInputChange}
                  error={!!formErrors.organization}
                  helperText={formErrors.organization}
                  placeholder="Acme Corporation"
                />
                
                <FormField
                  name="bio"
                  label="Professional Bio (50 words max)"
                  variant="outlined"
                  fullWidth
                  required
                  multiline
                  rows={4}
                  value={formData.bio}
                  onChange={handleInputChange}
                  error={!!formErrors.bio}
                  helperText={formErrors.bio || `${formData.bio.split(/\s+/).length}/50 words`}
                  placeholder="Brief professional bio highlighting your expertise and background"
                />

                <FormField
                  name="themes"
                  label="Themes and Focus"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={formData.themes}
                  onChange={handleInputChange}
                  error={!!formErrors.themes}
                  helperText={formErrors.themes}
                  placeholder="Add themes of interest and focus areas here"
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Online Presence</FormSubtitle>
                <FormField
                  name="linkedin"
                  label="LinkedIn URL"
                  variant="outlined"
                  fullWidth
                  value={formData.linkedin}
                  onChange={handleInputChange}
                  error={!!formErrors.linkedin}
                  helperText={formErrors.linkedin}
                  placeholder="https://linkedin.com/in/yourprofile"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                
                <FormField
                  name="websiteUrl"
                  label="Organization Website"
                  variant="outlined"
                  fullWidth
                  value={formData.websiteUrl}
                  onChange={handleInputChange}
                  error={!!formErrors.websiteUrl}
                  helperText={formErrors.websiteUrl}
                  placeholder="https://example.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormSection>
              
              <FormSection>
                <FormSubtitle variant="h5">Visual Assets</FormSubtitle>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="white" sx={{ mb: 1 }}>
                      Headshot Image
                    </Typography>
                    
                    {headshotPreview ? (
                      <FilePreview>
                        <Avatar 
                          src={headshotPreview} 
                          alt="Headshot preview" 
                          sx={{ width: 60, height: 60, mr: 2 }}
                        />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" color="white">
                            {formData.headshot?.name}
                          </Typography>
                          <Typography variant="caption" color="white" sx={{ opacity: 0.7 }}>
                            {Math.round(formData.headshot?.size / 1024)} KB
                          </Typography>
                        </Box>
                        <IconButton 
                          onClick={() => removeFile('headshot')}
                          sx={{ color: 'white' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </FilePreview>
                    ) : (
                      <>
                        <UploadButton 
                          component="label"
                          variant="outlined" 
                          startIcon={<CloudUploadIcon />}
                          fullWidth
                        >
                          Upload Headshot
                          <VisuallyHiddenInput 
                            type="file"
                            accept="image/jpeg,image/png,image/webp"
                            onChange={(e) => handleFileChange(e, 'headshot')}
                          />
                        </UploadButton>
                        {formErrors.headshot && (
                          <Typography variant="caption" color="error">
                            {formErrors.headshot}
                          </Typography>
                        )}
                      </>
                    )}
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                      Professional headshot, square format recommended.
                      JPG, PNG or WebP, max 5MB.
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1" color="white" sx={{ mb: 1 }}>
                      Organization Logo (Optional)
                    </Typography>
                    
                    {logoPreview ? (
                      <FilePreview>
                        <Box 
                          component="img"
                          src={logoPreview}
                          alt="Logo preview"
                          sx={{ 
                            width: 60, 
                            height: 60, 
                            mr: 2,
                            objectFit: 'contain',
                            backgroundColor: 'white',
                            padding: '5px',
                            borderRadius: '4px'
                          }}
                        />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" color="white">
                            {formData.logo?.name}
                          </Typography>
                          <Typography variant="caption" color="white" sx={{ opacity: 0.7 }}>
                            {Math.round(formData.logo?.size / 1024)} KB
                          </Typography>
                        </Box>
                        <IconButton
                          onClick={() => removeFile('logo')}
                          sx={{ color: 'white' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </FilePreview>
                    ) : (
                      <UploadButton
                        component="label"
                        variant="outlined"
                        startIcon={<CloudUploadIcon />}
                        fullWidth
                      >
                        Upload Logo
                        <VisuallyHiddenInput
                          type="file"
                          accept="image/jpeg,image/png,image/webp"
                          onChange={(e) => handleFileChange(e, 'logo')}
                        />
                      </UploadButton>
                    )}
                    <Typography variant="caption" color="rgba(255, 255, 255, 0.7)">
                      Your organization's logo.
                      JPG, PNG or WebP, max 5MB.
                    </Typography>
                  </Grid>
                </Grid>
              </FormSection>
              
              <Divider sx={{ my: 4, backgroundColor: 'rgba(255, 255, 255, 0.1)' }} />
              
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit Registration
                </SubmitButton>
                
                {submission.status && (
                  <Alert 
                    severity={submission.status} 
                    sx={{ 
                      flexGrow: 1,
                      maxWidth: '400px'
                    }}
                    onClose={() => setSubmission({ status: null, message: '' })}
                  >
                    {submission.message}
                  </Alert>
                )}
              </Box>
              
              <Typography variant="caption" color="#FFFFFF" align="center" display="block" sx={{ mt: 2 }}>
                By submitting this form, you agree to our{' '}
                <Link href="#" color="#FFFFFF" underline="hover">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link href="#" color="#FFFFFF" underline="hover">
                  Terms of Service
                </Link>
              </Typography>
            </form>
          </FormContainer>
        </ContentWrapper>
      </MainContent>
    </PageContainer>
  );
};

export default Register;