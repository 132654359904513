import React from 'react';
import { Helmet } from 'react-helmet';
import { 
  Box, 
  Typography, 
  Container,
  Grid,
  Card,
  CardContent,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Navbar from '../components/Navbar';
import PageHero from '../components/PageHero';
import { SectionBanner, SectionBannerText, SectionBannerWrapper, BodyText, PodcastCard, PodcastBodyText, CategoryContentSection } from '../components/SharedComponents';
import ContentCard from '../components/shared/ContentCard';
import PreviewTabGallery from '../components/PreviewTabGallery';
import HeroCarousel from '../components/HeroCarousel';
import { pageColors } from '../components/shared/colors';
import SectionBodyText from '../components/SectionBodyText';

// Festival highlights data structure
const festivalData = [
  {
    title: '#4',
    color: pageColors.main,
    description: 'The fourth festival on Grinda Island brought together innovators, artists, and thinkers.',
    images: [
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15130684054_o.jpg', alt: 'Festival 2014 - Scene 1' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15131227283_o.jpg', alt: 'Festival 2014 - Scene 2' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15564753999_o.jpg', alt: 'Festival 2014 - Scene 3' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565148659_o.jpg', alt: 'Festival 2014 - Scene 4' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565149169_o.jpg', alt: 'Festival 2014 - Scene 5' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565149369_o.jpg', alt: 'Festival 2014 - Scene 6' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565198588_o.jpg', alt: 'Festival 2014 - Scene 7' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565447577_o.jpg', alt: 'Festival 2014 - Scene 8' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565453947_o.jpg', alt: 'Festival 2014 - Scene 9' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565460707_o.jpg', alt: 'Festival 2014 - Scene 10' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565601908_o.jpg', alt: 'Festival 2014 - Scene 11' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565602248_o.jpg', alt: 'Festival 2014 - Scene 12' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565797720_o.jpg', alt: 'Festival 2014 - Scene 13' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15750621015_o.jpg', alt: 'Festival 2014 - Scene 14' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15752166432_o.jpg', alt: 'Festival 2014 - Scene 15' }
    ]
  },
  {
    title: '#3',
    color: pageColors.main,
    description: 'The third festival expanded our horizons with groundbreaking performances and discussions.',
    images: [
      { url: '/img/festivals/2013/day-1_14279719483_o.jpg', alt: 'Festival 2013 - Day 1' },
      { url: '/img/festivals/2013/day-1-8_14072969557_o.jpg', alt: 'Festival 2013 - Day 1 Scene 8' },
      { url: '/img/festivals/2013/9508834445_8348cd8d99_o.jpg', alt: 'Festival 2013 - Main Stage' },
      { url: '/img/festivals/2013/day-2-10_14236536736_o.jpg', alt: 'Festival 2013 - Day 2 Scene 1' },
      { url: '/img/festivals/2013/day-2-11_14073010889_o.jpg', alt: 'Festival 2013 - Day 2 Scene 2' },
      { url: '/img/festivals/2013/day-2-12_14279837883_o.jpg', alt: 'Festival 2013 - Day 2 Scene 3' },
      { url: '/img/festivals/2013/day-2-13_14073027688_o.jpg', alt: 'Festival 2013 - Day 2 Scene 4' },
      { url: '/img/festivals/2013/day-2-14_14236539246_o.jpg', alt: 'Festival 2013 - Day 2 Scene 5' },
      { url: '/img/festivals/2013/day-2-15_14257520152_o.jpg', alt: 'Festival 2013 - Day 2 Scene 6' },
      { url: '/img/festivals/2013/day-2-16_14073014309_o.jpg', alt: 'Festival 2013 - Day 2 Scene 7' },
      { url: '/img/festivals/2013/day-2-17_14236541286_o.jpg', alt: 'Festival 2013 - Day 2 Scene 8' },
      { url: '/img/festivals/2013/day-2-18_14256349471_o.jpg', alt: 'Festival 2013 - Day 2 Scene 9' },
      { url: '/img/festivals/2013/day-2-19_14073056830_o.jpg', alt: 'Festival 2013 - Day 2 Scene 10' },
      { url: '/img/festivals/2013/day-2-20_14073032588_o.jpg', alt: 'Festival 2013 - Day 2 Scene 11' },
      { url: '/img/festivals/2013/day-2-21_14259677055_o.jpg', alt: 'Festival 2013 - Day 2 Scene 12' },
      { url: '/img/festivals/2013/day-2-22_14256352621_o.jpg', alt: 'Festival 2013 - Day 2 Scene 13' },
      { url: '/img/festivals/2013/day-2-23_14073018899_o.jpg', alt: 'Festival 2013 - Day 2 Scene 14' }
    ]
  },
  {
    title: '#2',
    color: pageColors.main,
    description: 'The second festival featured workshops, forums, and artistic collaborations.',
    images: [
      { url: '/img/festivals/2012/activism--rights-forum_7905728568_o.jpg', alt: 'Activism & Rights Forum' },
      { url: '/img/festivals/2012/alisa-may-paul-d-miller-tegan-bukowski_7905725124_o.jpg', alt: 'Alisa May, Paul D Miller & Tegan Bukowski' },
      { url: '/img/festivals/2012/cabins_7905693780_o.jpg', alt: 'Festival Cabins' },
      { url: '/img/festivals/2012/cabins_7905713820_o.jpg', alt: 'Festival Cabins View' },
      { url: '/img/festivals/2012/campfire_7905727722_o.jpg', alt: 'Festival Campfire' },
      { url: '/img/festivals/2012/camping-ngen_7905682480_o.jpg', alt: 'Camping at Ngen' },
      { url: '/img/festivals/2012/check-in_7905703340_o.jpg', alt: 'Check-in Area' },
      { url: '/img/festivals/2012/co-creation-session_7905714328_o.jpg', alt: 'Co-creation Session' },
      { url: '/img/festivals/2012/co-creation-space_7905717410_o.jpg', alt: 'Co-creation Space' },
      { url: '/img/festivals/2012/culture--localisation-forum_7905721738_o.jpg', alt: 'Culture & Localisation Forum' },
      { url: '/img/festivals/2012/day-1-evening_7905679326_o.jpg', alt: 'Day 1 Evening' },
      { url: '/img/festivals/2012/dj-spooky_7905722264_o.jpg', alt: 'DJ Spooky Performance' },
      { url: '/img/festivals/2012/dj-spooky_7905722826_o.jpg', alt: 'DJ Spooky Set' },
      { url: '/img/festivals/2012/dj-spooky_7905724474_o.jpg', alt: 'DJ Spooky Session' },
      { url: '/img/festivals/2012/enterprise-forum_7905675540_o.jpg', alt: 'Enterprise Forum' },
      { url: '/img/festivals/2012/enterprise-seminar_7905710268_o.jpg', alt: 'Enterprise Seminar' },
      { url: '/img/festivals/2012/festival-site_7905701768_o.jpg', alt: 'Festival Site' },
      { url: '/img/festivals/2012/internet-forum_7905710900_o.jpg', alt: 'Internet Forum' }
    ]
  },
  {
    title: '#1',
    color: pageColors.main,
    description: 'Our inaugural festival set the stage for years of innovation and collaboration.',
    images: [
      { url: '/img/festivals/2011/6070606439_c04fc49722_o.jpg', alt: 'Opening Ceremony' },
      { url: '/img/festivals/2011/anna-maria-orru_6071159890_o.jpg', alt: 'Anna Maria Orru' },
      { url: '/img/festivals/2011/asha-ali_6071212886_o.jpg', alt: 'Asha Ali Performance' },
      { url: '/img/festivals/2011/asha-ali_6071214778_o.jpg', alt: 'Asha Ali Set' },
      { url: '/img/festivals/2011/ass--knut-larsson_6070638757_o.jpg', alt: 'Knut Larsson' },
      { url: '/img/festivals/2011/bob-hansson_6070968712_o.jpg', alt: 'Bob Hansson' },
      { url: '/img/festivals/2011/bob-hansson-feat-kalle-beatmeister-bccman_6070411699_o.jpg', alt: 'Bob Hansson & Kalle' },
      { url: '/img/festivals/2011/breakfast_6070608767_o.jpg', alt: 'Festival Breakfast' },
      { url: '/img/festivals/2011/carolyn-steel_6070659103_o.jpg', alt: 'Carolyn Steel' },
      { url: '/img/festivals/2011/carolyn-steel_6071197510_o.jpg', alt: 'Carolyn Steel Presentation' },
      { url: '/img/festivals/2011/cities-forum_6071185178_o.jpg', alt: 'Cities Forum' },
      { url: '/img/festivals/2011/closing-ceremony_6766843573_o.jpg', alt: 'Closing Ceremony' },
      { url: '/img/festivals/2011/copia-doble-systema_6070687697_o.jpg', alt: 'Copia Doble Systema' },
      { url: '/img/festivals/2011/dance_6070554751_o.jpg', alt: 'Festival Dance' },
      { url: '/img/festivals/2011/ed-colville-thomas-ermacora_6070610451_o.jpg', alt: 'Ed Colville & Thomas Ermacora' },
      { url: '/img/festivals/2011/fireplace_6070544263_o.jpg', alt: 'Fireplace Gathering' },
      { url: '/img/festivals/2011/gran-kajfes_6070547473_o.jpg', alt: 'Gran Kajfes' },
      { url: '/img/festivals/2011/jesper-waldersten_6070549605_o.jpg', alt: 'Jesper Waldersten' }
    ]
  }
];

// Events data structure
const eventsData = [
  {
    title: 'FOOD FUTURES FORUM',
    color: pageColors.events,
    description: 'The Food Futures Forum explored sustainable and innovative approaches to food systems.',
    images: [
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648015_o.jpg', alt: 'Food Futures Forum - Group Discussion' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490743061_o.jpg', alt: 'Food Futures Forum - Panel Discussion' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490652239_o.jpg', alt: 'Food Futures Forum - Interactive Session' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490649365_o.jpg', alt: 'Food Futures Forum - Exhibition' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648711_o.jpg', alt: 'Food Futures Forum - Networking' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490646471_o.jpg', alt: 'Food Futures Forum - Workshop' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490654125_o.jpg', alt: 'Food Futures Forum - Closing Session' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490643431_o.jpg', alt: 'Food Futures Forum - Presentation' }
    ]
  },
  {
    title: 'ART + TECH FORUM',
    color: pageColors.events,
    description: 'A forum exploring the intersection of art and technology, featuring leading artists and technologists.',
    images: [
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254120497_o.jpg', alt: 'Art + Tech Forum - Opening Session' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254120943_o.jpg', alt: 'Art + Tech Forum - Panel Discussion' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254121283_o.jpg', alt: 'Art + Tech Forum - Interactive Demo' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254121885_o.jpg', alt: 'Art + Tech Forum - Workshop' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122245_o.jpg', alt: 'Art + Tech Forum - Exhibition' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122347_o.jpg', alt: 'Art + Tech Forum - Networking' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122499_o.jpg', alt: 'Art + Tech Forum - Presentation' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122839_o.jpg', alt: 'Art + Tech Forum - Group Discussion' }
    ]
  },
  {
    title: 'LAUNCH',
    color: pageColors.events,
    description: 'The launch event that started it all.',
    images: [
      { url: '/img/fp_events/launch-2010/29660_427084921473_1969173_n_9297878817_o.jpg', alt: 'Launch event - Scene 1' },
      { url: '/img/fp_events/launch-2010/29660_427084946473_4126664_n_9300662876_o.jpg', alt: 'Launch event - Scene 2' },
      { url: '/img/fp_events/launch-2010/29660_427084971473_6208632_n_9300662848_o.jpg', alt: 'Launch event - Scene 3' },
      { url: '/img/fp_events/launch-2010/29660_427084976473_7469280_n_9297878807_o.jpg', alt: 'Launch event - Scene 4' },
      { url: '/img/fp_events/launch-2010/29660_427084991473_1453976_n_9297879405_o.jpg', alt: 'Launch event - Scene 5' },
      { url: '/img/fp_events/launch-2010/29660_427085211473_586208_n_9300662792_o.jpg', alt: 'Launch event - Scene 6' }
    ]
  }
];

// Hero carousel data structure
const heroCarouselData = [
  {
    title: 'HIGHLIGHTS',
    color: '#FFFFFF',
    images: [
      // Festival images - 20 selected (original 10 + 10 new)
      { url: '/img/festivals/2011/cities-forum_6071185178_o.jpg', alt: 'Cities Forum' },
      { url: '/img/festivals/2011/dance_6070554751_o.jpg', alt: 'Festival Dance' },
      { url: '/img/festivals/2011/closing-ceremony_6766843573_o.jpg', alt: 'Closing Ceremony' },
      { url: '/img/festivals/2011/copia-doble-systema_6070687697_o.jpg', alt: 'Copia Doble Systema' },
      { url: '/img/festivals/2011/fireplace_6070544263_o.jpg', alt: 'Festival Fireplace' },
      { url: '/img/festivals/2012/activism--rights-forum_7905728568_o.jpg', alt: 'Activism & Rights Forum' },
      { url: '/img/festivals/2012/co-creation-session_7905714328_o.jpg', alt: 'Co-creation Session' },
      { url: '/img/festivals/2013/day-1_14279719483_o.jpg', alt: 'Festival Day 1' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15130684054_o.jpg', alt: 'Grinda Festival' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15131227283_o.jpg', alt: 'Grinda Activities' },
      // New festival images added
      { url: '/img/festivals/2011/6070606439_c04fc49722_o.jpg', alt: 'Opening Ceremony' },
      { url: '/img/festivals/2011/asha-ali_6071214778_o.jpg', alt: 'Asha Ali Set' },
      { url: '/img/festivals/2011/breakfast_6070608767_o.jpg', alt: 'Festival Breakfast' },
      { url: '/img/festivals/2011/ed-colville-thomas-ermacora_6070610451_o.jpg', alt: 'Discussion Session' },
      { url: '/img/festivals/2012/camping-ngen_7905682480_o.jpg', alt: 'Camping at Ngen' },
      { url: '/img/festivals/2012/check-in_7905703340_o.jpg', alt: 'Festival Check-in' },
      { url: '/img/festivals/2012/dj-spooky_7905722826_o.jpg', alt: 'DJ Spooky Live' },
      { url: '/img/festivals/2012/festival-site_7905701768_o.jpg', alt: 'Festival Site Overview' },
      { url: '/img/festivals/2013/9508834445_8348cd8d99_o.jpg', alt: 'Festival 2013 Main Stage' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565601908_o.jpg', alt: 'Grinda Festival Evening' },

      // Event images - 10 selected
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254120497_o.jpg', alt: 'Art vs Tech Forum 1' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254121885_o.jpg', alt: 'Art vs Tech Forum 2' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254122347_o.jpg', alt: 'Art vs Tech Forum 3' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490638261_o.jpg', alt: 'Food Futures Forum 1' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490646471_o.jpg', alt: 'Food Futures Forum 2' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648015_o.jpg', alt: 'Food Futures Forum 3' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490654125_o.jpg', alt: 'Food Futures Forum 4' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490652239_o.jpg', alt: 'Food Futures Forum 5' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490743061_o.jpg', alt: 'Food Futures Forum 6' },
      { url: '/img/fp_events/launch-2010/29660_427084921473_1969173_n_9297878817_o.jpg', alt: 'Launch Event 2010' },

      // Talks images - 10 selected
      { url: '/img/festivals/2011/carolyn-steel_6070659103_o.jpg', alt: 'Carolyn Steel Talk' },
      { url: '/img/festivals/2011/cities-forum_6071185178_o.jpg', alt: 'Cities Forum Talk' },
      { url: '/img/festivals/2011/ed-colville-thomas-ermacora_6070610451_o.jpg', alt: 'Ed Colville & Thomas Ermacora' },
      { url: '/img/festivals/2011/john-thackara-his-backpack-john-manoochehri_6070730483_o.jpg', alt: 'John Thackara Talk' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490643431_o.jpg', alt: 'Food Futures Talk 1' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648369_o.jpg', alt: 'Food Futures Talk 2' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490648711_o.jpg', alt: 'Food Futures Talk 3' },
      { url: '/img/fp_events/food-futures/nordic-food-localism-forum_8490649365_o.jpg', alt: 'Food Futures Talk 4' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254120943_o.jpg', alt: 'Art vs Tech Talk 1' },
      { url: '/img/fp_events/art-vs-tech/julbord--forum-2012_8254121283_o.jpg', alt: 'Art vs Tech Talk 2' },

      // Treasury images - 10 selected
      { url: '/img/partner_events/treasury/D01-metaverse/D01-metaverse_019.jpg', alt: 'Metaverse Treasury 1' },
      { url: '/img/partner_events/treasury/D01-metaverse/D01-metaverse_028.jpg', alt: 'Metaverse Treasury 2' },
      { url: '/img/partner_events/treasury/D01-metaverse/D01-metaverse_038.jpg', alt: 'Metaverse Treasury 3' },
      { url: '/img/partner_events/treasury/D01-metaverse/D01-metaverse_052.jpg', alt: 'Metaverse Treasury 4' },
      { url: '/img/partner_events/treasury/D01-metaverse/D01-metaverse_067.jpg', alt: 'Metaverse Treasury 5' },
      { url: '/img/partner_events/treasury/D02-apple-vision-launch/D02-apple-vision-launch_002.jpg', alt: 'Vision Launch Treasury 1' },
      { url: '/img/partner_events/treasury/D02-apple-vision-launch/D02-apple-vision-launch_013.jpg', alt: 'Vision Launch Treasury 2' },
      { url: '/img/partner_events/treasury/D02-apple-vision-launch/D02-apple-vision-launch_024.jpg', alt: 'Vision Launch Treasury 3' },
      { url: '/img/partner_events/treasury/D04-open-demo/D04-open-demo_013.jpg', alt: 'Open Demo Treasury 1' },
      { url: '/img/partner_events/treasury/D04-open-demo/D04-open-demo_019.jpg', alt: 'Open Demo Treasury 2' }
    ]
  }
];

// Styled components
const PageContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  background: `url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#000',
  display: 'flex',
  flexDirection: 'column'
}));

const Section = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    minHeight: 'auto', // Allow sections to expand with content on mobile
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    minHeight: 'auto', // Also for landscape mode
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const TwoPaneSection = styled(Section)(({ theme }) => ({
  '& .MuiGrid-container': {
    justifyContent: 'space-between'
  }
}));

const IntroPane = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100%'
});

const PreviewPane = styled(Grid)({
  // Right pane styling
});

const MainContent = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4)
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '85%',
  margin: '0 auto',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    width: '95%',
  }
}));

const SectionCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: '#FFFFFF',
  height: '100%',
  transition: 'transform 0.3s ease-in-out',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-5px)'
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  }
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2.5),
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2.5),
  paddingBottom: 0,
  '&:last-child': {
    paddingBottom: 0
  },
  '& h3.MuiTypography-root': { // Target for podcast title
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem !important',
      lineHeight: '1.1 !important',
      marginTop: '1rem !important',
    },
    '@media (max-width: 600px) and (orientation: landscape)': {
      fontSize: '1.3rem !important',
      lineHeight: '1 !important',
    }
  },
  '& h4.MuiTypography-root': { // Target for podcast subtitle
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem !important',
      lineHeight: '1.1 !important',
      marginBottom: '1.5rem !important',
    },
    '@media (max-width: 600px) and (orientation: landscape)': {
      fontSize: '1.1rem !important',
      lineHeight: '1 !important',
      marginBottom: '1rem !important',
    }
  }
}));

const PodcastLogo = styled('img')(({ theme }) => ({
  width: '200px',
  height: 'auto',
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
  float: 'left'
}));

const PodcastContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));

const ScrollIndicator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: 'rgba(255, 255, 255, 0.4)',
  fontSize: '0.75rem',
  fontFamily: 'Inter, sans-serif',
  pointerEvents: 'none',
  zIndex: 1
}));

const SpotifyEmbed = styled('iframe')({
  borderRadius: '12px',
  width: '100%',
  height: '160px',
  marginTop: '3px'
});

const EpisodesContainer = styled(Box)(({ theme }) => ({
  height: '400px',
  overflowY: 'auto',
  marginTop: theme.spacing(1),
  position: 'relative',
  '& > iframe': {
    marginBottom: 0
  },
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '4px',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)'
    }
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  textTransform: 'uppercase',
  fontFamily: 'NeusaNextStd-CompactRegular, sans-serif',
  fontSize: '1.5rem',
  marginBottom: theme.spacing(2)
}));

const SectionSubtitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  marginBottom: theme.spacing(3),
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '3rem',
  textAlign: 'left',
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1,
  opacity: 0.6
}));

const HeroText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#FFFFFF',
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '3rem',
  textAlign: 'center',
  letterSpacing: '0.02em',
  lineHeight: 1,
  opacity: 0.6,
  textTransform: 'none'
}));

const MainPage = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>FUTUREPERFECT</title>
        <style>
          {`
            @media (max-width: 600px) {
              .podcast-title {
                font-size: 1.6rem !important;
                line-height: 1.1 !important;
              }
              .podcast-subtitle {
                font-size: 1.4rem !important;
                line-height: 1.1 !important;
              }
            }
            
            @media (max-width: 600px) and (orientation: landscape) {
              .podcast-title {
                font-size: 1.3rem !important;
                line-height: 1 !important;
              }
              .podcast-subtitle {
                font-size: 1.1rem !important;
                line-height: 1 !important;
              }
            }
          `}
        </style>
      </Helmet>
      <PageContainer>
        <Navbar />
        
        {/* Hero Section */}
        <Section sx={{ 
          backgroundColor: '#000000',
          position: 'relative',
          overflow: 'hidden',
          minHeight: 'calc(100vh - 91px)' // Subtract navbar height
        }}>
          <Box sx={{ position: 'relative', width: '100%', height: 'calc(100vh - 91px)' }}>
            <HeroCarousel 
              images={heroCarouselData} 
              strapline="Spaces for the Worlds We Make"
              color="rgba(255, 255, 255, 0.7)"
            />
          </Box>
        </Section>

        {/* WHAT Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color={pageColors.main}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                WHAT
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Section id="what" sx={{ 
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/carousel/hero_carousel_A.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <ContentWrapper>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
              <Grid item xs={12}>
                <SectionBodyText variant="white">
                  Futureperfect creates immersive experiences and meaningful connections at the intersection of built design, spatial technology and modern sustainable living.
                  <br /><br />
                  Through events, talks, and festivals, Futureperfect brings together world-leading creators and innovators to explore how technology and design materialize more sustainable futures.
                  <br /><br />
                  We believe positive experience, real evidence, creative collaboration, and an adventure mindset unlock true understanding and shared progress in how we live, build, shape our world.
                  <br /><br />
                  Founded in Stockholm in 2010, Futureperfect has welcomed thousands of participants and listeners, and hundreds of speakers, contributors and performers. Join in today.
                </SectionBodyText>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>

        {/* EVENTS Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color={pageColors.events}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                EVENTS
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Section id="events" sx={{ 
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/backgrounds/background_small.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: 'auto', // Allow natural height
          minHeight: {
            xs: 'auto',
            sm: 'auto',
            md: '85vh' // Optimal height for desktop to show galleries properly
          },
          paddingTop: { xs: 6, sm: 8, md: 8 }, // Reduced top padding
          paddingBottom: { xs: 10, sm: 12, md: 12 } // Reduced bottom padding
        }}>
          <ContentWrapper>
            <Grid container spacing={{ xs: 2, sm: 3, md: 5 }} alignItems="center">
              <IntroPane item xs={12} md={5.5}>
                <Box sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                  alignItems: 'flex-start'
                }}>
                  <SectionBodyText variant="white">
                    Futureperfect Events are connection points for world-leading creators, thought leaders, and activists and students to imagine and develop the future of design, technology and sustainability together.
                    <br/><br/>
                    <a 
                      href="/events" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{ 
                        color: pageColors.events, 
                        textDecoration: 'none',
                        transition: 'opacity 0.3s ease'
                      }}
                      onMouseEnter={(e) => e.target.style.opacity = '0.7'}
                      onMouseLeave={(e) => e.target.style.opacity = '1'}
                    >
                      Explore more
                    </a>
                  </SectionBodyText>
                </Box>
              </IntroPane>
              <PreviewPane item xs={12} md={6}>
                <Box sx={{ 
                  width: '100%', 
                  maxWidth: 'none',
                  height: '100%', // Use full height
                  display: 'flex', // Use flexbox
                  alignItems: 'center', // Vertical centering
                  '& .MuiImageList-root': {
                    // Target ImageList only on main page
                    '@media (min-width: 960px)': {
                      gap: 24, // Increase gap between images on desktop
                      overflow: 'hidden', // Changed from 'visible' to 'hidden' to prevent overlap on Vercel
                      '& .MuiImageListItem-root': {
                        marginBottom: '12px', // Add more space between images
                      }
                    }
                  },
                  // Target the gallery container box
                  '& > div > div[role="tabpanel"] > div': {
                    '@media (min-width: 960px)': {
                      height: '55vh !important', // Fixed height for main page galleries
                      paddingTop: 2,
                      marginTop: 2
                    }
                  },
                  // Target the entire PreviewTabGallery component
                  '& > div': {
                    '@media (min-width: 960px)': {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }
                  }
                }}>
                  <PreviewTabGallery 
                    items={eventsData}
                    styling={{
                      tab: {
                        fontFamily: 'NeusaBlack, sans-serif',
                        fontSize: '1.5rem', // Updated to match TabGallery size
                        fontWeight: 700,
                        letterSpacing: '0.03em',
                        textTransform: 'uppercase',
                        defaultColor: '#FFFFFF',
                        hoverColor: pageColors.events,
                        selectedColor: pageColors.events
                      }
                    }}
                  />
                </Box>
              </PreviewPane>
            </Grid>
          </ContentWrapper>
        </Section>

        {/* TALKS Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color={pageColors.talks}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                TALKS
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Section id="talks" sx={{ 
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/backgrounds/background_front.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <ContentWrapper>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
              <IntroPane item xs={12} md={5.5}>
                <Box>
                  <SectionBodyText variant="white">
                    Futureperfect Talks are conversations with leading technologists, thinkers, and creators about design and spatial technology to discuss the implications and opportunities - for creativity, society and sustainability - of the most powerful technologies yet invented.
                    <br/><br/>
                    <a 
                      href="/talks" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{ 
                        color: pageColors.talks, 
                        textDecoration: 'none',
                        transition: 'opacity 0.3s ease'
                      }}
                      onMouseEnter={(e) => e.target.style.opacity = '0.7'}
                      onMouseLeave={(e) => e.target.style.opacity = '1'}
                    >
                      Explore more
                    </a>
                  </SectionBodyText>
                </Box>
              </IntroPane>
              
              <PreviewPane item xs={12} md={5.5}>
                <PodcastCard>
                  <StyledCardContent>
                    <Typography variant="h3" 
                      className="podcast-title"
                      sx={{
                        color: '#FFFFFF',
                        textTransform: 'uppercase',
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(0.5),
                        fontFamily: 'NeusaBlack, sans-serif',
                        fontSize: '3.5rem',
                        textAlign: 'left',
                        letterSpacing: '0.02em',
                        lineHeight: 1
                      }}
                    >
                      FUTUREPERFECT TALKS
                    </Typography>
                    <Typography variant="h4"
                      className="podcast-subtitle" 
                      sx={{
                        color: '#FFFFFF',
                        marginBottom: theme.spacing(3),
                        fontFamily: 'NeusaBlack, sans-serif',
                        fontSize: '3rem',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        letterSpacing: '0.02em',
                        lineHeight: 1,
                        opacity: 0.6
                      }}
                    >
                      SEASON 1
                    </Typography>
                    <PodcastBodyText>
                      Season 1 of the Futureperfect Talks are an exploration of design and spatial technology in practice - and how they are starting to overlap and merge, across architecture, game, urbanism and more.
                    </PodcastBodyText>
                    <PodcastBodyText sx={{ color: '#9BA1A6' }}>
                      Sponsored by Epic Games.
                    </PodcastBodyText>
                    <Box sx={{ display: 'flow-root', position: 'relative' }}>
                      <PodcastLogo 
                        src={`${process.env.PUBLIC_URL}/img/logos/pod_logo.jpg`} 
                        alt="Futureperfect Talks" 
                      />
                      <ScrollIndicator>
                        scroll for more episodes
                        <KeyboardArrowDownIcon sx={{ fontSize: '1rem' }} />
                      </ScrollIndicator>
                    </Box>
                    <EpisodesContainer>
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/4u7yaEAURgMtnc6PFVKmkm" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/5mJa7J2ef0T5FO8HbywGoC" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/7vJQ0458bEQIiyLcCG5TJr" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/2ux9cTl8pUfr02BTblTnBE" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/0hftbh0YbckJ3oN2usGfgb" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/09Su6uwcghEFeOxspfGgMP" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                      <SpotifyEmbed 
                        src="https://open.spotify.com/embed/episode/6lqUQkMEcZxicHQ5hZd85c" 
                        height="160" 
                        frameBorder="0" 
                        allowFullScreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                    </EpisodesContainer>
                  </StyledCardContent>
                </PodcastCard>
              </PreviewPane>
            </Grid>
          </ContentWrapper>
        </Section>

        {/* FESTIVAL Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color={pageColors.festivals}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                FESTIVALS
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Section id="festival" sx={{ 
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/festivals/2014/futureperfect-festival-2014---grinda_15130684054_o.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: 'auto', // Allow natural height
          minHeight: {
            xs: 'auto',
            sm: 'auto',
            md: '85vh' // Optimal height for desktop to show galleries properly
          },
          paddingTop: { xs: 6, sm: 8, md: 8 }, // Reduced top padding
          paddingBottom: { xs: 10, sm: 12, md: 12 } // Reduced bottom padding
        }}>
          <ContentWrapper>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} alignItems="center">
              <IntroPane item xs={12} md={5.5}>
                <Box sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column', 
                  justifyContent: 'center',
                  alignItems: 'flex-start'
                }}>
                  <SectionBodyText variant="white">
                    Futureperfect Festivals are experiences for immersing oneself in the themes of built design, sustainability and technology, blending large scale Forums, intimate dialogues, together with food, music and nature immersion.
                    <br/><br/>
                    <a 
                      href="/festivals" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      style={{ 
                        color: pageColors.festivals, 
                        textDecoration: 'none',
                        transition: 'opacity 0.3s ease'
                      }}
                      onMouseEnter={(e) => e.target.style.opacity = '0.7'}
                      onMouseLeave={(e) => e.target.style.opacity = '1'}
                    >
                      Explore more
                    </a>
                  </SectionBodyText>
                </Box>
              </IntroPane>
              <PreviewPane item xs={12} md={6}>
                <Box sx={{ 
                  width: '100%', 
                  maxWidth: 'none',
                  height: '100%', // Use full height
                  display: 'flex', // Use flexbox
                  alignItems: 'center', // Vertical centering
                  '& .MuiImageList-root': {
                    // Target ImageList only on main page
                    '@media (min-width: 960px)': {
                      gap: 24, // Increase gap between images on desktop
                      overflow: 'hidden', // Changed from 'visible' to 'hidden' to prevent overlap on Vercel
                      '& .MuiImageListItem-root': {
                        marginBottom: '12px', // Add more space between images
                      }
                    }
                  },
                  // Target the gallery container box
                  '& > div > div[role="tabpanel"] > div': {
                    '@media (min-width: 960px)': {
                      height: '55vh !important', // Fixed height for main page galleries
                      paddingTop: 2,
                      marginTop: 2
                    }
                  },
                  // Target the entire PreviewTabGallery component
                  '& > div': {
                    '@media (min-width: 960px)': {
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center'
                    }
                  }
                }}>
                  <PreviewTabGallery 
                    items={festivalData}
                    styling={{
                      tab: {
                        fontFamily: 'NeusaBlack, sans-serif',
                        fontSize: '1.5rem', // Updated to match TabGallery size
                        fontWeight: 700,
                        letterSpacing: '0.03em',
                        textTransform: 'uppercase',
                        defaultColor: '#FFFFFF',
                        hoverColor: '#F7D417',
                        selectedColor: '#F7D417'
                      }
                    }}
                  />
                </Box>
              </PreviewPane>
            </Grid>
          </ContentWrapper>
        </Section>

        {/* PARTICIPATE Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
            position: 'sticky',
            top: 0,
            zIndex: 4
          }} color={pageColors.main}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                PARTICIPATE
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>

        <Section id="participate" sx={{ 
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/carousel/hero_carousel_B.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
          <ContentWrapper>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
              <Grid item xs={12}>
                <SectionBodyText variant="white">
                  There are many ways to participate in Futureperfect's mission to connect design, technology, and sustainability.<br/><br/>
                  <a href="mailto:hello@futureperfect.net" style={{ color: 'inherit', textDecoration: 'none' }}>hello@futureperfect.net</a>
                </SectionBodyText>
              </Grid>
            </Grid>

            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} sx={{ mt: { xs: 1, md: 2 } }}>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="JOIN"
                  accentColor={pageColors.main}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Sign up for updates and invites to events, podcasts, and festivals.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="https://discord.gg/R2NKjCm" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Join the conversation →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="SPEAK"
                  accentColor={pageColors.main}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Share your expertise and insights as a speaker and moderator in our talks, events and festivals.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="/register" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Register as a speaker →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="CREATE"
                  accentColor={pageColors.main}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Collaborate with us to develop new ideas, performances, projects, and initiatives.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="/create" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Share your ideas →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="SPONSOR"
                  accentColor={pageColors.main}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Support our mission and gain visibility among a community of innovators and thought leaders.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="/sponsor" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Explore sponsorship →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>
      </PageContainer>
    </>
  );
};

export default MainPage;
