import React from 'react';
import './fonts/fonts.css';  
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Festivals from './pages/Festivals';
import Events from './pages/Events';
import MainPage from './pages/MainPage';
import Talks from './pages/Talks';
import Register from './pages/Register';
import Create from './pages/Create';
import Sponsor from './pages/Sponsor';
import TestContent from './pages/TestContent';
import { createAppTheme } from './components/shared/theme';

// Theme wrapper to handle page-specific theming
const ThemedApp = () => {
  const theme = createAppTheme();

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/events" element={<Events />} />
        <Route path="/festivals" element={<Festivals />} />
        <Route path="/talks" element={<Talks />} />
        <Route path="/register" element={<Register />} />
        <Route path="/talks/register" element={<Register />} /> {/* Keep old path as redirect */}
        <Route path="/create" element={<Create />} />
        <Route path="/sponsor" element={<Sponsor />} />
        <Route path="/test-content" element={<TestContent />} />
      </Routes>
    </ThemeProvider>
  );
};

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <ThemedApp />
    </BrowserRouter>
  );
}

export default App;
