import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CarouselContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  overflow: 'hidden'
}));

const CarouselSlide = styled(Box)(({ active }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: active ? 1 : 0,
  transition: 'opacity 1s ease-in-out',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 1
  }
}));

const Overlay = styled(Box)(({ overlayOpacity = 0.3 }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
  zIndex: 1
}));

const ThumbnailStrip = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  left: '25%',
  right: '25%',
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center', // Center the thumbnails
  overflowX: 'auto',
  overflowY: 'hidden',
  height: '106px',
  padding: '0 10px',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '& > *:not(:last-child)': {
    marginRight: '4px' // Add spacing between thumbnails
  },
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    bottom: 10,
    left: '10%',
    right: '10%',
    height: '66px',
    padding: '0 5px'
  },
  // Mobile landscape - Hide thumbnail strip completely
  '@media (max-width: 800px) and (orientation: landscape)': {
    display: 'none !important'
  }
}));

const Thumbnail = styled(Box)(({ theme, active }) => ({
  width: '180px',
  height: '100px',
  flexShrink: 0,
  cursor: 'pointer',
  position: 'relative',
  margin: '3px 2px',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: '3px solid #fff',
    pointerEvents: 'none'
  },
  transition: 'all 0.3s ease',
  opacity: active ? 1 : 0.7,
  '&:hover': {
    opacity: 1
  },
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    width: '100px', // Smaller width
    height: '60px', // Smaller height
    margin: '3px 2px',
    '&::after': {
      border: '2px solid #fff'
    }
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    width: '80px', // Even smaller for landscape
    height: '40px', // Even smaller for landscape
    '&::after': {
      border: '1px solid #fff'
    }
  }
}));

const HeroText = styled(Typography)(({ theme, textColor }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  width: '80%',
  maxWidth: '1200px',
  textAlign: 'center',
  fontSize: '5.5rem',
  fontFamily: 'NeusaBlack, sans-serif',
  fontWeight: 700,
  letterSpacing: '0.02em',
  padding: 0,
  margin: 0,
  display: 'block',
  color: 'rgba(255, 255, 255, 0.7)', // Consistent white with opacity
  '& span': {
    padding: 0,
    margin: 0,
    lineHeight: 1,
    color: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit'
  },
  // Large screens
  [theme.breakpoints.down('lg')]: {
    fontSize: '5rem'
  },
  // Medium screens
  [theme.breakpoints.down('md')]: {
    fontSize: '4.5rem'
  },
  // Small screens (tablets)
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.8rem',
    width: '90%',
    wordWrap: 'break-word', // Allow text to wrap on mobile
    whiteSpace: 'normal', // Allow text to wrap on mobile
    lineHeight: 1.2
  },
  // Extra small (mobile phones)
  '@media (max-width: 480px)': {
    fontSize: '2.4rem',
    lineHeight: 1.2
  },
  // Very small screens
  '@media (max-width: 375px)': {
    fontSize: '2rem',
    lineHeight: 1.3
  },
  // Mobile landscape mode
  '@media (max-width: 800px) and (orientation: landscape)': {
    fontSize: '2.2rem',
    width: '95%',
    top: '42%', // Move up in landscape mode 
    lineHeight: 1.2
  }
}));

// Styled navigation arrows for mobile landscape
const NavArrow = styled(IconButton)(({ theme, direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 10, // Higher z-index to ensure it's visible
  color: 'rgba(255, 255, 255, 0.8)',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  display: 'none', // Hidden by default
  width: '36px',
  height: '36px',
  // Only show on mobile landscape
  '@media (max-width: 800px) and (orientation: landscape)': {
    display: 'flex',
  },
  ...(direction === 'left' ? {
    left: 16,
  } : {
    right: 16,
  }),
}));

/**
 * HeroCarousel Component
 * @param {Object} props
 * @param {Array} props.images - Array of images or array of objects with images
 * @param {string} props.strapline - Text to display on the carousel
 * @param {string} props.color - Color of the strapline text
 */
const HeroCarousel = ({ images, strapline, color }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  // Process images based on data structure provided
  let carouselImages = [];
  
  if (images) {
    // Check if images is an array of objects with 'images' property (festival data format)
    if (Array.isArray(images) && images[0] && images[0].images) {
      // Festival data format (array of objects with images property)
      // Flatten the first tab's images only for now
      carouselImages = images[0].images;
    } 
    // Check if images is a direct array of image objects
    else if (Array.isArray(images) && images[0] && (images[0].url || images[0].alt)) {
      // Direct array of image objects
      carouselImages = images;
    }
    // Default fallback uses introSlides
    else {
      try {
        // This is for backwards compatibility with the original HeroCarousel implementation
        const { introSlides } = require('../data/introText');
        const totalSlides = 14; // A-H plus 9-14
        
        carouselImages = Array.from({ length: totalSlides }, (_, index) => {
          let imagePath;
          if (index < 8) {
            // A-H images
            imagePath = `/img/carousel/hero_carousel_${String.fromCharCode(65 + index)}.jpg`;
          } else {
            // 9-14 images
            imagePath = `/img/carousel/hero_carousel_${index + 1}.jpg`;
          }
          
          // For indices 0-7, use introSlides text, otherwise empty string
          const text = (index < 8 && introSlides[index]) ? introSlides[index].text.toLowerCase() : '';
          
          return {
            url: imagePath,
            alt: `Slide ${index + 1}`,
            text: text
          };
        });
      } catch (e) {
        console.error('Error loading fallback images:', e);
        carouselImages = [];
      }
    }
  }
  
  const totalSlides = carouselImages.length;
  const showThumbnails = totalSlides > 1; // Only show thumbnails if there's more than one image
  
  useEffect(() => {
    if (totalSlides <= 1) return; // Don't auto-rotate if only one image
    
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, 5000);
    
    return () => clearInterval(timer);
  }, [totalSlides]);

  // Determine if we should display text from the image or use strapline
  const getTextForSlide = (item, index) => {
    // If strapline is provided as prop, use that for all slides
    if (strapline) return strapline;
    // Otherwise check if the image object has text
    return item.text || '';
  };

  // Get theme and check for mobile landscape orientation
  const theme = useTheme();
  const isLandscape = useMediaQuery('(max-width: 800px) and (orientation: landscape)');

  // Navigation functions
  const goToPrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  return (
    <>
      <CarouselContainer>
        {carouselImages.map((item, index) => (
          <CarouselSlide key={index} active={currentSlide === index}>
            <img 
              src={item.url} 
              alt={item.alt || `Slide ${index + 1}`}
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <Overlay />
          </CarouselSlide>
        ))}
      </CarouselContainer>
      
      {/* Navigation arrows - only visible on mobile landscape */}
      {totalSlides > 1 && (
        <>
          <NavArrow
            direction="left"
            onClick={goToPrevSlide}
            aria-label="Previous slide"
            size="large"
          >
            <ChevronLeftIcon fontSize="large" />
          </NavArrow>
          <NavArrow
            direction="right"
            onClick={goToNextSlide}
            aria-label="Next slide"
            size="large"
          >
            <ChevronRightIcon fontSize="large" />
          </NavArrow>
        </>
      )}
      
      {/* Only show thumbnail strip if more than one image */}
      {showThumbnails && (
        <ThumbnailStrip>
          {carouselImages.map((item, index) => (
            <Thumbnail
              key={index}
              onClick={() => setCurrentSlide(index)}
              active={currentSlide === index}
            >
              <img 
                src={item.url} 
                alt={item.alt || `Thumbnail ${index + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Thumbnail>
          ))}
        </ThumbnailStrip>
      )}
      
      {/* Text can come from strapline prop or from current slide's text */}
      {(strapline || carouselImages[currentSlide]?.text) && (
        <HeroText textColor={color}>
          {getTextForSlide(carouselImages[currentSlide], currentSlide)}
        </HeroText>
      )}
    </>
  );
};

export default HeroCarousel;