import React from 'react';
import { Box, styled } from '@mui/material';
import { ContentContainer } from './Layout';

// Grid container with responsive columns
const GridContainer = styled(Box)(({ theme, columns = 3 }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gap: theme.spacing(4),
  width: '100%',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: columns > 2 ? 'repeat(2, 1fr)' : `repeat(${columns}, 1fr)`
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr'
  }
}));

/**
 * CardGrid Component
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.children - Cards to display in the grid
 * @param {number} [props.columns=3] - Number of columns (default: 3)
 * @param {boolean} [props.narrow=false] - Use narrow container width
 * @param {Object} [props.sx] - Additional MUI styles
 */
const CardGrid = ({ 
  children,
  columns = 3,
  narrow = false,
  sx = {}
}) => {
  return (
    <ContentContainer narrow={narrow}>
      <GridContainer columns={columns} sx={sx}>
        {children}
      </GridContainer>
    </ContentContainer>
  );
};

export default CardGrid;
