import { styled, Tab } from '@mui/material';

const StyledTab = styled(Tab)(({ theme, color, styling }) => ({
  fontFamily: styling?.tab?.fontFamily || 'NeusaBlack, sans-serif',
  fontWeight: styling?.tab?.fontWeight || 700,
  fontSize: styling?.tab?.fontSize || '1.5rem',
  letterSpacing: styling?.tab?.letterSpacing || '0.03em',
  textTransform: styling?.tab?.textTransform || 'uppercase',
  minWidth: '200px',
  padding: '12px 32px',
  whiteSpace: 'nowrap',
  backgroundColor: 'transparent',
  color: styling?.tab?.defaultColor ? `${styling.tab.defaultColor}99` : color ? `${color}99` : '#B4006C99',
  transition: 'all 0.3s ease',
  '&:not(:last-child)': {
    marginRight: '2px'
  },
  '&:hover': {
    backgroundColor: 'transparent',
    color: styling?.tab?.hoverColor || color || '#B4006C'
  },
  '&.Mui-selected': {
    backgroundColor: styling?.tab?.defaultColor ? `${styling.tab.defaultColor}10` : color ? `${color}10` : '#B4006C10',
    color: styling?.tab?.selectedColor || color || '#B4006C'
  },
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
    padding: '8px 20px',
    minWidth: '140px'
  },
  // Mobile landscape adjustments
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1rem',
    padding: '6px 16px',
    minWidth: '120px'
  }
}));

export default StyledTab;
