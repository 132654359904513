import React from 'react';
import { Box, Card, styled } from '@mui/material';
import { backgroundColors, colorVariants } from './colors';
import { SectionTitle, SectionSubtitle, BodyText } from './Typography';

// Main card container with hover effect and accent bar
const StyledCard = styled(Card)(({ theme, accentColor }) => {
  // Determine the base color from the accent color
  const isEventsPink = accentColor === '#B4006C';
  const isFestivalsGold = accentColor === '#F4C145';
  const isTalksBlue = accentColor === '#000033';
  
  let backgroundColor;
  if (isEventsPink) {
    backgroundColor = '#8B0034'; // Deeper magenta with red undertones
  } else if (isFestivalsGold) {
    backgroundColor = '#E6A817'; // Rich golden amber
  } else if (isTalksBlue) {
    backgroundColor = '#000044'; // Slightly lighter navy blue
  } else {
    backgroundColor = backgroundColors.card;
  }

  return {
    backgroundColor,
    color: '#FFFFFF',
    height: '100%',
    transition: 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out',
    position: 'relative',
    overflow: 'visible',
    borderRadius: 0,
    '& .MuiTypography-root': { // Ensure all typography is white
      color: '#FFFFFF'
    },
    '&:hover': {
      transform: 'translateY(-5px)',
      backgroundColor: isEventsPink 
        ? '#A30040' // Slightly lighter but still deep red-magenta
        : isFestivalsGold 
          ? '#F6B827' // Brighter golden amber
          : isTalksBlue
            ? '#000055' // Even lighter navy blue for hover
            : backgroundColors.card,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '12px',
      backgroundColor: isTalksBlue ? '#FFFFFF' : (accentColor || theme.palette.primary.main),
      [theme.breakpoints.down('sm')]: {
        height: '8px'
      },
      '@media (max-width: 600px) and (orientation: landscape)': {
        height: '6px'
      }
    }
  };
});

// Content container with consistent padding
const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  overflow: 'visible',
  height: 'auto',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
  },
  // Mobile landscape mode
  '@media (max-width: 600px) and (orientation: landscape)': {
    padding: theme.spacing(1.5),
    gap: theme.spacing(1),
  }
}));

// Optional image container
const ImageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  '& img': {
    width: '100%',
    height: 'auto',
    display: 'block'
  }
}));

// Optional overlay for images
const ImageOverlay = styled(Box)(({ theme, overlayColor }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: overlayColor || 'rgba(0, 0, 0, 0.3)',
  transition: 'opacity 0.3s ease-in-out',
  opacity: 0,
  '&:hover': {
    opacity: 1
  }
}));

/**
 * ContentCard Component
 * 
 * @param {Object} props
 * @param {string} props.title - Main title of the card
 * @param {string} [props.subtitle] - Optional subtitle
 * @param {string|React.ReactNode} props.children - Card content
 * @param {string} [props.accentColor] - Color for the top accent bar
 * @param {string} [props.image] - Optional image URL
 * @param {string} [props.imageOverlay] - Optional overlay color for image
 * @param {Object} [props.sx] - Additional MUI styles
 */
const ContentCard = ({ 
  title,
  subtitle,
  children,
  accentColor,
  image,
  imageOverlay,
  sx = {},
  ...props
}) => {
  return (
    <StyledCard accentColor={accentColor} sx={sx} {...props}>
      {image && (
        <ImageContainer>
          <img src={image} alt={title} />
          {imageOverlay && <ImageOverlay overlayColor={imageOverlay} />}
        </ImageContainer>
      )}
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
          <SectionTitle className="card-title">{title}</SectionTitle>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </Box>
        {typeof children === 'string' ? (
          <BodyText>{children}</BodyText>
        ) : (
          <BodyText>{children}</BodyText>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default ContentCard;
