import React, { useState } from 'react';
import { Box, Container, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ImageGallery from './ImageGallery';

const CategoryHeader = styled(Box)(({ theme, color }) => ({
  width: '100%',
  backgroundColor: color || '#B4006C',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.5rem',
  letterSpacing: '0.03em',
  textAlign: 'center',
  fontWeight: 700,
}));

const StyledTabs = styled(Tabs)(({ theme, tabcolor }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: tabcolor || '#B4006C',
  },
}));

const StyledTab = styled(Tab)(({ theme, tabcolor }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1rem',
  letterSpacing: '0.02em',
  color: '#333333',
  '&.Mui-selected': {
    color: tabcolor || '#B4006C',
  },
}));

const CategoryTabGallery = ({ category, items, styling }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ 
        width: '90%',
        margin: '0 auto' 
      }}>
        <CategoryHeader color={styling?.tabColor}>
          <CategoryTitle variant="h3">
            {category}
          </CategoryTitle>
        </CategoryHeader>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          tabcolor={styling?.tabColor}
        >
          {items.map((item, index) => (
            <StyledTab
              key={index}
              label={item.title}
              tabcolor={styling?.tabColor}
            />
          ))}
        </StyledTabs>
        {items.map((item, index) => (
          <Box
            key={index}
            role="tabpanel"
            hidden={value !== index}
            sx={{ mt: 3 }}
          >
            {value === index && (
              <>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '1.1rem',
                    color: '#333333',
                    mb: 2
                  }}
                >
                  {item.description}
                </Typography>
                <ImageGallery images={item.images} />
              </>
            )}
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default CategoryTabGallery;
