import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ImageGallery from './ImageGallery';

const StyledTabs = styled(Tabs)(({ theme, styling }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: styling?.selectedColor || '#FFFFFF',
    height: 2, // Match TabGallery indicator height
  },
  minHeight: '48px',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
  borderBottom: '1px solid rgba(255, 255, 255, 0.2)', // Add subtle border like TabGallery
  marginBottom: theme.spacing(4), // More spacing below tabs
  marginTop: theme.spacing(2) // Add spacing above tabs
}));

const StyledTab = styled(Tab)(({ theme, styling }) => ({
  fontFamily: styling?.fontFamily || 'NeusaBlack, sans-serif',
  fontSize: styling?.fontSize || '1.5rem', // Match TabGallery size
  letterSpacing: styling?.letterSpacing || '0.03em', // Match TabGallery letterspacing
  textTransform: styling?.textTransform || 'uppercase',
  fontWeight: styling?.fontWeight || 700,
  color: styling?.defaultColor || '#FFFFFF',
  minHeight: '48px',
  padding: '6px 20px', // Increased horizontal padding
  minWidth: '120px', // Added minimum width for consistency
  whiteSpace: 'nowrap',
  '&:hover': {
    color: styling?.hoverColor || '#FFFFFF',
    backgroundColor: 'transparent', // Match TabGallery hover behavior
  },
  '&.Mui-selected': {
    color: styling?.selectedColor || '#FFFFFF',
    backgroundColor: styling?.defaultColor ? `${styling.defaultColor}10` : 'rgba(255, 255, 255, 0.1)', // Similar to TabGallery
  },
  // Mobile adjustments - keep existing responsive behavior for the preview context
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem', // Increased from 0.9rem to be more consistent with TabGallery
    padding: '4px 12px', // Increased horizontal padding
    minWidth: '80px',
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1rem', // Increased from 0.8rem
    padding: '3px 10px', // Increased padding
    minWidth: '60px',
  }
}));

const PreviewTabGallery = ({ items, styling }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        variant="standard"
        scrollButtons={false}
        styling={styling?.tab}
        centered
      >
        {items.map((item, index) => (
          <StyledTab
            key={index}
            label={item.title}
            styling={styling?.tab}
          />
        ))}
      </StyledTabs>
      {items.map((item, index) => (
        <Box
          key={index}
          role="tabpanel"
          hidden={value !== index}
          sx={{ mt: 2 }}
        >
          {value === index && (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: { xs: '0.875rem', sm: '0.9rem', md: '1rem' },
                  color: '#FFFFFF',
                  mb: { xs: 1, md: 2 },
                  textAlign: 'center',
                  '@media (max-width: 600px) and (orientation: landscape)': {
                    fontSize: '0.8rem',
                    mb: 0.5
                  }
                }}
              >
                {item.description}
              </Typography>
              <Box sx={{ 
                minHeight: 'auto', // Allow natural height based on content
                overflow: 'hidden', // Changed from 'visible' to 'hidden' to prevent overflow on Vercel
                display: 'flex', // Use flexbox
                flexDirection: 'column', // Stack children vertically
                justifyContent: 'flex-start', // Align at the top
                height: { md: '60vh' }, // Set fixed height on desktop for proper sizing
                mt: { xs: 2, md: 4 }, // More space below the description text
                pt: { md: 2 }, // Add padding at top to provide space
                position: 'relative', // Establish positioning context
                boxSizing: 'border-box' // Include padding in height calculation
              }}>
                <ImageGallery 
                  images={item.images}
                  spacing={{ xs: 1, sm: 1.5, md: 2 }} // Increased spacing for masonry effect
                />
              </Box>
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default PreviewTabGallery;
