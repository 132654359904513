import React from 'react';
import { Box, styled } from '@mui/material';
import { ContentContainer } from './Layout';

// Full-width section with background options
export const FullWidthSection = styled(Box)(({ 
  theme, 
  backgroundColor,
  backgroundImage,
  overlay = false,
  minHeight = '50vh'
}) => ({
  width: '100%',
  minHeight: minHeight,
  position: 'relative',
  backgroundColor,
  backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&::after': overlay ? {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  } : {}
}));

// Container-width section
export const ContainerSection = styled(ContentContainer)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(4, 0)
}));

// Half-width box (responsive)
export const HalfBox = styled(Box)(({ theme, align = 'left' }) => ({
  width: '50%',
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  textAlign: align
}));

// One-third box (responsive)
export const ThirdBox = styled(Box)(({ theme }) => ({
  width: '33.333%',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

// Two-thirds box (responsive)
export const TwoThirdsBox = styled(Box)(({ theme }) => ({
  width: '66.666%',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

// Feature box with background
export const FeatureBox = styled(Box)(({ 
  theme, 
  backgroundColor = 'rgba(0, 0, 0, 0.7)',
  height = 'auto'
}) => ({
  padding: theme.spacing(4),
  backgroundColor,
  height,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

// Offset box (breaks out of container)
export const OffsetBox = styled(Box)(({ theme, offset = '10vw' }) => ({
  width: `calc(100% + ${offset})`,
  marginLeft: `-${offset}`,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: 0
  }
}));

// Flex row with responsive options
export const FlexRow = styled(Box)(({ 
  theme,
  gap = 2,
  wrap = true,
  justify = 'flex-start',
  align = 'stretch'
}) => ({
  display: 'flex',
  flexWrap: wrap ? 'wrap' : 'nowrap',
  gap: theme.spacing(gap),
  justifyContent: justify,
  alignItems: align
}));

// Grid layouts
export const GridLayout = {
  // 2-column grid
  Half: styled(Box)(({ theme, gap = 4 }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(gap),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    }
  })),

  // 3-column grid
  Thirds: styled(Box)(({ theme, gap = 4 }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: theme.spacing(gap),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    }
  })),

  // 2/3 + 1/3 grid
  TwoThirdsGrid: styled(Box)(({ theme, gap = 4, reverse = false }) => ({
    display: 'grid',
    gridTemplateColumns: reverse ? '1fr 2fr' : '2fr 1fr',
    gap: theme.spacing(gap),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    }
  }))
};

// Content boxes with different styles
export const ContentBox = {
  // Transparent box
  Transparent: styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    backdropFilter: 'blur(10px)'
  })),

  // Solid box with border
  Solid: styled(Box)(({ theme, color = 'primary.main' }) => ({
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette[color].main}`
  })),

  // Gradient box
  Gradient: styled(Box)(({ theme, startColor, endColor }) => ({
    padding: theme.spacing(3),
    background: `linear-gradient(45deg, ${startColor || theme.palette.primary.main}, ${endColor || theme.palette.secondary.main})`
  })),

  // Floating box with shadow
  Float: styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-20px)'
  }))
};

// Media boxes for images and videos
export const MediaBox = {
  // Square image box
  Square: styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  })),

  // Wide image box (16:9)
  Wide: styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  })),

  // Portrait image box (3:4)
  Portrait: styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    paddingTop: '133.33%',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    }
  }))
};

// Layout patterns
export const LayoutPattern = {
  // Zigzag layout
  Zigzag: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    '& > *:nth-of-type(even)': {
      flexDirection: 'row-reverse'
    }
  })),

  // Masonry-style layout
  Masonry: styled(Box)(({ theme, columns = 3 }) => ({
    columnCount: columns,
    columnGap: theme.spacing(3),
    '& > *': {
      breakInside: 'avoid',
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('md')]: {
      columnCount: Math.min(2, columns)
    },
    [theme.breakpoints.down('sm')]: {
      columnCount: 1
    }
  })),

  // Overlapping layout
  Overlap: styled(Box)(({ theme }) => ({
    position: 'relative',
    '& > *:not(:first-child)': {
      marginTop: '-10%',
      marginLeft: '10%',
      position: 'relative',
      zIndex: 1
    }
  }))
};
