import React from 'react';
import { styled } from '@mui/material/styles';
import {
                  Box,
                  Container,
                  Grid,
                  Typography,
                  useTheme,
                  useMediaQuery,
} from '@mui/material';
import { colors } from '../theme';
import Navbar from '../components/Navbar';
import HeroCarousel from '../components/HeroCarousel';
import TabGallery from '../components/TabGallery';
import { 
                  ContentBoxHeading, 
                  ContentBoxHeadingCallout, 
                  SectionBanner, 
                  SectionBannerText,
                  SectionBannerWrapper,
} from '../components/SharedComponents';
import ContentCard from '../components/shared/ContentCard';
import Helmet from 'react-helmet';
import SectionBodyText from '../components/SectionBodyText';

const Section = styled(Box)(({ theme }) => ({
                  minHeight: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
  '& > .MuiContainer-root': {
                  width: '100%'
  }
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
                  width: '85%',
                  margin: '0 auto'
}));

// Festival highlights data structure
const festivalData = [
  {
                  title: '#4',
                  color: '#F7D417',
                  description: 'The fourth festival on Grinda Island brought together innovators, artists, and thinkers.',
                  images: [
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15130684054_o.jpg', alt: 'Festival 2014 - Scene 1' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15131227283_o.jpg', alt: 'Festival 2014 - Scene 2' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15564753999_o.jpg', alt: 'Festival 2014 - Scene 3' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565148659_o.jpg', alt: 'Festival 2014 - Scene 4' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565149169_o.jpg', alt: 'Festival 2014 - Scene 5' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565149369_o.jpg', alt: 'Festival 2014 - Scene 6' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565198588_o.jpg', alt: 'Festival 2014 - Scene 7' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565447577_o.jpg', alt: 'Festival 2014 - Scene 8' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565453947_o.jpg', alt: 'Festival 2014 - Scene 9' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565460707_o.jpg', alt: 'Festival 2014 - Scene 10' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565601908_o.jpg', alt: 'Festival 2014 - Scene 11' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565602248_o.jpg', alt: 'Festival 2014 - Scene 12' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565797720_o.jpg', alt: 'Festival 2014 - Scene 13' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15750621015_o.jpg', alt: 'Festival 2014 - Scene 14' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15752166432_o.jpg', alt: 'Festival 2014 - Scene 15' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15752169502_o.jpg', alt: 'Festival 2014 - Scene 16' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565149189_o.png', alt: 'Festival 2014 - Scene 17' },
      { url: '/img/festivals/2014/futureperfect-festival-2014---grinda_15565149399_o.jpg', alt: 'Festival 2014 - Scene 18' }
    ]
  },
  {
                  title: '#3',
                  color: '#F7D417',
                  description: 'The third festival expanded our horizons with groundbreaking performances and discussions.',
                  images: [
      { url: '/img/festivals/2013/day-1_14279719483_o.jpg', alt: 'Festival 2013 - Day 1' },
      { url: '/img/festivals/2013/day-1-8_14072969557_o.jpg', alt: 'Festival 2013 - Day 1 Scene 8' },
      { url: '/img/festivals/2013/9508834445_8348cd8d99_o.jpg', alt: 'Festival 2013 - Main Stage' },
      { url: '/img/festivals/2013/day-2-10_14236536736_o.jpg', alt: 'Festival 2013 - Day 2 Scene 1' },
      { url: '/img/festivals/2013/day-2-11_14073010889_o.jpg', alt: 'Festival 2013 - Day 2 Scene 2' },
      { url: '/img/festivals/2013/day-2-12_14279837883_o.jpg', alt: 'Festival 2013 - Day 2 Scene 3' },
      { url: '/img/festivals/2013/day-2-13_14073027688_o.jpg', alt: 'Festival 2013 - Day 2 Scene 4' },
      { url: '/img/festivals/2013/day-2-14_14236539246_o.jpg', alt: 'Festival 2013 - Day 2 Scene 5' },
      { url: '/img/festivals/2013/day-2-15_14257520152_o.jpg', alt: 'Festival 2013 - Day 2 Scene 6' },
      { url: '/img/festivals/2013/day-2-16_14073014309_o.jpg', alt: 'Festival 2013 - Day 2 Scene 7' },
      { url: '/img/festivals/2013/day-2-17_14236541286_o.jpg', alt: 'Festival 2013 - Day 2 Scene 8' },
      { url: '/img/festivals/2013/day-2-18_14256349471_o.jpg', alt: 'Festival 2013 - Day 2 Scene 9' },
      { url: '/img/festivals/2013/day-2-19_14073056830_o.jpg', alt: 'Festival 2013 - Day 2 Scene 10' },
      { url: '/img/festivals/2013/day-2-20_14073032588_o.jpg', alt: 'Festival 2013 - Day 2 Scene 11' },
      { url: '/img/festivals/2013/day-2-21_14259677055_o.jpg', alt: 'Festival 2013 - Day 2 Scene 12' },
      { url: '/img/festivals/2013/day-2-22_14256352621_o.jpg', alt: 'Festival 2013 - Day 2 Scene 13' },
      { url: '/img/festivals/2013/day-2-23_14073018899_o.jpg', alt: 'Festival 2013 - Day 2 Scene 14' },
      { url: '/img/festivals/2013/day-2-24_14279846113_o.jpg', alt: 'Festival 2013 - Day 2 Scene 15' },
      { url: '/img/festivals/2013/day-2-25_14256354631_o.jpg', alt: 'Festival 2013 - Day 2 Scene 16' },
      { url: '/img/festivals/2013/day-2-26_14256356311_o.jpg', alt: 'Festival 2013 - Day 2 Scene 17' },
      { url: '/img/festivals/2013/day-2-27_14073100357_o.jpg', alt: 'Festival 2013 - Day 2 Scene 18' },
      { url: '/img/festivals/2013/day-2-28_14256357791_o.jpg', alt: 'Festival 2013 - Day 2 Scene 19' },
      { url: '/img/festivals/2013/day-2-2_14236542956_o.jpg', alt: 'Festival 2013 - Day 2 Scene 20' },
      { url: '/img/festivals/2013/day-2-2_14257400512_o.jpg', alt: 'Festival 2013 - Day 2 Scene 21' },
      { url: '/img/festivals/2013/day-2-3_14259557325_o.jpg', alt: 'Festival 2013 - Day 2 Scene 22' },
      { url: '/img/festivals/2013/day-2-3_14279850933_o.jpg', alt: 'Festival 2013 - Day 2 Scene 23' },
      { url: '/img/festivals/2013/day-2-4_14072974697_o.jpg', alt: 'Festival 2013 - Day 2 Scene 24' },
      { url: '/img/festivals/2013/day-3-1_14256364031_o.jpg', alt: 'Festival 2013 - Day 3 Scene 1' },
      { url: '/img/festivals/2013/day-3-2_14073045768_o.jpg', alt: 'Festival 2013 - Day 3 Scene 2' },
      { url: '/img/festivals/2013/day-3-3_14236558206_o.jpg', alt: 'Festival 2013 - Day 3 Scene 3' }
    ]
  },
  {
                  title: '#2',
                  color: '#F7D417',
                  description: 'The second festival featured workshops, forums, and artistic collaborations.',
                  images: [
      { url: '/img/festivals/2012/activism--rights-forum_7905728568_o.jpg', alt: 'Activism & Rights Forum' },
      { url: '/img/festivals/2012/alisa-may-paul-d-miller-tegan-bukowski_7905725124_o.jpg', alt: 'Alisa May, Paul D Miller & Tegan Bukowski' },
      { url: '/img/festivals/2012/cabins_7905693780_o.jpg', alt: 'Festival Cabins' },
      { url: '/img/festivals/2012/cabins_7905713820_o.jpg', alt: 'Festival Cabins View' },
      { url: '/img/festivals/2012/campfire_7905727722_o.jpg', alt: 'Festival Campfire' },
      { url: '/img/festivals/2012/camping-ngen_7905682480_o.jpg', alt: 'Camping at Ngen' },
      { url: '/img/festivals/2012/check-in_7905703340_o.jpg', alt: 'Check-in Area' },
      { url: '/img/festivals/2012/co-creation-session_7905714328_o.jpg', alt: 'Co-creation Session' },
      { url: '/img/festivals/2012/co-creation-space_7905717410_o.jpg', alt: 'Co-creation Space' },
      { url: '/img/festivals/2012/culture--localisation-forum_7905721738_o.jpg', alt: 'Culture & Localisation Forum' },
      { url: '/img/festivals/2012/day-1-evening_7905679326_o.jpg', alt: 'Day 1 Evening' },
      { url: '/img/festivals/2012/dj-spooky_7905722264_o.jpg', alt: 'DJ Spooky Performance' },
      { url: '/img/festivals/2012/dj-spooky_7905722826_o.jpg', alt: 'DJ Spooky Set' },
      { url: '/img/festivals/2012/dj-spooky_7905724474_o.jpg', alt: 'DJ Spooky Session' },
      { url: '/img/festivals/2012/dj-spooky-app_7905726232_o.jpg', alt: 'DJ Spooky App Demo' },
      { url: '/img/festivals/2012/dsc_0192_7905698190_o.jpg', alt: 'Festival Scene 1' },
      { url: '/img/festivals/2012/dsc_0196_7905698898_o.jpg', alt: 'Festival Scene 2' },
      { url: '/img/festivals/2012/dsc_0288_7905702660_o.jpg', alt: 'Festival Scene 3' },
      { url: '/img/festivals/2012/dsc_0391_7905708770_o.jpg', alt: 'Festival Scene 4' },
      { url: '/img/festivals/2012/dsc_0437_7905713010_o.jpg', alt: 'Festival Scene 5' },
      { url: '/img/festivals/2012/dsc_0503_7905719326_o.jpg', alt: 'Festival Scene 6' },
      { url: '/img/festivals/2012/dsc_0510_7905720070_o.jpg', alt: 'Festival Scene 7' },
      { url: '/img/festivals/2012/dsc_0514_7905720948_o.jpg', alt: 'Festival Scene 8' },
      { url: '/img/festivals/2012/dsc_0736_7905729142_o.jpg', alt: 'Festival Scene 9' },
      { url: '/img/festivals/2012/enterprise-forum_7905675540_o.jpg', alt: 'Enterprise Forum' },
      { url: '/img/festivals/2012/enterprise-seminar_7905710268_o.jpg', alt: 'Enterprise Seminar' },
      { url: '/img/festivals/2012/festival-site_7905701768_o.jpg', alt: 'Festival Site' },
      { url: '/img/festivals/2012/flashlines_7905697530_o.jpg', alt: 'Flashlines Performance 1' },
      { url: '/img/festivals/2012/flashlines_7905726916_o.jpg', alt: 'Flashlines Performance 2' },
      { url: '/img/festivals/2012/futureperfect-forum_7905679972_o.jpg', alt: 'Futureperfect Forum' },
      { url: '/img/festivals/2012/internet-forum_7905710900_o.jpg', alt: 'Internet Forum' },
      { url: '/img/festivals/2012/joachim-robert-cyanotype-workshop_7905684644_o.jpg', alt: 'Cyanotype Workshop' }
    ]
  },
  {
                  title: '#1',
                  color: '#F7D417',
                  description: 'Our inaugural festival set the stage for years of innovation and collaboration.',
                  images: [
      { url: '/img/festivals/2011/6070606439_c04fc49722_o.jpg', alt: 'Opening Ceremony' },
      { url: '/img/festivals/2011/anna-maria-orru_6071159890_o.jpg', alt: 'Anna Maria Orru' },
      { url: '/img/festivals/2011/asha-ali_6071212886_o.jpg', alt: 'Asha Ali Performance' },
      { url: '/img/festivals/2011/asha-ali_6071214778_o.jpg', alt: 'Asha Ali Set' },
      { url: '/img/festivals/2011/ass--knut-larsson_6070638757_o.jpg', alt: 'Knut Larsson' },
      { url: '/img/festivals/2011/bob-hansson_6070968712_o.jpg', alt: 'Bob Hansson' },
      { url: '/img/festivals/2011/bob-hansson-feat-kalle-beatmeister-bccman_6070411699_o.jpg', alt: 'Bob Hansson & Kalle' },
      { url: '/img/festivals/2011/breakfast_6070608767_o.jpg', alt: 'Festival Breakfast' },
      { url: '/img/festivals/2011/carolyn-steel_6070659103_o.jpg', alt: 'Carolyn Steel' },
      { url: '/img/festivals/2011/carolyn-steel_6071197510_o.jpg', alt: 'Carolyn Steel Presentation' },
      { url: '/img/festivals/2011/cities-forum_6071185178_o.jpg', alt: 'Cities Forum' },
      { url: '/img/festivals/2011/closing-ceremony_6766843573_o.jpg', alt: 'Closing Ceremony' },
      { url: '/img/festivals/2011/closing-ceremony-1_6766857331_o.jpg', alt: 'Closing Ceremony 1' },
      { url: '/img/festivals/2011/closing-ceremony-2_6766850423_o.jpg', alt: 'Closing Ceremony 2' },
      { url: '/img/festivals/2011/copia-doble-systema_6070687697_o.jpg', alt: 'Copia Doble Systema' },
      { url: '/img/festivals/2011/copia-doble-systema_6070697903_o.jpg', alt: 'Copia Doble Systema Set' },
      { url: '/img/festivals/2011/copia-doble-systema_6071271596_o.jpg', alt: 'Copia Doble Systema Performance' },
      { url: '/img/festivals/2011/coppia-doble-systema_6070718095_o.jpg', alt: 'Copia Doble Systema Group' },
      { url: '/img/festivals/2011/dance_6070554751_o.jpg', alt: 'Festival Dance' },
      { url: '/img/festivals/2011/dsc_0204jpg_6070585811_o.jpg', alt: 'Festival Moments 1' },
      { url: '/img/festivals/2011/dsc_0207jpg_6071131020_o.jpg', alt: 'Festival Moments 2' },
      { url: '/img/festivals/2011/ed-colville-thomas-ermacora_6070610451_o.jpg', alt: 'Ed Colville & Thomas Ermacora' },
      { url: '/img/festivals/2011/fireplace_6070544263_o.jpg', alt: 'Fireplace Gathering 1' },
      { url: '/img/festivals/2011/fireplace_6071079100_o.jpg', alt: 'Fireplace Gathering 2' },
      { url: '/img/festivals/2011/fireplace_6071084186_o.jpg', alt: 'Fireplace Gathering 3' },
      { url: '/img/festivals/2011/food-service_6070894462_o.jpg', alt: 'Food Service' },
      { url: '/img/festivals/2011/futureperfect-day-two_6071032096_o.jpg', alt: 'Festival Day Two 1' },
      { url: '/img/festivals/2011/futureperfect-day-two_6071068218_o.jpg', alt: 'Festival Day Two 2' },
      { url: '/img/festivals/2011/futureperfect_6070938524_o.jpg', alt: 'Festival Overview' },
      { url: '/img/festivals/2011/gran-kajfes_6070547473_o.jpg', alt: 'Gran Kajfes' },
      { url: '/img/festivals/2011/gran-kajfes_6070569677_o.jpg', alt: 'Gran Kajfes Performance' },
      { url: '/img/festivals/2011/jesper-waldersten_6070549605_o.jpg', alt: 'Jesper Waldersten' },
      { url: '/img/festivals/2011/john-thackara-his-backpack-john-manoochehri_6070730483_o.jpg', alt: 'John Thackara and John Manoochehri' }
    ]
  }
];

// Festivals styling configuration
const festivalsTabStyling = {
                  tab: {
                  fontFamily: 'NeusaBlack, sans-serif',
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  letterSpacing: '0.03em',
                  defaultColor: '#F7D417',
                  hoverColor: '#D4B514',
                  selectedColor: '#D4B514',
                  textTransform: 'uppercase'
  },
                  container: {
                  width: '100%'
  }
};

const Festivals = () => {
                  return (
    <>
      <Helmet>
        <title>FUTUREPERFECT FESTIVALS</title>
      </Helmet>
      <Container maxWidth={false} disableGutters>
        <Navbar />
        {/* Hero Section */}
        <Section sx={{ 
                  marginBottom: 0, 
                  position: 'relative', 
                  height: 'calc(100vh - 91px)', // Subtract navbar height
                  minHeight: 'calc(100vh - 91px)', // Subtract navbar height
                  overflow: 'hidden'
        }}>
          <HeroCarousel
                  strapline="Experiences for the Worlds We Make"
                  images={festivalData}
          />
        </Section>
        {/* WHAT Section */}
        <SectionBannerWrapper sx={{ marginTop: 0 }}>
          <SectionBanner sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 4
          }} color={colors.gold}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                  WHAT IS FUTUREPERFECT FESTIVAL?
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        <Section id="what" sx={{ backgroundColor: 'rgba(247, 212, 23, 0.1)', marginTop: 0 }}>
          <ContentWrapper>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SectionBodyText>
                  Futureperfect Festivals are inspirational experiences on themes of sustainable living, built design and living well, for everyone.<br/><br/>
                  Futureperfect Festivals are a collaboration platform to increase engagement in sustainable living, modern placemaking, and emerging spatial technologies.<br/><br/>
                  Futureperfect brings music, art, and creativity to the centre of sustainable living and built design.<br/><br/>
                  Futureperfect believes real sustainable living and built design are an adventure in living well.
                </SectionBodyText>
              </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="FESTIVALS"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Festivals are the most impactful way to connect people to each other, to ideas, and to action. Futureperfect creates festivals that show the great value embedded in the ideas and practices of sustainable living.
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="COLLABORATE"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Futureperfect sees that the potential of sustainable living lies at the intersection of design, technology, and sustainability - and wants to enable lasting, innovative collaboration across these sectors.
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="CREATIVITY"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Futureperfect believes that rich, positive experiences with design and technology, based on real evidence, are the best way to unlock motivation towards sustainable living.
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="ADVENTURE"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Futureperfect believes stopping bad things is good - but making better things is so much better. Sustainable living has to become a great adventure in living well.
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>
        {/* GOALS Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 4
          }} color={colors.gold}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                  WHAT IS THE GOAL OF FUTUREPERFECT FESTIVAL?
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        <Section id="goals" sx={{ 
                  position: 'relative',
                  minHeight: '100vh',
                  backgroundImage: 'url(/img/backgrounds/background.jpg)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundAttachment: 'fixed',
          '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1
          }
        }}>
          <ContentWrapper sx={{ position: 'relative', zIndex: 2, py: 12 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sx={{ mb: 8 }}>
                <SectionBodyText variant="white">
                  The goals of Futureperfect Festival are to create meaningful conversations, experiences, collaborations, and adventures in sustainable living.
                </SectionBodyText>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="CONVERSATION"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Great conversation creates opportunities by linking fresh audiences to essential themes
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="EXPERIENCE"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Real experience of better ways of living creates motivation and engagement
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="COLLABORATION"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Collaboration between design, technology, and sustainability sectors creates innovation and real progress
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="ADVENTURE"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Sustainable living should be an adventure in living well
                  
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>
        {/* WHO Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 4
          }} color={colors.gold}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                  WHO IS FUTUREPERFECT FESTIVAL FOR?
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        <Section id="who" sx={{ backgroundColor: 'rgba(247, 212, 23, 0.1)', marginTop: 0 }}>
          <ContentWrapper>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SectionBodyText>
                  Futureperfect focuses on highlighting exceptional real-world examples of sustainable living through engaging, interactive experiences. We showcase innovative practices already making a difference in communities worldwide.<br/><br/>
                  This creates a tremendous opportunity to engage new people in the positive and high potential themes of sustainable living – Futureperfect is for all these people and organisations.
                </SectionBodyText>
              </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="INDIVIDUALS"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Be part of a community dedicated to exploring and shaping sustainable futures
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="BUSINESSES"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Connect with innovators and leaders in sustainability, spatial technology, and digital design
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="PUBLIC SECTOR"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Engage with initiatives that bridge public policy with technological innovation
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="PARTNERS"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Collaborate with us to create meaningful impact in sustainable development
                  
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>
        {/* PROGRAM Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 4
          }} color={colors.gold}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                  FESTIVAL PROGRAM ELEMENTS
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        <Section id="program" sx={{ marginTop: 0 }}>
          <ContentWrapper>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SectionBodyText>
                  Futureperfect festivals are collaborations involving some or all the following program elements.
                </SectionBodyText>
              </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="PRO DIALOGUES"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Interactive dialogues with world class experts, with a FutureLab of best practice and collaborative solution
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="FORUMS"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  High-powered presentations and exchanges with global thought and practice leaders across society and enterprise
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="OPEN DIALOGUES"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Interactive seminars led by inspiring individuals and organisations – for everyone interested to connect and explore
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="CO-CREATION"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Dynamic collaboration and discussion space - open to all - led by professional facilitators and thought leaders
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="LIVING WELL"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Food from world-class chefs, nature and adventure, live music and more
                  
                </ContentCard>
              </Grid>
              <Grid item xs={12} md={4}>
                <ContentCard 
                  title="MUSIC & ARTS"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  Full music program - as well as art, film, photography
                  
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>
        {/* FESTIVALS Section */}
        <SectionBannerWrapper>
          <SectionBanner color={colors.gold}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                  PAST FESTIVALS
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        <Section id="festivals" sx={{ backgroundColor: 'rgba(247, 212, 23, 0.1)', marginTop: 0, width: '100%', maxWidth: 'none' }}>
          <Box sx={{ width: '100%', mt: 4, maxWidth: 'none' }}>
            <TabGallery 
                  items={festivalData}
                  styling={{
                ...festivalsTabStyling,
                  container: { 
                  ...festivalsTabStyling.container,
                  maxWidth: 'none',
                  px: 0
                }
              }}
            />
          </Box>
        </Section>
        {/* PARTICIPATE Section */}
        <SectionBannerWrapper>
          <SectionBanner sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 4
          }} color={colors.gold}>
            <Container maxWidth="lg">
              <SectionBannerText variant="h2">
                  PARTICIPATE
              </SectionBannerText>
            </Container>
          </SectionBanner>
        </SectionBannerWrapper>
        <Section id="participate" sx={{ 
                  marginTop: 0,
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/img/festivals/2014/futureperfect-festival-2014---grinda_15565198588_o.jpg)`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
        }}>
          <ContentWrapper>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SectionBodyText variant="white">
                  Below are the ways to participate in Futureperfect Festival.<br/><br/>
                  <a href="mailto:hello@futureperfect.net" style={{ color: 'inherit', textDecoration: 'none' }}>hello@futureperfect.net</a>
                </SectionBodyText>
              </Grid>
            </Grid>
            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="JOIN"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Sign up for updates and invites to festivals - including private events, away days, remote experiences, special camping options and more.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="https://discord.gg/R2NKjCm" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Join the conversation →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="SPEAK & DEMONSTRATE"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Share your expertise and insights as a speaker, moderator or hands-on workshop leader at our events.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="/register" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Register as a speaker →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="CREATE"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Collaborate with us to host, perform, display, hack, experience at festivals.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="/create" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Share your ideas →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ContentCard
                  title="SPONSOR"
                  accentColor={colors.gold}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ height: '180px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                      Support our mission and gain visibility among creative and technical leaders and emerging contributors.
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 10, left: 0, right: 0 }}>
                      <a href="/sponsor" style={{ color: '#FFFFFF', textDecoration: 'none', fontWeight: 'bold' }}>
                        Explore sponsorship →
                      </a>
                    </Box>
                  </Box>
                </ContentCard>
              </Grid>
            </Grid>
          </ContentWrapper>
        </Section>
        {/* Program Elements Section */}
      </Container>
    </>
  );
};

export default Festivals;
