import { Typography, styled } from '@mui/material';

// Page Title - Used for main page headings
export const PageTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '3.5rem',
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1,
  color: '#FFFFFF',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale'
}));

// Section Title - Used in content cards and major sections
export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '2.5rem',
  fontWeight: 700,
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1,
  color: '#FFFFFF',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  // Mobile adjustments
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem'
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1.2rem'
  }
}));

// Section Subtitle - Used below section titles
export const SectionSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '2.5rem',
  fontWeight: 700,
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1,
  color: '#FFFFFF',
  opacity: 0.6,
  marginTop: '0.4rem',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  // Mobile adjustments
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem',
    marginTop: '0.3rem'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem',
    marginTop: '0.2rem'
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '1rem',
    marginTop: '0.1rem'
  }
}));

// Card Title - Used within content cards
export const CardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Oswald, sans-serif',
  fontSize: '1.5rem',
  fontWeight: 700,
  textTransform: 'uppercase',
  letterSpacing: '-0.02em',
  lineHeight: 1.2,
  color: '#FFFFFF',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale'
}));

// Main Body Text - Used for primary content sections
export const MainBodyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: '0.95rem',
  fontWeight: 400,
  lineHeight: 1.4,
  letterSpacing: 'normal',
  color: theme.palette.text.primary,
  marginBottom: '1.2rem'
}));

// Body Text - Secondary text style
export const BodyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: '1.1rem',
  fontWeight: 400,
  lineHeight: 1.8,
  letterSpacing: 'normal',
  color: '#333333',
  marginBottom: '1.2rem',
  // Mobile adjustments
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.9rem',
    lineHeight: 1.6,
    marginBottom: '0.8rem'
  },
  // Mobile landscape
  '@media (max-width: 600px) and (orientation: landscape)': {
    fontSize: '0.8rem',
    lineHeight: 1.4,
    marginBottom: '0.6rem'
  }
}));

// Secondary Text - Smaller body text
export const SecondaryText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: '0.9rem',
  fontWeight: 400,
  lineHeight: 1.6,
  letterSpacing: 'normal',
  color: '#FFFFFF',
  opacity: 0.8
}));

// Banner Text - Used in section banners
export const BannerText = styled(Typography)(({ theme }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '2rem',
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1,
  color: '#FFFFFF',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale'
}));

// Sub-Banner Text - Used in sub-section banners
export const SubBannerText = styled(Typography)(({ theme }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.5rem',
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1,
  color: '#FFFFFF',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale'
}));

// Strapline Text - Used for page straplines in navbar
export const StraplineText = styled('div')(({ theme }) => ({
  fontFamily: 'NeusaNextPro-Bold, sans-serif',
  fontSize: '1.75rem',
  letterSpacing: '0.03em',
  lineHeight: 1.4,
  color: '#FFFFFF',
  opacity: 0.7,
  textAlign: 'center',
  textTransform: 'none',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.25rem'
  }
}));

// NavLink Text - Used for navigation menu items
export const NavLinkText = styled(Typography)(({ theme }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '1.25rem',
  letterSpacing: '0.03em',
  lineHeight: 1.4,
  color: '#FFFFFF',
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'opacity 0.2s ease-in-out',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  '&:hover': {
    opacity: 0.7
  }
}));

// Page Name Text - Used for page names in header
export const PageNameText = styled(Typography)(({ theme }) => ({
  fontFamily: 'NeusaBlack, sans-serif',
  fontSize: '2.25rem',
  letterSpacing: '0.03em',
  lineHeight: 1,
  color: '#FFFFFF',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem' // Increase mobile font size to match logo proportion
  }
}));
