import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const OptimizedImage = ({ 
  src, 
  alt, 
  className,
  width,
  height,
  style 
}) => {
  // Check if we're in Vercel environment and should use original images
  const useOriginalImages = process.env.USE_ORIGINAL_IMAGES === 'true';
  
  const [imageLoaded, setImageLoaded] = useState(false);
  const [useFallback, setUseFallback] = useState(useOriginalImages);
  
  // If we're in Vercel environment, just use the original image directly
  if (useOriginalImages) {
    return (
      <LazyLoadImage
        src={src}
        alt={alt}
        className={className}
        width={width}
        height={height}
        style={style}
        effect="blur"
        loading="lazy"
        onLoad={() => setImageLoaded(true)}
      />
    );
  }
  
  // Only try to use optimized images for paths that include /img/
  // Get a clean path by removing any query params
  const cleanSrc = src.split('?')[0];
  
  // Remove the file extension and get the base path
  const basePath = cleanSrc.replace(/\.[^/.]+$/, '');
  
  // Transform path to use img-optimized directory regardless of nesting level
  // e.g., /img/carousel/hero_carousel_A.jpg -> /img-optimized/carousel/hero_carousel_A
  // or /img/festivals/2014/image.jpg -> /img-optimized/festivals/2014/image
  let optimizedBasePath;
  
  // Check if the path contains /img/ directory
  if (cleanSrc.includes('/img/')) {
    optimizedBasePath = basePath.replace('/img/', '/img-optimized/');
  } else {
    // If for some reason it doesn't include the /img/ path, use original without logging
    optimizedBasePath = basePath;
  }
  
  // Only log for development or specific debugging needs
  if (process.env.NODE_ENV === 'development' && 
      (cleanSrc.includes('hero_carousel') || cleanSrc.includes('festivals/2014'))) {
    console.log('Path info:', { 
      src: cleanSrc, 
      optimizedBasePath,
      useFallback
    });
  }
  
  // Create srcSet for WebP images
  const srcSetWebP = `
    ${optimizedBasePath}-sm.webp 320w,
    ${optimizedBasePath}-md.webp 768w,
    ${optimizedBasePath}-lg.webp 1024w,
    ${optimizedBasePath}-xl.webp 1920w
  `;

  // Fallback JPEG for browsers that don't support WebP
  const fallbackSrc = `${optimizedBasePath}-xl.jpg`;
  
  // Original image as ultimate fallback
  const originalSrc = src;
  
  // Special handling for hero carousel images 
  // Check if this is one of the default carousel slide images
  const isDefaultCarouselImage = src.includes('/img/carousel/hero_carousel_');
  
  // For optimized images paths that we know exist
  // This helps prevent image flickering when the optimized image doesn't exist
  const hasOptimizedVersion = 
    isDefaultCarouselImage ||
    src.includes('/img/backgrounds/');

  // The actual image source we'll use based on whether the optimized version exists
  const actualSrc = useFallback ? originalSrc : fallbackSrc;
  
  // Handle checking for file existence and falling back
  const handleImageError = (e) => {
    console.log(`Failed to load optimized image: ${fallbackSrc}, falling back to original: ${originalSrc}`);
    setUseFallback(true);
    e.target.src = originalSrc;
  };

  // Only log errors when image loading fails, not all image debugging info
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && useFallback) {
      if (src.includes('hero_carousel') || src.includes('cities-forum')) {
        console.log({
          originalPath: src,
          optimizedBasePath,
          fallbackSrc,
          hasOptimizedVersion,
          useFallback
        });
      }
    }
  }, [src, optimizedBasePath, fallbackSrc, hasOptimizedVersion, useFallback]);

  // Standard implementation for all environments
  return (
    <picture>
      {!useFallback && (
        <source
          srcSet={srcSetWebP}
          sizes="(max-width: 320px) 320px,
                 (max-width: 768px) 768px,
                 (max-width: 1024px) 1024px,
                 1920px"
          type="image/webp"
        />
      )}
      <LazyLoadImage
        src={actualSrc}
        alt={alt}
        className={className}
        width={width}
        height={height}
        style={style}
        effect="blur"
        loading="lazy"
        onError={handleImageError}
        onLoad={() => setImageLoaded(true)}
      />
    </picture>
  );
};

export default OptimizedImage;
